import React from 'react'
import {withResponsiveHelper, RadioFilter, ButtonSecondary, FilterIcon} from '@modifi/ui'
import {propEq, find, equals, map, omit} from 'ramda'
import qs from 'qs'
import * as PropTypes from 'prop-types'

import {ACTION_REQUIRED, quickFilterOptions} from '../constants'

const QuickFilters = ({
  filters: existingFilters,
  location,
  replaceUrl,
  orderBy,
  orderDirection,
  itemsPerPage,
  resetAllFilters,
  isSmOrSmaller,
  t,
}) => {
  const getIfQuickFilterSelected = () => {
    /* checking this case explicitly as the property hasActionRequired is string always 
    because it is coming from url, like this: 'true'.
    Inside quickFilterOptions, property hasActionRequired has value boolean.
    */
    if (existingFilters?.hasActionRequired === 'true') {
      return {label: t('ol.nav.action-required'), value: ACTION_REQUIRED}
    }

    const selectedFilter = quickFilterOptions(t).find(quickFilter =>
      equals(quickFilter?.filters, existingFilters)
    )
    if (selectedFilter) {
      return {label: selectedFilter.label, value: selectedFilter.value}
    }
    return null
  }
  const handleQuickFilterSelect = filterKey => {
    if (filterKey) {
      replaceUrl({
        ...location,
        search: qs.stringify({
          page: 1,
          itemsPerPage,
          orderBy,
          orderDirection,
          ...find(propEq('value', filterKey), quickFilterOptions(t)).filters,
        }),
      })
    }
  }
  return (
    <RadioFilter
      options={map(omit('filters'), quickFilterOptions(t))}
      defaultSelected={getIfQuickFilterSelected()}
      fieldName={t('ol.quick-filter.fieldName')}
      onSubmit={selected => handleQuickFilterSelect(selected?.value)}
      onReset={() => resetAllFilters()}
      label={t('ol.quick-filter.fieldName')}
      buttonComponent={ButtonSecondary}
      bordered
      icon={<FilterIcon height={24} width={24} />}
      dropdownPosition={isSmOrSmaller ? 'left' : 'right'}
      dropdownWidth={250}
    />
  )
}

QuickFilters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.any,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  replaceUrl: PropTypes.func.isRequired,
  orderBy: PropTypes.arrayOf(PropTypes.string),
  orderDirection: PropTypes.arrayOf(PropTypes.oneOf(['ASC', 'DESC'])),
  itemsPerPage: PropTypes.number,
  resetAllFilters: PropTypes.func.isRequired,
  isSmOrSmaller: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

QuickFilters.defaultProps = {
  filters: {},
  orderBy: [],
  orderDirection: [],
  itemsPerPage: 20,
}

export default withResponsiveHelper(QuickFilters)
