/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import forge, {Middleware} from 'mappersmith'
import type AresClientClientInterface from './AresClientClientInterface'

type FactoryOptions = {
  host?: string
  middleware?: Middleware[]
}

const AresClientClientFactory = ({
  host,
  middleware,
}: FactoryOptions = {}): AresClientClientInterface =>
  forge({
    clientId: 'matrix:ares',
    host: host || '',
    middlewares: [...(middleware || [])],
    resources: {
      PayoutReport: {
        generate: {
          path: '/api/v1/report/payouts',
          method: 'post',
        },
        getReports: {
          path: '/api/v1/report/payouts',
          method: 'get',
        },
        fetch: {
          path: '/api/v1/report/payouts/{externalId}/csv',
          method: 'get',
          binary: true,
        },
      },
      RepaymentsReport: {
        generate: {
          path: '/api/v1/report/repayments/csv',
          method: 'get',
          binary: true,
        },
      },
      Activations: {
        activateByRef: {
          path: '/api/v1/orders/{externalOrderId}/activations',
          method: 'post',
        },
        updateActivationByRef: {
          path: '/api/v1/orders/{externalOrderId}/activations/{externalPaymentId}',
          method: 'put',
        },
      },
      AddressBook: {
        getEntries: {
          path: '/api/v1/addressBook/entries',
          method: 'get',
        },
        getBankAccounts: {
          path: '/api/v1/addressBook/bankAccounts',
          method: 'get',
        },
        getBankAccountById: {
          path: '/api/v1/addressBook/bankAccounts/{externalBankAccountId}',
          method: 'get',
        },
        changeVisibility: {
          path: '/api/v1/addressBook/entries/visibility',
          method: 'post',
        },
      },
      BankAccounts: {
        validate: {
          path: '/api/v1/bankAccounts/validate',
          method: 'post',
        },
        getBankAccount: {
          path: '/api/v1/bankAccounts/repayments',
          method: 'get',
        },
      },
      CompanyAddresses: {
        addressSearch: {
          path: '/api/v1/companyAddresses/search',
          method: 'get',
        },
        createAddress: {
          path: '/api/v1/companyAddresses',
          method: 'post',
        },
      },
      Task: {
        fetch: {
          path: '/api/v1/tasks/',
          method: 'get',
        },
      },
      CompanyFinancingConfigs: {
        getAgreement: {
          path: '/api/v1/financingConfigs/agreement/pdf',
          method: 'post',
          binary: true,
        },
        configs: {
          path: '/api/v1/financingConfigs',
          method: 'get',
        },
        configById: {
          path: '/api/v1/financingConfigs/{configId}',
          method: 'get',
        },
      },
      CompanyUsers: {
        userSearch: {
          path: '/api/v1/companies/{externalCompanyId}/users/search',
          method: 'get',
        },
      },
      Corporates: {
        search: {
          path: '/api/v1/corporates/search',
          method: 'get',
        },
      },
      CreateOrderRequest: {
        requiredDocuments: {
          path: '/api/v1/createOrder/{createOrderRequestId}/requiredDocuments',
          method: 'post',
        },
      },
      Documents: {
        search: {
          path: '/api/v1/documents',
          method: 'get',
        },
      },
      FundsTransfers: {
        updateFundsTransferArrivalDate: {
          path: '/api/v1/fundsTransfers/{externalId}',
          method: 'patch',
        },
      },
      Invoices: {
        all: {
          path: '/api/v1/invoices',
          method: 'get',
        },
        csvById: {
          path: '/api/v1/invoices/{externalInvoiceId}/csv',
          method: 'get',
          binary: true,
        },
        jsonById: {
          path: '/api/v1/invoices/{externalInvoiceId}/json',
          method: 'get',
        },
        pdfById: {
          path: '/api/v1/invoices/{externalInvoiceId}/pdf',
          method: 'get',
          binary: true,
        },
      },
      Orders: {
        all: {
          path: '/api/v1/orders',
          method: 'get',
        },
        create: {
          path: '/api/v1/orders',
          method: 'post',
        },
        byId: {
          path: '/api/v1/orders/{externalOrderId}',
          method: 'get',
        },
        addSellerBankAccount: {
          path: '/api/v1/orders/{externalOrderId}/bankAccounts/seller',
          method: 'post',
        },
        cancelByRef: {
          path: '/api/v1/orders/{externalOrderId}/cancel',
          method: 'patch',
        },
        updateReferenceCode: {
          path: '/api/v1/orders/{externalOrderId}',
          method: 'patch',
        },
        containerRoute: {
          path: '/api/v1/orders/{externalOrderId}/containerRoute',
          method: 'get',
        },
        buyerConfirmation: {
          path: '/api/v1/orders/{externalOrderId}/buyerConfirmation',
          method: 'get',
        },
      },
      OrderDocuments: {
        attachDocument: {
          path: '/api/v1/orders/{externalOrderId}/documents',
          method: 'post',
        },
        detachDocument: {
          path: '/api/v1/orders/{externalOrderId}/payments/{externalPaymentId}/documents/{fileId}',
          method: 'delete',
        },
      },
      OrderFilterRanges: {
        getFilterRanges: {
          path: '/api/v1/orderFilterRanges',
          method: 'get',
        },
      },
      OrderShipments: {
        all: {
          path: '/api/v1/orderShipments',
          method: 'get',
        },
      },
      OrderStatistics: {
        statistics: {
          path: '/api/v1/statistics/orders',
          method: 'get',
        },
      },
      Payments: {
        getExtensions: {
          path: '/api/v1/payments/{externalPaymentId}/extensions',
          method: 'get',
        },
        applyExtension: {
          path: '/api/v1/payments/{externalPaymentId}/extensions',
          method: 'post',
        },
      },
      Places: {
        byId: {
          path: '/api/v1/places/{placeId}',
          method: 'get',
        },
      },
      Repayments: {
        all: {
          path: '/api/v1/repayments',
          method: 'get',
        },
      },
      TradePartners: {
        getSellerEntries: {
          path: '/api/v1/buyers',
          method: 'get',
        },
        create: {
          path: '/api/v1/tradePartners',
          method: 'post',
        },
        getTradePartners: {
          path: '/api/v1/tradePartners',
          method: 'get',
        },
        repayments: {
          path: '/api/v1/tradePartnerRepayments',
          method: 'get',
        },
      },
      TradePartnerOrderFilterRanges: {
        getFilterRanges: {
          path: '/api/v1/tradePartnerOrderFilterRanges',
          method: 'get',
        },
      },
      TradePartnerOrders: {
        all: {
          path: '/api/v1/tradePartnerOrders',
          method: 'get',
        },
        byId: {
          path: '/api/v1/tradePartnerOrders/{externalOrderId}',
          method: 'get',
        },
        getOrderDocument: {
          path: '/api/v1/tradePartnerOrders/{externalOrderId}/documentsToConfirm/{fileId}',
          method: 'get',
          binary: true,
        },
        fetchBuyerConfirmation: {
          path: '/api/v1/tradePartnerOrders/{externalOrderId}/buyerConfirmation',
          method: 'get',
        },
        updateBuyerConfirmation: {
          path: '/api/v1/tradePartnerOrders/{externalOrderId}/buyerConfirmation',
          method: 'patch',
        },
      },
      TradePartnerStatistics: {
        statistics: {
          path: '/api/v1/statistics/tradePartners',
          method: 'get',
        },
      },
      NoticeOfAssignment: {
        Download: {
          path: '/api/v1/orders/{externalOrderId}/noticeOfAssignment/pdf',
          method: 'get',
          binary: true,
        },
      },
    },
  })

export default AresClientClientFactory
