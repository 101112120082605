// @todo move to components package
import React from 'react'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default React.forwardRef<SVGSVGElement, {color: string}>((props, ref) => (
  <Svg viewBox="0 0 24 24" {...props} ref={ref}>
    <path d="M10.525 16.65L17.65 9.49998L16.375 8.24998L10.525 14.125L7.57498 11.175L6.32498 12.425L10.525 16.65ZM12 22.15C10.6166 22.15 9.30414 21.8833 8.06248 21.35C6.82081 20.8166 5.74164 20.0916 4.82498 19.175C3.90831 18.2583 3.18331 17.1833 2.64998 15.95C2.11664 14.7166 1.84998 13.4 1.84998 12C1.84998 10.6 2.11664 9.27914 2.64998 8.03748C3.18331 6.79581 3.90831 5.72081 4.82498 4.81248C5.74164 3.90414 6.81664 3.18331 8.04998 2.64998C9.28331 2.11664 10.6 1.84998 12 1.84998C13.4 1.84998 14.7208 2.11664 15.9625 2.64998C17.2041 3.18331 18.2791 3.90414 19.1875 4.81248C20.0958 5.72081 20.8166 6.79164 21.35 8.02498C21.8833 9.25831 22.15 10.5833 22.15 12C22.15 13.4 21.8833 14.7166 21.35 15.95C20.8166 17.1833 20.0958 18.2583 19.1875 19.175C18.2791 20.0916 17.2083 20.8166 15.975 21.35C14.7416 21.8833 13.4166 22.15 12 22.15ZM12 20.45C14.35 20.45 16.3458 19.625 17.9875 17.975C19.6291 16.325 20.45 14.3333 20.45 12C20.45 9.64998 19.6291 7.65414 17.9875 6.01248C16.3458 4.37081 14.35 3.54998 12 3.54998C9.66664 3.54998 7.67498 4.37081 6.02498 6.01248C4.37498 7.65414 3.54998 9.64998 3.54998 12C3.54998 14.3333 4.37498 16.325 6.02498 17.975C7.67498 19.625 9.66664 20.45 12 20.45Z" />
  </Svg>
))
