import {connect} from '@modifi/redux'
import {aCloseDialog} from '@modifi/plugin-dialogs'
import {compose} from 'ramda'
import {withTranslation} from '@modifi/plugin-i18n'

import RepaymentInformation from './RepaymentInformation'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  closeDialog: aCloseDialog,
}

export const DIALOG_NAME = 'dialog-repayment-information'

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('matrix')
)(RepaymentInformation)
