import React, {PureComponent} from 'react'
import {connect} from '@modifi/redux'
import * as PropTypes from 'prop-types'
import {aEnsureCountries} from '@modifi/plugin-countries'
import Context from './Context'
import isUuid from '../../../../utils/is-uuid'

class ShipmentTrackingManager extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    externalOrderId: PropTypes.string,
    containerId: PropTypes.string,
    orderReference: PropTypes.string,
    onFetch: PropTypes.func.isRequired,
    ensureCountries: PropTypes.func.isRequired,
  }

  static defaultProps = {
    children: null,
    externalOrderId: null,
    containerId: null,
    orderReference: null,
  }

  componentDidMount() {
    const {ensureCountries} = this.props
    this.fetchContainer()
    ensureCountries()
  }

  componentDidUpdate(prevProps) {
    const {externalOrderId, containerId} = this.props
    if (externalOrderId !== prevProps.externalOrderId || containerId !== prevProps.containerId) {
      this.fetchContainer()
    }
  }

  fetchContainer() {
    const {externalOrderId, containerId, onFetch} = this.props
    if (isUuid(externalOrderId)) {
      return containerId ? onFetch(externalOrderId, containerId) : onFetch(externalOrderId)
    }
    return undefined
  }

  render() {
    const {children, externalOrderId, containerId, orderReference, onFetch} = this.props

    const context = {
      externalOrderId,
      containerId,
      orderReference,
      onFetch,
    }

    return <Context.Provider value={context}>{children}</Context.Provider>
  }
}

const mapDispatchToProps = {
  ensureCountries: aEnsureCountries,
}

export default connect(null, mapDispatchToProps)(ShipmentTrackingManager)
