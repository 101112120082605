import React from 'react'
import styled from 'styled-components'
import {InlineLoader, Row, Col} from '@modifi/ui'

// 152 = {top nav height} + {content container padding-top} + {content container padding-bottom}
// 152 = 80 + 40 + 32
const FullHeightRow = styled(Row)`
  height: calc(100vh - 152px);
`

const PageContentLoader = () => (
  <FullHeightRow justifyContent="center" alignItems="center">
    <Col auto>
      <InlineLoader size="medium" />
    </Col>
  </FullHeightRow>
)

export default PageContentLoader
