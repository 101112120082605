import {compose} from 'ramda'
import {connect} from '@modifi/redux'
import ProgressBar from './ProgressBar'
import {withShipmentTrackingContext} from '../shipment-tracking-manager'
import {selectContainerRouteData, selectIsContainerRouteDataLoading} from '../../store'

const mapStateToProps = (state, ownProps) => ({
  containerData: selectContainerRouteData(state)(ownProps.externalOrderId),
  isDataLoading: selectIsContainerRouteDataLoading(state)(ownProps.externalOrderId),
})

const mapDispatchToProps = {}

export default compose(
  withShipmentTrackingContext,
  connect(mapStateToProps, mapDispatchToProps)
)(ProgressBar)
