import styled from 'styled-components'
import {white} from '@modifi/colors'
import {Col} from '@modifi/ui'

export const Wrapper = styled.div`
  background-color: ${white};
  min-height: 80px;
  margin-bottom: 24px;
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 3px 2px;
  padding: 24px 24px 16px 24px;
`

export const FilterCol = styled(Col)`
  margin-bottom: 16px;
`

export const ResetCol = styled(Col)`
  text-align: end;
`
