import {connect} from '@modifi/redux'
import {aCloseDialog} from '@modifi/plugin-dialogs'
import {compose} from 'ramda'
import {withTranslation} from '@modifi/plugin-i18n'

import TradeConfirmation from './TradeConfirmation'

import {
  aUpdateTradeConfirmation,
  selectIsUpdateReviewStatusPending,
  selectUpdateReviewStatusError,
  aClearError,
} from '../../pages/order/store'

const mapStateToProps = state => ({
  loading: selectIsUpdateReviewStatusPending(state),
  error: selectUpdateReviewStatusError(state),
})

const mapDispatchToProps = {
  closeDialog: aCloseDialog,
  updateTradeConfirmation: aUpdateTradeConfirmation,
  clearError: aClearError,
}

export const DIALOG_NAME = 'dialog-trade-confirmation'

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('matrix')
)(TradeConfirmation)
