import React, {useRef, useState, useEffect} from 'react'
import moment from 'moment'
import {Row, Col} from '@modifi/ui'
import PropTypes from 'prop-types'
import {FormattedDate} from '@modifi/plugin-i18n'
import {Wrapper, ProgressBarLabel} from './styles'
import {SkeletonLine} from '../../../../ui/skeleton-loaders'
import {ShipLine} from './components'

const getBarSize = wrapperRef => wrapperRef?.current?.getBoundingClientRect()?.width || 0

const ProgressBar = ({containerData, isDataLoading}) => {
  const wrapperRef = useRef(null)

  const [barSize, setBarSize] = useState(getBarSize(wrapperRef))

  useEffect(() => {
    const updateBarSize = () => {
      setBarSize(getBarSize(wrapperRef))
    }
    window.addEventListener('resize', updateBarSize)
    return () => window.removeEventListener('resize', updateBarSize)
  }, [])

  useEffect(() => {
    setBarSize(getBarSize(wrapperRef))
  }, [])

  const barFullWidth = barSize ? barSize - 70 : 0 // barFullWidth = barSize - widths of ports - width of dock
  const length =
    containerData?.start && containerData?.end
      ? (moment().diff(containerData?.start, 'seconds') /
          moment(containerData?.end).diff(containerData?.start, 'seconds')) *
        barFullWidth
      : 0
  const width = barFullWidth < length ? barFullWidth : length // handle if end date is already in the past (should not happen)
  const progressCompleted = width.toFixed(2) === barFullWidth.toFixed(2) //

  return (
    <Wrapper ref={wrapperRef}>
      <ShipLine
        progress={width}
        isLoading={isDataLoading}
        lineWidth={barFullWidth}
        completed={progressCompleted}
      />
      <Row justifyContent="between" mt={10}>
        <Col auto>
          <ProgressBarLabel bold={!isDataLoading} departure>
            {isDataLoading ? (
              <SkeletonLine />
            ) : (
              `${containerData?.startLocation?.name}, ${containerData?.startLocation?.countryCode}`
            )}
          </ProgressBarLabel>
          <ProgressBarLabel departure>
            {isDataLoading ? (
              <SkeletonLine />
            ) : (
              <FormattedDate>{containerData?.start}</FormattedDate>
            )}
          </ProgressBarLabel>
        </Col>
        <Col auto>
          <ProgressBarLabel bold={!isDataLoading}>
            {isDataLoading ? (
              <SkeletonLine />
            ) : (
              `${containerData?.endLocation?.name}, ${containerData?.endLocation?.countryCode}`
            )}
          </ProgressBarLabel>
          <ProgressBarLabel>
            {isDataLoading ? <SkeletonLine /> : <FormattedDate>{containerData?.end}</FormattedDate>}
          </ProgressBarLabel>
        </Col>
      </Row>
    </Wrapper>
  )
}

ProgressBar.propTypes = {
  containerData: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    startLocation: PropTypes.shape({
      name: PropTypes.string,
      countryCode: PropTypes.string,
    }),
    endLocation: PropTypes.shape({
      name: PropTypes.string,
      countryCode: PropTypes.string,
    }),
  }),
  start: PropTypes.string,
  end: PropTypes.string,
  startLocation: PropTypes.shape({
    name: PropTypes.string,
    countryCode: PropTypes.string,
  }),
  endLocation: PropTypes.shape({
    name: PropTypes.string,
    countryCode: PropTypes.string,
  }),
  isDataLoading: PropTypes.bool.isRequired,
}

ProgressBar.defaultProps = {
  start: null,
  end: null,
  startLocation: null,
  endLocation: null,
  containerData: null,
}

export default ProgressBar
