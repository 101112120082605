import {compose} from 'ramda'
import React from 'react'
import {clientConfig} from '@modifi/client-config'
import {
  AUTH_TYPE_USER,
  CONFIG_KEY_AUTH_TOKEN_REFRESH_INTERVAL,
} from '@modifi/plugin-authentication/register'
import ms from 'ms'
import {appFactory} from '@modifi/app'
import {FullScreenLoader} from '@modifi/ui'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-dates/initialize'

import {initPlugins} from './plugins'
import './dialogs/setup'
import Layout from './layouts'
import matrixMiddleware from './store/middleware'
import reducers from './store/reducers'
import routes from './routes'
import {withSuspense, withAbilityProvider} from './hoc'

const getApp = async (options = {}) => {
  await initPlugins(options?.plugins)

  const hocFunc = options.useSuspense
    ? compose(withSuspense, withAbilityProvider)
    : withAbilityProvider

  clientConfig.set(CONFIG_KEY_AUTH_TOKEN_REFRESH_INTERVAL, ms('4m'))

  return appFactory({
    client: 'matrix',
    appType: AUTH_TYPE_USER,
    fullscreenLoader: () => <FullScreenLoader />,
    routes,
    reducers,
    layout: Layout,
    storeMiddlewares: [matrixMiddleware],
    hoc: hocFunc,
  })
}

export default getApp
