import {combineReducers} from '@modifi/redux'

import linkReducer from './link-document.store'
import unlinkReducer from './unlink-document.store'

export default combineReducers({
  link: linkReducer,
  unlink: unlinkReducer,
})

export {
  aLinkDocumentRefToPayment,
  aLinkDocumentToPaymentCleanup,
  selectIsLinkDocumentsFromPaymentError,
  selectIsLinkDocumentsFromPaymentInProgress,
  selectLinkDocumentsFromPaymentErrors,
} from './link-document.store'
export {
  aUnlinkDocumentFromPayment,
  aUnlinkDocumentToPaymentCleanup,
  selectIsUnlinkDocumentsFromPaymentError,
  selectIsUnlinkDocumentsFromPaymentInProgress,
  selectUnlinkDocumentsFromPaymentErrorCode,
  selectUnlinkDocumentsFromPaymentErrorMessage,
} from './unlink-document.store'
