// @todo move to components package
import React from 'react'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default React.forwardRef<SVGSVGElement, {color: string}>((props, ref) => (
  <Svg viewBox="0 0 6 24" {...props} width={6} height={24} ref={ref}>
    <path d="M3 24C2.175 24 1.46875 23.7063 0.88125 23.1188C0.29375 22.5312 0 21.825 0 21C0 20.175 0.29375 19.4688 0.88125 18.8813C1.46875 18.2938 2.175 18 3 18C3.825 18 4.53125 18.2938 5.11875 18.8813C5.70625 19.4688 6 20.175 6 21C6 21.825 5.70625 22.5312 5.11875 23.1188C4.53125 23.7063 3.825 24 3 24ZM3 15C2.175 15 1.46875 14.7063 0.88125 14.1188C0.29375 13.5313 0 12.825 0 12C0 11.175 0.29375 10.4688 0.88125 9.88125C1.46875 9.29375 2.175 9 3 9C3.825 9 4.53125 9.29375 5.11875 9.88125C5.70625 10.4688 6 11.175 6 12C6 12.825 5.70625 13.5313 5.11875 14.1188C4.53125 14.7063 3.825 15 3 15ZM3 6C2.175 6 1.46875 5.70625 0.88125 5.11875C0.29375 4.53125 0 3.825 0 3C0 2.175 0.29375 1.46875 0.88125 0.88125C1.46875 0.29375 2.175 0 3 0C3.825 0 4.53125 0.29375 5.11875 0.88125C5.70625 1.46875 6 2.175 6 3C6 3.825 5.70625 4.53125 5.11875 5.11875C4.53125 5.70625 3.825 6 3 6Z" />
  </Svg>
))
