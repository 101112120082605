import {handleUnauthorizedMiddleware, selectAccessToken} from '@modifi/plugin-authentication'
import {clientConfig} from '@modifi/client-config'

import {rawClientFactory, AresApiClient} from './factory'

export default (): AresApiClient =>
  rawClientFactory({
    getAccessToken: () => selectAccessToken() as string,
    host: clientConfig.get('SERVICE_API') as string,
    additionalMiddleware: [handleUnauthorizedMiddleware],
  })
