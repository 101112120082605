import React, {useEffect} from 'react'
import * as PropTypes from 'prop-types'

const IncoTermName = ({incoTermName, ensureIncoTerms}) => {
  useEffect(() => {
    ensureIncoTerms()
  }, [null]) // eslint-disable-line react-hooks/exhaustive-deps
  return <>{incoTermName}</>
}

IncoTermName.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  children: PropTypes.string.isRequired,
  ensureIncoTerms: PropTypes.func.isRequired,
  incoTermName: PropTypes.string,
}

IncoTermName.defaultProps = {
  incoTermName: null,
}

export default IncoTermName
