// @todo move to components package
import React from 'react'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default ({...props}) => (
  <Svg viewBox="0 0 72 72" {...props}>
    <g clipPath="url(#clip0_9653_20023)">
      <path
        d="M36 39L12 24V54H36C36 55.05 36.075 56.0625 36.225 57.0375C36.375 58.0125 36.6 59 36.9 60H12C10.35 60 8.9375 59.4125 7.7625 58.2375C6.5875 57.0625 6 55.65 6 54V18C6 16.35 6.5875 14.9375 7.7625 13.7625C8.9375 12.5875 10.35 12 12 12H60C61.65 12 63.0625 12.5875 64.2375 13.7625C65.4125 14.9375 66 16.35 66 18V35.1C65.1 34.65 64.1375 34.275 63.1125 33.975C62.0875 33.675 61.05 33.45 60 33.3V24L36 39ZM36 33L60 18H12L36 33Z"
        fill="#53A2D9"
      />
      <path
        d="M46.3875 64.6125C49.3125 67.5375 52.85 69 57 69C61.15 69 64.6875 67.5375 67.6125 64.6125C70.5375 61.6875 72 58.15 72 54C72 49.85 70.5375 46.3125 67.6125 43.3875C64.6875 40.4625 61.15 39 57 39C52.85 39 49.3125 40.4625 46.3875 43.3875C43.4625 46.3125 42 49.85 42 54C42 58.15 43.4625 61.6875 46.3875 64.6125Z"
        fill="#7BB835"
      />
      <path d="M64.05 58.95L61.95 61.05L55.5 54.6V45H58.5V53.4L64.05 58.95Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_9653_20023">
        <rect width="72" height="72" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)
