import {map} from 'ramda'

const isTrueString = val => val === 'true'
const isFalseString = val => val === 'false'
export const normalizeSearchObj = map(val => {
  if (isTrueString(val)) {
    return true
  }
  if (isFalseString(val)) {
    return false
  }
  return val
})
