/* istanbul ignore file */
import {createSelector} from 'reselect'
import {selectUser} from '@modifi/plugin-user'
import {AccessLevel, ProductType} from '@modifi/enums'
import {compose, filter, map, path, pathOr, pluck, propEq, uniq} from 'ramda'
import {USER_PRODUCT_TYPE_TRADE_PARTNER} from '../../constants'

export const selectHasUserLoaded = createSelector(selectUser, user => user !== null)

// @todo use enums when notifications fixed.
export const selectHasUserAccess = createSelector(selectUser, user =>
  [AccessLevel.CREATE_ORDER, 'TRADE_PARTNER'].includes(user?.accessLevel)
)

export const selectAccessLevel = createSelector(selectUser, user => user?.accessLevel)

export const selectFirstName = createSelector(selectUser, path(['firstName']))
export const selectLastName = createSelector(selectUser, path(['lastName']))
export const selectCompanyName = createSelector(
  selectUser,
  path(['company', 'address', 'companyName'])
)
export const selectUserEmail = createSelector(selectUser, path(['email']))

export const selectUserProductTypes = createSelector(
  selectUser,
  compose(uniq, pluck('productType'), pathOr([], ['company', 'financingOptions']))
)

export const selectUserStatistics = createSelector(selectUser, pathOr({}, ['statistics']))

export const selectUserDefaultCurrency = createSelector(
  selectUser,
  pathOr('JPY', ['company', 'address', 'country', 'defaultCurrency'])
)

export const selectUserCompanyId = createSelector(selectUser, path(['company', 'externalId']))

export const selectFinancingOptions = createSelector(
  selectUser,
  pathOr([], ['company', 'financingOptions'])
)
export const selectAvailableProductCurrencies = (state, productType) => {
  const financingOptions = selectFinancingOptions(state)
  const availableFinancingOptions = filter(propEq('productType', productType), financingOptions)
  return uniq(map(path(['settlementCurrency']), availableFinancingOptions))
}

export const selectHasSFProductType = state => {
  const userProductTypes = selectUserProductTypes(state)
  return userProductTypes.includes(ProductType.SELLER_FINANCING)
}

export const selectHasBFProductType = state => {
  const userProductTypes = selectUserProductTypes(state)
  return userProductTypes.includes(ProductType.BUYER_FINANCING)
}

export const selectHasFinancingOptions = createSelector(
  selectFinancingOptions,
  financingOptions => financingOptions.length > 0
)

export const selectUserCountryCode = createSelector(
  selectUser,
  path(['company', 'address', 'countryCode'])
)

export const selectUserActions = createSelector(selectUser, user => user?.actions)

export const selectHasTradePartnerOrders = createSelector(
  selectUser,
  user => user?.company?.statistics?.tradePartnerActiveOrdersCount > 0
)

export const selectHasCreatedTrades = createSelector(
  selectUser,
  selectHasSFProductType,
  (user, isSFCustomer) => isSFCustomer && user?.statistics?.createdOrdersCount > 0
)

export const selectIsMultiUploadEnabled = createSelector(
  selectUser,
  (state, productType) => productType,
  (user, productType) =>
    user?.company?.companyProductConfigurations?.find(config => config.productType === productType)
      ?.multiInvoiceUploadEnabled
)

export const selectIsRepaymentReportEnabled = createSelector(
  selectUser,
  user =>
    user?.company?.companyProductConfigurations?.find(
      config => config.productType === ProductType.SELLER_FINANCING
    )?.repaymentReportEnabled
)

export const selectUserTypes = createSelector(
  selectUserProductTypes,
  selectHasTradePartnerOrders,
  (productTypes, hasTradePartnerOrders) => {
    // Remove 'BF' from productTypes if it exists
    // since BF product is discontinued and filteredProductTypes can be removed
    // once the BE is cleaned up
    const filteredProductTypes = productTypes.filter(type => type !== ProductType.BUYER_FINANCING)

    // add a new type for a counter party of SF
    if (hasTradePartnerOrders) {
      return [...filteredProductTypes, USER_PRODUCT_TYPE_TRADE_PARTNER]
    }

    return filteredProductTypes
  }
)

export const selectUserHasBFOnly = createSelector(
  selectUserTypes,
  products => products.length === 1 && products[0] === ProductType.BUYER_FINANCING
)
