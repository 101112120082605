import {ModifiUiApp, withResponsiveProvider} from '@modifi/ui'
import * as PropTypes from 'prop-types'
import {compose} from 'ramda'
import React from 'react'
import {Helmet} from 'react-helmet'
import GlobalStyles from '../globalStyle'
import PageLayout from './page-layout'

const Layout = ({children, tReady}) => (
  <ModifiUiApp>
    <Helmet>
      <body className="no-body-scroll" />
    </Helmet>
    <PageLayout>{tReady && children}</PageLayout>
    <GlobalStyles />
  </ModifiUiApp>
)

Layout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  tReady: PropTypes.bool,
}

Layout.defaultProps = {
  children: null,
  tReady: true,
}

export default compose(withResponsiveProvider)(Layout)
