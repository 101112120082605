// @todo move to components package
import React from 'react'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default React.forwardRef<SVGSVGElement, {color: string}>((props, ref) => (
  <Svg viewBox="0 0 24 24" {...props} ref={ref}>
    <path
      d="M12 11.4847C10.3067 11.4847 8.92638 10.9509 7.8589 9.88344C6.79141 8.81595 6.25767 7.43558 6.25767 5.74233C6.25767 4.04908 6.79141 2.66871 7.8589 1.60123C8.92638 0.533742 10.3067 0 12 0C13.6933 0 15.0736 0.533742 16.1411 1.60123C17.2086 2.66871 17.7423 4.04908 17.7423 5.74233C17.7423 7.43558 17.2086 8.81595 16.1411 9.88344C15.0736 10.9509 13.6933 11.4847 12 11.4847ZM21.4969 23.7423H2.50307C1.81595 23.7423 1.22699 23.4969 0.736196 23.0061C0.245399 22.5153 0 21.9264 0 21.2393V20.0613C0 19.0798 0.245399 18.2393 0.736196 17.5399C1.22699 16.8405 1.85276 16.3067 2.6135 15.9387C4.28221 15.2025 5.8773 14.6503 7.39877 14.2822C8.92025 13.9141 10.454 13.7301 12 13.7301C13.546 13.7301 15.0736 13.9202 16.5828 14.3006C18.092 14.681 19.681 15.2393 21.3497 15.9755C22.135 16.319 22.773 16.8405 23.2638 17.5399C23.7546 18.2393 24 19.0798 24 20.0613V21.2393C24 21.9264 23.7546 22.5153 23.2638 23.0061C22.773 23.4969 22.184 23.7423 21.4969 23.7423ZM2.50307 21.2393H21.4969V20.0982C21.4969 19.7055 21.3804 19.3313 21.1472 18.9755C20.9141 18.6196 20.6258 18.3558 20.2822 18.184C18.7362 17.4479 17.319 16.9387 16.0307 16.6564C14.7423 16.3742 13.3988 16.2331 12 16.2331C10.6012 16.2331 9.2454 16.3742 7.93252 16.6564C6.61963 16.9387 5.20245 17.4479 3.68098 18.184C3.33742 18.3558 3.05522 18.6196 2.83436 18.9755C2.6135 19.3313 2.50307 19.7055 2.50307 20.0982V21.2393ZM12 8.9816C12.9325 8.9816 13.7055 8.67485 14.319 8.06135C14.9325 7.44785 15.2393 6.67485 15.2393 5.74233C15.2393 4.80982 14.9325 4.03681 14.319 3.42331C13.7055 2.80982 12.9325 2.50307 12 2.50307C11.0675 2.50307 10.2945 2.80982 9.68098 3.42331C9.06749 4.03681 8.76074 4.80982 8.76074 5.74233C8.76074 6.67485 9.06749 7.44785 9.68098 8.06135C10.2945 8.67485 11.0675 8.9816 12 8.9816Z"
      fill={props.color}
    />
  </Svg>
))
