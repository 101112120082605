import styled from 'styled-components'
import {white, darkGray, airBlue, lightGray} from '@modifi/colors'
import {Col} from '@modifi/ui'

export const Wrapper = styled.div``

export const FilterCol = styled(Col)`
  margin-bottom: 16px;
`

// custom styling (do not want to change filter styles yet for regular customers)
export const FilterWrapper = styled.div`
  background-color: ${white};
  div[data-ident='filter-button'] {
    color: ${darkGray};
  }
  svg[data-ident='dropdown-arrow'] {
    color: ${airBlue};
  }
`

export const ActionRequiredWrapper = styled.div`
  background-color: ${white};
  padding: 8px;
  border-radius: 1px;
  border: solid 1px ${lightGray};
  div {
    font-size: 14px;
  }
`

export const ResetFilterWrapper = styled.div`
  button[data-ident='button'] {
    background-color: transparent;
    border-radius: 1px;
    border: solid 1px ${airBlue};
  }
`
