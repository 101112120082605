import React from 'react'
import {landGreen, white} from '@modifi/colors'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default ({color = 'currentColor', ...props}) => (
  <Svg viewBox="0 0 33 33" color={color} {...props}>
    <defs>
      <circle id="a" cx="16.5" cy="16.5" r="16.5" />
    </defs>
    <g fill="none" fillRule="evenodd" id="g24">
      <g>
        <g>
          <g transform="translate(-271 -304) translate(271 297) translate(0 7)">
            <mask id="b" fill={white}>
              <use href="#a" />
            </mask>
            <g fill={landGreen} mask="url(#b)">
              <path d="m 2,0 h 29 c 1.108,0 2,0.892 2,2 v 29 c 0,1.108 -0.892,2 -2,2 H 2 C 0.892,33 0,32.108 0,31 V 2 C 0,0.892 0.892,0 2,0 Z" />
            </g>
            <g mask="url(#b)">
              <g>
                <path
                  stroke={white}
                  strokeWidth="2"
                  d="M0.765 5.662L7.023 11.71 19.178 0.006"
                  transform="translate(7 10.75)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
)
