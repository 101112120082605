import {path} from 'ramda'
import {CollectionFactory} from '@modifi/redux'

import {byTransportModality} from '../../../utils/static/transport-modality-to-inco-term'
import staticIncoTerms from './static'

const request = async () => staticIncoTerms

const {selectors, actions, reducer} = CollectionFactory({
  reducerPath: ['static', 'incoTerms'],
  entityPrimaryKeySelector: path(['code']),
  actionNamePrefix: 'static/inco-terms',
  fetchRequest: () => request(),
})

export default reducer

export const selectIncoTerms = state => selectors.selectByKeyWindow(state, 'all')
export const selectIncoTermsLoading = state => selectors.selectIsKeyWindowLoading(state, 'all')
export const selectIncoTermById = selectors.selectById
export const selectIncoTermNameById = (state, id) => path(['langEN'], selectIncoTermById(state, id))
export const selectIncoTermsByTransportModality = (state, transportModality) => {
  const allIncoTerms = selectIncoTerms(state)

  if (transportModality) {
    const validIncoTermsByTransportModality = byTransportModality(transportModality)
    return allIncoTerms.filter(({code}) => validIncoTermsByTransportModality.includes(code))
  }
  return allIncoTerms
}

export const aFetchIncoTerms = () => actions.fetch('all')
export const aEnsureIncoTerms = () => (dispatch, getState) => {
  if (selectIncoTerms(getState()).length === 0) {
    dispatch(aFetchIncoTerms())
  }
}

/**
 * @deprecated
 * @type {SelectByIdType}
 */
export const selectById = selectIncoTermById
/**
 * @deprecated
 * @param state
 * @param id
 * @return {[number, [number, number, [number, [number, [number, number, *]], number]], *]|f1}
 */
export const selectNameById = selectIncoTermNameById
/**
 * @deprecated
 * @type {function(*=): ArrayOfResult}
 */
export const selectAll = selectIncoTerms
