import {connect} from '@modifi/redux'
import {aCloseDialog} from '@modifi/plugin-dialogs'
import {compose} from 'ramda'
import {withTranslation} from '@modifi/plugin-i18n'

import ShipmentTrackingInformation from './ShipmentTrackingInformation'
import {
  aEnsureContainerRoute,
  selectIsContainerRouteError,
} from '../../custom-plugins/shipment-tracking/store'

const mapStateToProps = (state, ownProps) => ({
  error: selectIsContainerRouteError(state)(
    ownProps?.params?.externalOrderId,
    ownProps?.params?.containerId
  ),
})

const mapDispatchToProps = {
  fetchContainerRoute: aEnsureContainerRoute,
  handleClose: aCloseDialog,
}

export const DIALOG_NAME = 'dialog-shipment-tracking-information'

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('matrix')
)(ShipmentTrackingInformation)
