import {connect} from '@modifi/redux'
import {
  aEnsureBuyerConfirmationDetails,
  aFetchBuyerConfirmationDetails,
  selectModifiComment,
  selectBuyerCommentRequired,
  selectBuyerConfirmationDetailsAreLoading,
  selectBuyerConfirmationDetailsHasError,
  selectBuyerConfirmationDetailsErrorMessage,
  aCleanUpBuyerConfirmationDetails,
  aUpdateBuyerConfirmation,
  aCleanupUpdateBuyerConfirmation,
  selectUpdateBuyerConfirmationPending,
  selectUpdateBuyerConfirmationHasError,
  selectUpdateBuyerConfirmationErrorMessage,
  selectUpdateBuyerConfirmationErrorCode,
} from '../../data/buyer-confirmation'

import BuyerConfirmation, {TradeConfirmationProps} from './BuyerConfirmation'
import {aFetchOrder} from '../../pages/trade-partner-order/store'
import {aFetchOrdersWithUrlParams} from '../../pages/trade-partner-orders/store'
import {aFetchTradePartnerOrdersMetaData} from '../../custom-plugins/trade-partner-order-filters'

const mapStateToProps = (
  state: Record<string, any>
): Pick<
  TradeConfirmationProps,
  | 'isRequestPending'
  | 'modifiComment'
  | 'buyerCommentRequired'
  | 'isUpdatePending'
  | 'hasError'
  | 'errorMessage'
  | 'errorCode'
> => ({
  isRequestPending: selectBuyerConfirmationDetailsAreLoading(state),
  modifiComment: selectModifiComment(state),
  buyerCommentRequired: selectBuyerCommentRequired(state),
  isUpdatePending: selectUpdateBuyerConfirmationPending(state),
  hasError:
    selectBuyerConfirmationDetailsHasError(state) || selectUpdateBuyerConfirmationHasError(state),
  errorMessage:
    selectBuyerConfirmationDetailsErrorMessage(state) ||
    selectUpdateBuyerConfirmationErrorMessage(state),
  errorCode: selectUpdateBuyerConfirmationErrorCode(state),
})

const mapDispatchToProps = {
  ensureTradeConfirmation: aEnsureBuyerConfirmationDetails,
  fetchTradeConfirmation: aFetchBuyerConfirmationDetails,
  fetchOrder: aFetchOrder,
  fetchOrders: aFetchOrdersWithUrlParams,
  fetchOrdersMeta: aFetchTradePartnerOrdersMetaData,
  submit: aUpdateBuyerConfirmation,
  cleanUpUpdate: aCleanupUpdateBuyerConfirmation,
  cleanUpFetch: aCleanUpBuyerConfirmationDetails,
}

export const DIALOG_NAME = 'order/dialog-update-buyer-confirmation'

export default connect(mapStateToProps, mapDispatchToProps)(BuyerConfirmation)
