export default [
  {
    code: 'DOWNPAYMENT_FOR_PRODUCTION',
    langEN: 'Downpayment for production',
  },
  {
    code: 'BEFORE_BILL_OF_LADING',
    langEN: 'Before bill of lading',
  },
  {
    code: 'AFTER_BILL_OF_LADING',
    langEN: 'After bill of lading',
  },
  {
    code: 'BEFORE_AIRWAY_BILL',
    langEN: 'Before airway bill',
  },
  {
    code: 'AFTER_AIRWAY_BILL',
    langEN: 'After airway bill',
  },
  {
    code: 'BEFORE_CMR_CIM',
    langEN: 'Before shipping',
  },
  {
    code: 'AFTER_CMR_CIM',
    langEN: 'After shipping',
  },
  // {
  //   code: 'NOT_APPLICABLE',
  //   langEN: null,
  // },
]
