import {combineReducers} from '@modifi/redux'

import buyerConfirmationDetailsReducer from './buyer-confirmation/reducer'
import tradePartnerStatisticsReducer from './trade-partner-statistics/reducer'
import autoTradeApprovalReducer from './auto-trade-approval/reducer'
import taskReducer from './tasks/collection'

export default combineReducers({
  buyerConfirmation: buyerConfirmationDetailsReducer,
  tradePartnerStatistics: tradePartnerStatisticsReducer,
  autoTradeApproval: autoTradeApprovalReducer,
  tasks: taskReducer,
})
