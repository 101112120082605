import styled from 'styled-components'
import {white} from '@modifi/colors'
import {ButtonLink, Row} from '@modifi/ui'

export const HeaderContainer = styled.div`
  padding: 24px 16px 16px 16px;
  background-color: ${white};
  min-height: 64px;
  margin-bottom: 24px;
`

export const ButtonWithPointerEvents = styled(ButtonLink)`
  pointer-events: all;
`

export const HeaderRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
`
