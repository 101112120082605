import {lightGray, semiDarkGray} from '@modifi/colors'
import {Regular, Row as LayoutRow, Col, topBottomSpacingHelper, media, H4} from '@modifi/ui'
import styled from 'styled-components'

export const Label = styled(Regular)`
  color: ${semiDarkGray};
  display: flex;
  justify-content: space-between;

  ${topBottomSpacingHelper}
`

export const OrderDetailsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  padding: 60px 40px 40px 40px;
`

export const DetailsRow = styled(LayoutRow)`
  margin-bottom: 32px;
  border-bottom: 1px solid ${lightGray};
  :last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`

export const OrderDetailsColumn = styled(Col)`
  ${media.md`
    border-right: 1px solid ${lightGray};
  `}
`

export const Separator = styled.div`
  border-bottom: 1px solid ${lightGray};
  margin-bottom: 32px;
  margin-left: -10px;
  margin-right: -10px;
`

export const OrderAmountValue = styled(H4)`
  ${media.md`
    font-size: 24px;
  `}
  ${media.xl`
    font-size: 32px;
  `}
`
