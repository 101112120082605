import {EntityFactory, Reducer, ThunkAction} from '@modifi/redux'
import {createSelector} from 'reselect'
import {getAuthenticatedAresClient} from '../../../lib'

const apiClient = getAuthenticatedAresClient()

const {actions, reducer, selectors} = EntityFactory({
  actionNamePrefix: 'order/buyer-confirmation/entity',
  rootSelector: state => state?.order?.buyerConfirmation,
  fetchRequest: (state, params: {externalOrderId: string}) =>
    apiClient.Orders.buyerConfirmation({
      externalOrderId: params?.externalOrderId,
    })
      .then(res => res.data())
      .then(data => ({
        ...data,
        externalOrderId: params?.externalOrderId,
      })),
})

export default reducer as Reducer

export const {
  selectIsLoading: selectBuyerConfirmationIsLoading,
  selectIsError: selectBuyerConfirmationHasError,
  selectErrorMessage: selectBuyerConfirmationErrorMessage,
} = selectors

export const selectBuyerConfirmation = (state: Record<string, any>): Record<string, any> =>
  (selectors.selectEntity(state) || {}) as Record<string, any>

export const selectBuyerConfirmationSentRequest = createSelector(
  selectBuyerConfirmation,
  confirmation =>
    (confirmation?.sentTo || []).map((user: any) => {
      const {salutation, firstName, lastName, ...rest} = user
      return {
        requestedOn: confirmation?.requestedOn,
        confirmedOn: confirmation?.confirmedOn,
        name: `${salutation} ${firstName} ${lastName}`,
        ...rest,
      }
    })[0] // only taking first user that request has been sent for now
)

export const selectBuyerConfirmationDetailsWasLoaded = (state: Record<string, any>): boolean =>
  !!selectBuyerConfirmation(state)?.orderReferenceCode

export const aFetchBuyerConfirmation = (externalOrderId: string): any =>
  actions?.fetch({externalOrderId})

export const aCleanUpBuyerConfirmation = actions!.clear

export const aEnsureBuyerConfirmation =
  (externalOrderId: string): ThunkAction<any, any, any, any> =>
  (dispatch, getState): void => {
    if (selectBuyerConfirmation(getState())?.externalOrderId === externalOrderId) {
      return
    }
    dispatch(aFetchBuyerConfirmation(externalOrderId))
  }
