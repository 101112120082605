// @ts-ignore
import styled, {keyframes} from 'styled-components'
import {backgroundColor, lightGray} from '@modifi/colors'

const pulsingAnimation = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -135% 0;
  }
`

const SkeletonPulse = styled.span`
  display: inline-block;
  height: 100%;
  min-width: 100%;
  background: linear-gradient(
    -90deg,
    ${backgroundColor} 0%,
    ${lightGray} 50%,
    ${backgroundColor} 100%
  );
  background-size: 400% 400%;
  animation: ${pulsingAnimation} 1.2s ease-in-out infinite;
`

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const SkeletonLine = styled(SkeletonPulse)<{stringLength?: number | null}>`
  min-width: ${({stringLength = 10}): number | null => stringLength}ch;

  &::before {
    content: '\\00a0';
  }
`

export const SkeletonButton = styled(SkeletonPulse)<{stringLength?: number | null}>`
  height: 36px;
  min-width: ${({children, stringLength = 10}): string =>
    !children ? `${stringLength}ch` : 'auto'};
  padding: 0 ${({children}): string | number => (!children ? '16px' : 0)};
  font-size: 14px;
  color: transparent;
  border-radius: 2px;

  * {
    visibility: hidden;
  }
`
