import {ProductType} from '@modifi/enums'
import {selectAvailableProductCurrencies, selectHasSFProductType} from '../user/selectors'
import {aEnsureTradePartnerStatistics} from '../../data/trade-partner-statistics'

export const aInitializeCreateOrderLimitations = () => (dispatch, getState) => {
  if (selectHasSFProductType(getState())) {
    const referenceCurrency = selectAvailableProductCurrencies(
      getState(),
      ProductType.SELLER_FINANCING
    )[0]
    dispatch(aEnsureTradePartnerStatistics(referenceCurrency, ProductType.SELLER_FINANCING))
  }
}
