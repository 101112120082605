import React from 'react'
import {Col, XIcon, ResetFilterButton} from '@modifi/ui'
import * as PropTypes from 'prop-types'

import {HeaderContainer, ButtonWithPointerEvents, HeaderRow} from './styles'

const Header = ({onClose, filterCount, onReset, t}) => (
  <HeaderContainer>
    <HeaderRow>
      <Col auto>
        <ButtonWithPointerEvents iconOnly big onClick={onClose}>
          <XIcon height={24} width={24} />
        </ButtonWithPointerEvents>
      </Col>
      <Col auto>{t('ol.filters.mobile.headline')}</Col>
      <Col auto>
        <ResetFilterButton filterCount={filterCount} onReset={onReset} active={!!filterCount} />
      </Col>
    </HeaderRow>
  </HeaderContainer>
)

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  filterCount: PropTypes.number,
  onReset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

Header.defaultProps = {
  filterCount: 0,
}

export default Header
