import styled from 'styled-components'
import {Container, media} from '@modifi/ui'

export const ContentContainer = styled(Container)`
  padding-top: 40px;
  padding-bottom: 32px;
  ${media.max.xs`
    padding-left: 10px;
    padding-right: 10px;
  `}
`
export const MenuWithTagContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 16px;
  }
`
