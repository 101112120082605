import React from 'react'
import * as PropTypes from 'prop-types'
import {connect} from '@modifi/redux'
import {getComponentDisplayName} from '@modifi/utilities'
import {selectHasUserLoaded, selectHasUserAccess} from '../../modules/user'

const withUserAccess = WrappedComponent => {
  const ScopedComponent = ({hasUserAccess, userHasLoaded, ...props}) => {
    if (userHasLoaded && !hasUserAccess) {
      return null
    }
    return <WrappedComponent {...props} />
  }

  ScopedComponent.propTypes = {
    hasUserAccess: PropTypes.bool.isRequired,
    userHasLoaded: PropTypes.bool.isRequired,
  }

  const mapStateToProps = state => ({
    hasUserAccess: selectHasUserAccess(state),
    userHasLoaded: selectHasUserLoaded(state),
  })

  const mapDispatchToProps = {}

  ScopedComponent.WrappedComponent = WrappedComponent
  ScopedComponent.displayName = `withUserAccess(${getComponentDisplayName(WrappedComponent)})`

  return connect(mapStateToProps, mapDispatchToProps)(ScopedComponent)
}

export default withUserAccess
