import {aCloseDialog} from '@modifi/plugin-dialogs'
import {aAddSnackBar} from '@modifi/plugin-snackbars'
import {aGenericError} from '@modifi/redux'
import React from 'react'
import {path} from 'ramda'
import {getAuthenticatedAresClient} from '../../../lib'

import {aFetchOrders} from '../../orders/store'
import {aUpdateOrder} from './data.store'
import TranslatedString from '../../../utils/translatedString'

export const ACTION_UPDATE_REVIEW_STATUS_CLEANUP = 'order/UPDATE_REVIEW_STATUS_CLEANUP'
export const ACTION_UPDATE_REVIEW_STATUS_PENDING = 'order/UPDATE_REVIEW_STATUS_PENDING'
export const ACTION_UPDATE_REVIEW_STATUS_SUCCESS = 'order/UPDATE_REVIEW_STATUS_SUCCESS'
export const ACTION_UPDATE_REVIEW_STATUS_ERROR = 'order/UPDATE_REVIEW_STATUS_ERROR'
export const ACTION_CLEAR_ERROR = 'order/UPDATE_REVIEW_STATUS_CLEAR_ERROR'

const initialState = {
  updateReviewStatusPending: false,
  updateReviewStatusError: null,
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_UPDATE_REVIEW_STATUS_CLEANUP:
      return {
        ...state,
        updateReviewStatusPending: false,
        updateReviewStatusError: null,
      }
    case ACTION_UPDATE_REVIEW_STATUS_PENDING:
      return {
        ...state,
        updateReviewStatusPending: true,
        updateReviewStatusError: null,
      }
    case ACTION_UPDATE_REVIEW_STATUS_ERROR: {
      const {message} = payload
      return {
        ...state,
        updateReviewStatusPending: false,
        updateReviewStatusError: message,
      }
    }
    case ACTION_UPDATE_REVIEW_STATUS_SUCCESS:
      return {
        ...state,
        updateReviewStatusPending: false,
        updateReviewStatusError: null,
      }
    case ACTION_CLEAR_ERROR:
      return {
        ...state,
        updateReviewStatusError: null,
      }
    default:
      return state
  }
}

export const aUpdateReviewStatusCleanup = () => ({
  type: ACTION_UPDATE_REVIEW_STATUS_CLEANUP,
  payload: {},
})
export const aUpdateTradeConfirmation =
  (externalOrderId, reviewStatus, updateOrder) => async dispatch => {
    dispatch({
      type: ACTION_UPDATE_REVIEW_STATUS_PENDING,
      payload: {externalOrderId},
    })

    let result = {}
    try {
      const client = getAuthenticatedAresClient()
      result = await client.Orders.updateReferenceCode({
        externalOrderId,
        body: {
          reviewStatus,
        },
      }).then(res => res.data())
    } catch (error) {
      dispatch(aGenericError(ACTION_UPDATE_REVIEW_STATUS_ERROR, error))
      throw error
    }

    dispatch({
      type: ACTION_UPDATE_REVIEW_STATUS_SUCCESS,
    })

    if (updateOrder) dispatch(aUpdateOrder(result))
    dispatch(aCloseDialog())
    const snackBarOptions = {
      message: (
        <TranslatedString transString={`co.snackbar.order-review-${reviewStatus}.message`} />
      ),
      type: 'success',
    }
    dispatch(aAddSnackBar(snackBarOptions))
    dispatch(aFetchOrders())
  }

export const selectIsUpdateReviewStatusPending = state =>
  path(['order', 'updateReviewStatus', 'updateReviewStatusPending'], state)
export const selectUpdateReviewStatusError = state =>
  path(['order', 'updateReviewStatus', 'updateReviewStatusError'], state)

export const aClearError = () => dispatch => dispatch({type: ACTION_CLEAR_ERROR})
