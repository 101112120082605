// @todo move to components package
import React from 'react'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default React.forwardRef<SVGSVGElement, {color: string}>((props, ref) => (
  <Svg viewBox="0 0 20 24" {...props} ref={ref}>
    <path
      d="M9.78119 11.937C10.3742 11.937 10.8817 11.7259 11.3038 11.3036C11.726 10.8813 11.937 10.3737 11.937 9.78071C11.937 9.18773 11.7259 8.68018 11.3036 8.25806C10.8813 7.83595 10.3737 7.6249 9.78071 7.6249C9.18773 7.6249 8.68018 7.83604 8.25806 8.25833C7.83595 8.6806 7.6249 9.18822 7.6249 9.78119C7.6249 10.3742 7.83604 10.8817 8.25833 11.3038C8.6806 11.726 9.18822 11.937 9.78119 11.937ZM9.78095 21.718C12.4155 19.3153 14.3616 17.1361 15.6192 15.1804C16.8768 13.2246 17.5056 11.5045 17.5056 10.0201C17.5056 7.68661 16.7601 5.77594 15.2691 4.28809C13.7782 2.80023 11.9493 2.0563 9.78258 2.0563C7.61585 2.0563 5.78646 2.80038 4.2944 4.28854C2.80233 5.7767 2.0563 7.68774 2.0563 10.0217C2.0563 11.5072 2.70026 13.2266 3.98817 15.18C5.27606 17.1334 7.20699 19.3127 9.78095 21.718ZM9.79423 24C9.64059 24 9.49257 23.9763 9.35019 23.9288C9.20782 23.8813 9.07911 23.8101 8.96408 23.7152C5.96118 21.0651 3.71644 18.6107 2.22985 16.3519C0.743284 14.0931 0 11.9833 0 10.0224C0 6.9668 0.983121 4.53249 2.94936 2.7195C4.9156 0.906498 7.19258 0 9.78029 0C12.368 0 14.6452 0.906498 16.6119 2.7195C18.5786 4.53249 19.5619 6.9668 19.5619 10.0224C19.5619 11.979 18.8186 14.0878 17.3321 16.3487C15.8455 18.6096 13.6007 21.0651 10.5978 23.7152C10.4828 23.8101 10.3585 23.8813 10.225 23.9288C10.0915 23.9763 9.94788 24 9.79423 24Z"
      fill={props.color}
    />
  </Svg>
))
