import styled from 'styled-components'
import {lightGray} from '@modifi/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const TextCenter = styled.div`
  text-align: center;
  width: 80%;
  margin: 0 auto;
`

export const FooterSeparator = styled.div`
  position: relative;
  border-bottom: 1px solid ${lightGray};
  margin: 40px -45px 0 -54px;
`
