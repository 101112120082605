import {aGenericError} from '@modifi/redux'
import {path, findIndex, propEq} from 'ramda'
import {getAuthenticatedAresClient} from '../../../lib'
import {selectOrder, aUpdateOrder} from './data.store'

export const ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_CLEANUP =
  'fundsTransfer/EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_CLEANUP'
export const ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_PENDING =
  'fundsTransfer/EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_PENDING'
export const ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_SUCCESS =
  'fundsTransfer/EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_SUCCESS'
export const ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_ERROR =
  'fundsTransfer/EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_ERROR'

const initialState = {
  editFundsTransferArrivalDatePending: false,
  editFundsTransferArrivalDateError: null,
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_CLEANUP:
      return {
        ...state,
        editFundsTransferArrivalDatePending: false,
        editFundsTransferArrivalDateError: null,
      }
    case ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_PENDING:
      return {
        ...state,
        editFundsTransferArrivalDatePending: true,
        editFundsTransferArrivalDateError: null,
      }
    case ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_ERROR: {
      const {message} = payload
      return {
        ...state,
        editFundsTransferArrivalDatePending: false,
        editFundsTransferArrivalDateError: message,
      }
    }
    case ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_SUCCESS:
      return {
        ...state,
        editFundsTransferArrivalDatePending: false,
        editFundsTransferArrivalDateError: null,
      }
    default:
      return state
  }
}

export const aEditFundsTransferArrivalDateCleanup = () => ({
  type: ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_CLEANUP,
  payload: {},
})

export const aEditFundsTransferArrivalDate =
  (paymentExternalId, fundsTransferExternalId, fundsArrivalDate) => async (dispatch, getState) => {
    dispatch({
      type: ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_PENDING,
      payload: {fundsTransferExternalId},
    })

    let result = {}
    try {
      const client = getAuthenticatedAresClient()
      result = await client.FundsTransfers.updateFundsTransferArrivalDate({
        externalId: fundsTransferExternalId,
        body: {
          confirmedFundsTransferArrivalDate: fundsArrivalDate,
        },
      }).then(res => res.data())
    } catch (error) {
      dispatch(aGenericError(ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_ERROR, error))
      throw error
    }

    dispatch({
      type: ACTION_EDIT_FUNDS_TRANSFER_ARRIVAL_DATE_SUCCESS,
      payload: result,
    })

    const order = selectOrder(getState())
    const {payments} = order
    const paymentIndex = findIndex(propEq('externalId', paymentExternalId), payments)
    const fundsTransferIndex = findIndex(
      propEq('externalId', fundsTransferExternalId),
      payments[paymentIndex].fundsTransfers
    )
    payments[paymentIndex].fundsTransfers[fundsTransferIndex] = result
    dispatch(aUpdateOrder({...order, payments}))
  }

export const selectIsEditFundsTransferArrivalDatePending = state =>
  path(['order', 'editFundsTransferArrivalDate', 'editFundsTransferArrivalDatePending'], state)
export const selectEditFundsTransferArrivalDateError = state =>
  path(['order', 'editFundsTransferArrivalDate', 'editFundsTransferArrivalDateError'], state)
