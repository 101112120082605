import {getAuthenticatedAresClient} from '../../../lib'

export const searchTradePartners = searchString => {
  const client = getAuthenticatedAresClient()
  return client.TradePartners.getTradePartners({
    'search.companyName': searchString,
    pageIndex: 0,
    pageSize: 5,
    resultFields: ['tradePartner.externalCompanyId', 'tradePartner.address.companyName'],
  }).then(response => response.data().tradePartners)
}
