import React, {useCallback} from 'react'
import {find, propEq} from 'ramda'
import * as PropTypes from 'prop-types'
import {ButtonSecondary, SortableIcon, RadioFilter} from '@modifi/ui'
import {getSortOptions} from '../constants'

const SortFilter = ({orderBy, orderDirection, t, tReady, handleSubmit}) => {
  const getSelectedSortFilter = () => {
    if (!orderBy || !orderDirection) return null
    if (orderBy[0] === 'createdOn' && orderDirection[0]) {
      return find(propEq('value', orderDirection[0]), getSortOptions(t))
    }
    return null
  }

  const handleSubmitCb = useCallback(
    selected => {
      if (selected) handleSubmit(['createdOn'], [selected.value])
    },
    [handleSubmit]
  )

  const handleCancelCb = useCallback(() => {
    handleSubmit(['createdOn'], ['DESC'])
  }, [handleSubmit])

  if (!tReady) {
    return null
  }

  return (
    <RadioFilter
      options={getSortOptions(t)}
      defaultSelected={getSelectedSortFilter()}
      fieldName={t('ol.sort-filter.fieldName')}
      onSubmit={handleSubmitCb}
      onReset={handleCancelCb}
      isSortFilter
      label={t('ol.sort-filter.headline')}
      buttonComponent={ButtonSecondary}
      bordered
      icon={<SortableIcon />}
      dropdownPosition="right"
      dropdownWidth={250}
    />
  )
}

SortFilter.propTypes = {
  orderBy: PropTypes.arrayOf(PropTypes.string),
  orderDirection: PropTypes.arrayOf(PropTypes.oneOf(['ASC', 'DESC'])),
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tReady: PropTypes.bool.isRequired,
}

SortFilter.defaultProps = {
  orderBy: [],
  orderDirection: [],
}

export default SortFilter
