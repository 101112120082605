import {aAddSnackBar} from '@modifi/plugin-snackbars'
import download from 'downloadjs'
import ms from 'ms'
import React from 'react'
import {getAuthenticatedAresClient} from '../../../../lib'
import {selectExternalOrderId} from '../data.store'
import TranslatedString from '../../../../utils/translatedString'

export const aFetchFile = (fileId, fileName, mimeType) => async (dispatch, getState) => {
  const externalOrderId = selectExternalOrderId(getState())
  if (!fileId || !fileName || !mimeType) {
    dispatch(
      aAddSnackBar({
        type: 'alert',
        message: (
          <TranslatedString
            transString="tp-om.file-download-failed.internal-error"
            values={{fileName: fileName || 'file'}}
          />
        ),
      })
    )
    return
  }

  const apiClient = getAuthenticatedAresClient()

  try {
    const fileRaw = await apiClient.TradePartnerOrders.getOrderDocument({
      externalOrderId,
      fileId,
      timeout: ms('120s'),
    }).then(res => res.data())

    if (!fileRaw) {
      dispatch(
        aAddSnackBar({
          type: 'alert',
          message: (
            <TranslatedString
              transString="tp-om.file-download-failed.downloading-error"
              values={{fileName: fileName || 'file'}}
            />
          ),
        })
      )
    } else {
      download(fileRaw, fileName, mimeType)
    }
  } catch (e) {
    dispatch(
      aAddSnackBar({
        type: 'alert',
        message: (
          <TranslatedString
            transString="tp-om.file-download-failed.downloading-error"
            values={{fileName: fileName || 'file'}}
          />
        ),
      })
    )
    throw e
  }
}
