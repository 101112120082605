// inspired by https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
import {ButtonLink, CheckmarkIcon, CopyIcon} from '@modifi/ui'
import * as PropTypes from 'prop-types'
import React, {useEffect, useRef, useState} from 'react'

import {HiddenTextArea} from './styles'

const CopyButton = ({buttonComponent: ButtonComponent, children, copyString, ...restProps}) => {
  const [copied, setCopied] = useState(false)
  const textareaRef = useRef(null)

  const handleClick = () => {
    const previouslySelected =
      document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
    textareaRef.current.select()
    document.execCommand('copy')
    setCopied(true)
    if (previouslySelected) {
      document.getSelection().removeAllRanges()
      document.getSelection().addRange(previouslySelected)
    }
  }

  useEffect(() => {
    let timeout
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false)
      }, 1000)
    }
    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <>
      <ButtonComponent
        icon={!!children}
        iconOnly={!children}
        onClick={handleClick}
        active={!copied}
        {...restProps}
      >
        {copied ? <CheckmarkIcon /> : <CopyIcon />}
        {children && <span>{copied ? 'Copied' : children}</span>}
      </ButtonComponent>
      <HiddenTextArea ref={textareaRef} value={copyString} readOnly />
    </>
  )
}

CopyButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  buttonComponent: PropTypes.object,
  children: PropTypes.node,
  copyString: PropTypes.string.isRequired,
}

CopyButton.defaultProps = {
  buttonComponent: ButtonLink,
  children: null,
}

export default CopyButton
