import {compose} from 'ramda'
import {connect} from '@modifi/redux'
import {withTranslation} from '@modifi/plugin-i18n'
import EventTimeline from './EventTimeline'
import {selectFormattedContainerRouteEvents} from '../../store'
import {withShipmentTrackingContext} from '../shipment-tracking-manager'

const mapStateToProps = (state, ownProps) => ({
  containerEvents: selectFormattedContainerRouteEvents(state)(ownProps.externalOrderId),
})

const mapDispatchToProps = {}

export default compose(
  withShipmentTrackingContext,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('matrix')
)(EventTimeline)
