// @todo move to components package
import React from 'react'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default React.forwardRef<SVGSVGElement, {color: string}>((props, ref) => (
  <Svg viewBox="0 0 24 22" {...props} ref={ref}>
    <path
      d="M2.01618 21.6255C1.46174 21.6255 0.987097 21.4281 0.592247 21.0332C0.197416 20.6384 0 20.1637 0 19.6093V2.01618C0 1.46172 0.197416 0.987078 0.592247 0.592247C0.987097 0.197416 1.46174 0 2.01618 0H9.85065C10.4051 0 10.8797 0.197416 11.2746 0.592247C11.6694 0.987078 11.8668 1.46172 11.8668 2.01618V4.88286H21.9838C22.5383 4.88286 23.0129 5.08028 23.4078 5.47511C23.8026 5.86994 24 6.34458 24 6.89904V19.6093C24 20.1637 23.8026 20.6384 23.4078 21.0332C23.0129 21.4281 22.5383 21.6255 21.9838 21.6255H2.01618ZM1.93835 19.6871H5.04562V16.5798H1.93835V19.6871ZM1.93835 14.8042H5.04562V11.697H1.93835V14.8042ZM1.93835 9.92138H5.04562V6.81411H1.93835V9.92138ZM1.93835 5.03852H5.04562V1.93125H1.93835V5.03852ZM6.82121 19.6871H9.92848V16.5798H6.82121V19.6871ZM6.82121 14.8042H9.92848V11.697H6.82121V14.8042ZM6.82121 9.92138H9.92848V6.81411H6.82121V9.92138ZM6.82121 5.03852H9.92848V1.93125H6.82121V5.03852ZM11.7041 19.6871H22.0617V6.81411H11.7041V9.92138H14.0715V11.697H11.7041V14.8042H14.0715V16.5798H11.7041V19.6871ZM17.1788 11.697V9.92138H18.9544V11.697H17.1788ZM17.1788 16.5798V14.8042H18.9544V16.5798H17.1788Z"
      fill={props.color}
    />
  </Svg>
))
