/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import forge, {Middleware} from 'mappersmith'
import type ApolloClientClientInterface from './ApolloClientClientInterface'

type FactoryOptions = {
  host?: string
  middleware?: Middleware[]
}

const ApolloClientClientFactory = ({
  host,
  middleware,
}: FactoryOptions = {}): ApolloClientClientInterface =>
  forge({
    clientId: 'matrix:apollo',
    host: host || '',
    middlewares: [...(middleware || [])],
    resources: {
      Documents: {
        metaByIds: {
          path: '/api/v1/documents/metaData',
          method: 'get',
        },
        search: {
          path: '/api/v1/documents/{externalDocumentId}/search',
          method: 'post',
        },
      },
    },
  })

export default ApolloClientClientFactory
