import styled from 'styled-components'
import {RegularBlock, Strong} from '@modifi/ui'
import {landGreen, lightGray} from '@modifi/colors'

export const EventText = styled(RegularBlock)`
  font-size: 14px;
`

export const StrongText = styled(Strong)`
  font-size: 14px;
`

export const Timeline = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
`

export const Event = styled.li`
  margin-bottom: 30px;
  padding: 0 30px;
  position: relative;
  list-style-type: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    background-color: ${({isPast}) => (isPast ? landGreen : lightGray)};
  }

  &::after {
    content: '';
    border-left: ${({isPast}) => (isPast ? `5px solid ${landGreen}` : `2px dashed ${lightGray}`)};
    left: ${({isPast}) => (isPast ? '5px' : '6px')};
    top: 15px;
    height: calc(100% + 15px);
    position: absolute;
    bottom: 0;
    display: ${({lastEvent}) => (lastEvent ? 'none' : 'block')};
  }
`
