import {pathOr, path, compose, map, head, curry} from 'ramda'
import {createSelector} from 'reselect'
import {CollectionFactory} from '@modifi/redux'
import {selectCountryNameByAlpha2} from '@modifi/plugin-countries'
import {getAuthenticatedAresClient} from '../../../lib'
import {ROOT_REDUCER_KEY, REDUCER_KEY, ACTION_KEY} from '../constants'
import {getFormattedContainerEvents} from '../utils'

const {selectors, actions, reducer} = CollectionFactory({
  actionNamePrefix: `custom-plugins/${ACTION_KEY}/order-container-route`,
  reducerPath: [ROOT_REDUCER_KEY, REDUCER_KEY, 'orderContainerRoute'],
  fetchRequest: async (state, {externalOrderId, containerId}) => {
    const client = getAuthenticatedAresClient()
    return client.Orders.containerRoute({externalOrderId, containerId}).then(res => [
      {...res.data(), uniqueId: externalOrderId},
    ])
  },
  entityPrimaryKeySelector: path(['uniqueId']),
})

export default reducer

export const {
  selectByKeyWindow,
  selectIsKeyWindowLoading,
  selectIsKeyWindowError,
  selectFetchCountByKeyWindow,
  selectById,
} = selectors

// selectors
export const selectContainerRouteIsLoading = state => externalOrderId =>
  selectIsKeyWindowLoading(state, {externalOrderId})

export const selectContainerRouteContainsOrder = state => externalOrderId =>
  pathOr(null, ['containsOrder'], selectByKeyWindow(state, {externalOrderId})?.[0])

export const selectContainerId = state => externalOrderId =>
  pathOr(null, ['containerId'], selectByKeyWindow(state, {externalOrderId})?.[0])

export const selectContainerRouteEvents = state => externalOrderId =>
  pathOr([], ['containerEvents'], selectByKeyWindow(state, {externalOrderId})?.[0])

export const selectLocationCountryName = curry((state, alpha2) =>
  selectCountryNameByAlpha2(state, alpha2)
)

export const selectContainerEventsWithCountryName = createSelector(
  selectContainerRouteEvents,
  selectLocationCountryName,
  (getEvents, getCountryName) => externalOrderId =>
    compose(
      map(({location, ...rest}) => ({
        ...rest,
        location: {
          ...location,
          countryName: getCountryName(location?.countryCode),
        },
      }))
    )(getEvents(externalOrderId))
)

// for event time line
export const selectFormattedContainerRouteEvents = createSelector(
  selectContainerEventsWithCountryName,
  getEvents => externalOrderId => getFormattedContainerEvents(getEvents(externalOrderId))
)

export const selectContainerRouteFetchCount = state => externalOrderId =>
  selectFetchCountByKeyWindow(state, {externalOrderId})

export const selectContainerRouteHasLoaded = createSelector(
  selectContainerRouteFetchCount,
  fetchCount => externalOrderId => fetchCount(externalOrderId) > 0
)

// for progress bar
export const selectContainerRouteData = state => externalOrderId =>
  compose(
    head,
    map(props => {
      const start = props?.shippingStartDate
      const end = props?.shippingEndDate
      const containerEvents = props?.containerEvents
      if (start && end && containerEvents.length > 0) {
        return {
          start,
          end,
          startLocation: containerEvents?.[0]?.location,
          endLocation: containerEvents?.[containerEvents.length - 1]?.location,
        }
      }
      return null
    })
  )(selectByKeyWindow(state, {externalOrderId}))

export const selectIsContainerRouteDataLoading = createSelector(
  selectContainerRouteIsLoading,
  selectContainerRouteHasLoaded,
  selectContainerRouteData,
  (getIsDataLoading, getHasDataLoaded, getContainerData) => externalOrderId =>
    (!getIsDataLoading(externalOrderId) &&
      !getContainerData(externalOrderId) &&
      !getHasDataLoaded(externalOrderId)) ||
    getIsDataLoading(externalOrderId)
)

export const selectIsContainerRouteError = state => externalOrderId =>
  selectIsKeyWindowError(state, {externalOrderId})

export const selectIsContainerRouteEmpty = createSelector(
  selectContainerRouteIsLoading,
  selectIsContainerRouteError,
  (getIsLoading, getHasError) => externalOrderId =>
    !getIsLoading(externalOrderId) && getHasError(externalOrderId)
)

// actions
export const aFetchContainerRoute = (externalOrderId, containerId) =>
  actions.fetch({externalOrderId}, {externalOrderId, containerId})

export const aEnsureContainerRoute = (externalOrderId, containerId) => (dispatch, getState) => {
  if (
    selectContainerRouteIsLoading(getState())(externalOrderId, containerId) ||
    selectContainerRouteHasLoaded(getState())(externalOrderId, containerId)
  ) {
    return
  }
  dispatch(aFetchContainerRoute(externalOrderId, containerId))
}
