import {
  RuleFactory,
  RawRuleOf,
  Ability,
  useAbility as rawUseAbility,
  defineAbilityFor as rawDefineAbilityFor,
  AccessTokenData,
  Subject,
  asPermissionSubject,
} from '@modifi/plugin-authorization'
import {
  CONFIG_KEY_AUTH_TOKEN_SCOPE,
  CONFIG_KEY_AUTH_TOKEN_AUTHORITIES,
  CONFIG_KEY_AUTH_USER_NAME,
} from '@modifi/plugin-authentication'

import {clientConfig} from '@modifi/client-config'
import {
  NAVIGATION_ONBOARDING,
  NAVIGATION_E_INVOICES,
  NAVIGATION_ORDERS,
  NAVIGATION_OVERVIEW,
  NAVIGATION_BILLING,
  NAVIGATION_SHIPMENT_TRACKER,
} from './navigation'

type Authority =
  | 'hub:read'
  | 'hub:write'
  | 'billing:read'
  | 'billing:write'
  | 'onboarding:read'
  | 'onboarding:write'
  | 'services:shipment-tracker:read'
  | 'services:shipment-tracker:write'
  | 'services:e-invoice:read'
  | 'services:e-invoice:write'

type Abilities =
  | [
      'read' | 'write',
      (
        | 'service:hub'
        | 'service:onboarding'
        | 'service:billing'
        | 'service:shipment-tracker'
        | 'service:e-invoice'
        | 'main-navigation'
        | Exclude<Subject, string>
      )
    ]
  | ['access-service', 'matrix' | 'treseus' | 'argus' | 'charon']

export type AppAbility = Ability<Abilities>

export type Rule = RawRuleOf<AppAbility>

const HUB_READ: RuleFactory<Rule> = () => [
  {
    action: 'read',
    subject: 'main-navigation',
    conditions: {
      page: {
        $in: [NAVIGATION_OVERVIEW, NAVIGATION_ORDERS],
      },
    },
  },
  {
    action: 'access-service',
    subject: 'matrix',
  },
]

const HUB_WRITE: RuleFactory<Rule> = () => []

const BILLING_READ: RuleFactory<Rule> = () => [
  {
    action: 'read',
    subject: 'main-navigation',
    conditions: {
      page: {
        $in: [NAVIGATION_BILLING],
      },
    },
  },
  {
    action: 'access-service',
    subject: 'treseus',
  },
]

const BILLING_WRITE: RuleFactory<Rule> = () => []

const ONBOARDING_READ: RuleFactory<Rule> = () => [
  {
    action: 'read',
    subject: 'main-navigation',
    conditions: {
      page: {
        $in: [NAVIGATION_ONBOARDING],
      },
    },
  },
  {
    action: 'access-service',
    subject: 'charon',
  },
]

const ONBOARDING_WRITE: RuleFactory<Rule> = () => []

const SHIPMENT_TRACKER_READ: RuleFactory<Rule> = () => [
  {
    action: 'read',
    subject: 'main-navigation',
    conditions: {
      page: {
        $in: [NAVIGATION_SHIPMENT_TRACKER],
      },
    },
  },
  {
    action: 'access-service',
    subject: 'argus',
  },
]

const SHIPMENT_TRACKER_WRITE: RuleFactory<Rule> = () => []

const E_INVOICE_READ: RuleFactory<Rule> = () => [
  {
    action: 'read',
    subject: 'main-navigation',
    conditions: {
      page: {
        $in: [NAVIGATION_E_INVOICES],
      },
    },
  },
]

const E_INVOICE_WRITE: RuleFactory<Rule> = () => []

export const AUTHORITY_PERMISSIONS_MAP = new Map<Partial<Authority>, RuleFactory<Rule>>([
  ['hub:read', HUB_READ],
  ['hub:write', HUB_WRITE],
  ['billing:read', BILLING_READ],
  ['billing:write', BILLING_WRITE],
  ['onboarding:read', ONBOARDING_READ],
  ['onboarding:write', ONBOARDING_WRITE],
  ['services:shipment-tracker:read', SHIPMENT_TRACKER_READ],
  ['services:shipment-tracker:write', SHIPMENT_TRACKER_WRITE],
  ['services:e-invoice:read', E_INVOICE_READ],
  ['services:e-invoice:write', E_INVOICE_WRITE],
])

export const abilityFactory = (): AppAbility => {
  const tokenData: AccessTokenData = {
    // @ts-expect-error bad typing
    userName: clientConfig.get(CONFIG_KEY_AUTH_USER_NAME, ''),
    // @ts-expect-error bad typing
    scope: clientConfig.get(CONFIG_KEY_AUTH_TOKEN_SCOPE, []),
    // @ts-expect-error bad typing
    authorities: clientConfig.get(CONFIG_KEY_AUTH_TOKEN_AUTHORITIES, []),
  }

  return rawDefineAbilityFor<Abilities>(
    tokenData,
    {},
    {authorityPermissions: AUTHORITY_PERMISSIONS_MAP}
  )
}

export const useAbility = (): AppAbility => rawUseAbility<AppAbility>()

export const asMainNavigationSubject = (page: string): Subject =>
  asPermissionSubject('main-navigation', {page})
