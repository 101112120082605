import {pathOr} from 'ramda'
import {
  REDUCER_KEY,
  CONFIG_FORCE_SCROLLBAR,
  CONFIG_SHOW_ANNOUNCEMENT,
  CONFIG_STATISTICS_CURRENCY,
} from './constants'

const ACTION_SET = 'config/SET'
const ACTION_DELETE = 'config/DELETE'

const initialState = {
  [CONFIG_FORCE_SCROLLBAR]: false,
  [CONFIG_SHOW_ANNOUNCEMENT]: true,
  [CONFIG_STATISTICS_CURRENCY]: null,
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_SET: {
      return {
        ...state,
        ...payload,
      }
    }
    case ACTION_DELETE: {
      // eslint-disable-next-line no-shadow
      const {payload, ...restState} = state
      return restState
    }
    default:
      return state
  }
}

export const aSetConfig = (key, val) => ({
  type: ACTION_SET,
  payload: {
    [key]: val,
  },
})

export const aDeleteConfig = key => ({
  type: ACTION_DELETE,
  payload: key,
})

export const selectConfig = (state, key, fallback = null) =>
  pathOr(fallback, [REDUCER_KEY, key], state)
