import {path} from 'ramda'

import {CollectionFactory} from '@modifi/redux'
import staticPayoutTimings from './static'

const request = async () => staticPayoutTimings

const {selectors, actions, reducer} = CollectionFactory({
  reducerPath: ['static', 'payoutTimings'],
  entityPrimaryKeySelector: path(['code']),
  actionNamePrefix: 'static/payout-timings',
  fetchRequest: () => request(),
})

export default reducer

export const selectPayoutTimings = state => selectors.selectByKeyWindow(state, 'all')
export const selectPayoutTimingsLoading = state => selectors.selectIsKeyWindowLoading(state, 'all')
export const selectPayoutTimingById = selectors.selectById
export const selectPayoutTimingNameById = (state, id) =>
  path(['langEN'], selectPayoutTimingById(state, id))

export const aFetchPayoutTimings = () => actions.fetch('all')
export const aEnsurePayoutTimings = () => (dispatch, getState) => {
  if (selectPayoutTimings(getState()).length === 0) {
    dispatch(aFetchPayoutTimings())
  }
}
