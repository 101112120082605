import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {ButtonLink} from '@modifi/ui'
import {FormattedDate, Trans} from '@modifi/plugin-i18n'
import {Link} from '@modifi/router'
import {clientConfig} from '@modifi/client-config'
import {Event, EventText, Timeline, StrongText as Strong} from './styles'

const marineTrafficUrl = clientConfig.get('SERVICE_MARINE_TRAFFIC')

const EventTimeline = ({containerEvents, t}) => (
  <Timeline>
    {containerEvents.map((containerEvent, index) => {
      const isTransit = !!containerEvent?.departureDate
      const lastEventDate = isTransit
        ? containerEvent?.arrivalDate
        : containerEvent?.events?.[containerEvent?.events?.length - 1].date
      return (
        <Event
          key={containerEvent?.id}
          isPast={moment(lastEventDate).isSameOrBefore()}
          lastEvent={index === containerEvents?.length - 1}
        >
          {!isTransit && (
            <EventText>
              <Strong>
                {`${containerEvent?.location?.name}, ${containerEvent?.location?.countryName}`}
              </Strong>
            </EventText>
          )}
          {isTransit ? (
            <>
              <EventText>
                <Trans
                  t={t}
                  i18nKey={`container-events-transit-estimation.${containerEvent?.isEstimated}.label`}
                  values={{
                    departureLocationName: containerEvent?.departureLocation?.name,
                    departureLocationCountryName: containerEvent?.departureLocation?.countryName,
                    arrivalLocationName: containerEvent?.arrivalLocation?.name,
                    arrivalLocationCountryName: containerEvent?.arrivalLocation?.countryName,
                    count: moment(containerEvent?.arrivalDate).diff(
                      containerEvent?.departureDate,
                      'days'
                    ),
                  }}
                  components={<Strong />}
                />
              </EventText>

              {containerEvent?.vesselNumber && (
                <Link
                  to={`${marineTrafficUrl}/en/ais/details/ships/mmsi:${containerEvent?.vesselNumber}`}
                  isExternal
                  targetBlank
                >
                  <ButtonLink transparent>
                    <span>
                      {t('container-events-vessel.link', {number: containerEvent?.vesselNumber})}
                    </span>
                  </ButtonLink>
                </Link>
              )}

              <EventText ml={10} mt={5}>
                <FormattedDate>{containerEvent?.departureDate}</FormattedDate> -{' '}
                <FormattedDate>{containerEvent?.arrivalDate}</FormattedDate>
              </EventText>
            </>
          ) : (
            containerEvent?.events.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <EventText ml={10} mt={5} key={`${item?.code}_${i}`}>
                <FormattedDate>{item.date}</FormattedDate> -{' '}
                {t(`container-event.${item?.code}.label`)}{' '}
                {item?.isEstimated ? t('container-event-estimated.text') : ''}
              </EventText>
            ))
          )}
        </Event>
      )
    })}
  </Timeline>
)

EventTimeline.propTypes = {
  containerEvents: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.shape({
        name: PropTypes.string,
        countryName: PropTypes.string,
      }),
      events: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          date: PropTypes.string,
          isEstimated: PropTypes.bool,
          vesselNumber: PropTypes.string,
        })
      ),
    })
  ),
  t: PropTypes.func.isRequired,
}

EventTimeline.defaultProps = {
  containerEvents: null,
}

export default EventTimeline
