import {connect} from '@modifi/redux'
import {path, compose, pickBy, startsWith} from 'ramda'
import {withTranslation} from '@modifi/plugin-i18n'
import {selectSearchObj, aReplace} from '@modifi/router'
import qs from 'qs'

import SortFilter from './SortFilter'

const mapStateToProps = state => ({
  location: path(['router', 'location'], state),
  search: selectSearchObj(state),
  orderBy: path(['orderBy'], selectSearchObj(state)),
  orderDirection: path(['orderDirection'], selectSearchObj(state)),
  filters: pickBy((val, key) => startsWith('filter', key), selectSearchObj(state)),
})

const mapDispatchToProps = {
  replaceUrl: aReplace,
}

const mergeProps = (mapProps, dispatchProps, ownProps) => {
  const {search, location, filters} = mapProps
  const {replaceUrl} = dispatchProps
  return {
    handleSubmit: (updatedOrderBy, updatedOrderDirection) => {
      replaceUrl({
        ...location,
        search: qs.stringify({
          ...search,
          orderBy: updatedOrderBy,
          orderDirection: updatedOrderDirection,
          ...filters,
        }),
      })
    },
    ...mapProps,
    ...dispatchProps,
    ...ownProps,
  }
}

export default compose(
  withTranslation(['matrix', 'ui']),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(SortFilter)
