import {AsyncAction, CollectionFactory, Reducer} from '@modifi/redux'
import {getAuthenticatedAresClient} from '../../../lib'
import {ACTION_KEY} from '../constants'
import keyWindowFromUrl from '../../../utils/keyWindowFromUrl'

const {actions, selectors, reducer} = CollectionFactory({
  actionNamePrefix: ACTION_KEY,
  rootSelector: state => state?.tradePartnerOrders?.data,
  // @ts-expect-error wrong typing
  fetchRequest: (state, params) => {
    const client = getAuthenticatedAresClient()
    // @ts-expect-error wrong typing
    return client.TradePartnerOrders.all(params)
      .then(res => res.data())
      .then(data => ({
        // eslint-disable-next-line no-underscore-dangle
        meta: data?._meta ?? undefined,
        entities: data?.orders ?? [],
      }))
  },
  entityPrimaryKeySelector: entity => entity.externalId,
})

export default reducer as Reducer

export const selectOrderById = selectors.selectById

export const selectOrdersByParams = selectors.selectByKeyWindow
export const selectOrdersAreLoadingByParams = selectors.selectIsKeyWindowLoading
export const selectOrdersFetchCountByParams = selectors.selectFetchCountByKeyWindow
export const selectOrdersErrorByParams = selectors.selectIsKeyWindowError

export const selectOrdersMetaByParams = selectors.selectMetaByKeyWindow

export const selectOrdersTotalCountForParams = (
  state: Record<string, any>,
  params: {maxPageIndex: number; totalCount: number}
): number => selectOrdersMetaByParams(state, params)?.totalCount

export const selectOrdersTotalPagesForParams = (
  state: Record<string, any>,
  params: {maxPageIndex: number; totalCount: number}
): number => selectOrdersMetaByParams(state, params)?.maxPageIndex

export const aFetchOrdersWithParams = (params: {pageIndex: number; pageSize: number}): any =>
  actions!.fetch(params)

export const aFetchOrdersWithUrlParams =
  (): AsyncAction =>
  (dispatch, getState): any => {
    const params = keyWindowFromUrl(getState())
    dispatch(aFetchOrdersWithParams(params))
  }
