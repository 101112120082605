import {aGenericError} from '@modifi/redux'
import {path} from 'ramda'
import {TransferMethod} from '@modifi/constants/java'

import {getAuthenticatedAresClient} from '../../../lib'
import {aUpdateOrder} from './data.store'

export const ACTION_ADD_SELLER_BANK_ACCOUNT_CLEANUP = 'order/ADD_SELLER_BANK_ACCOUNT_CLEANUP'
export const ACTION_ADD_SELLER_BANK_ACCOUNT_PENDING = 'order/ADD_SELLER_BANK_ACCOUNT_PENDING'
export const ACTION_ADD_SELLER_BANK_ACCOUNT_SUCCESS = 'order/ADD_SELLER_BANK_ACCOUNT_SUCCESS'
export const ACTION_ADD_SELLER_BANK_ACCOUNT_ERROR = 'order/ADD_SELLER_BANK_ACCOUNT_ERROR'

const initialState = {
  editPending: false,
  editError: null,
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_ADD_SELLER_BANK_ACCOUNT_CLEANUP:
      return {
        ...state,
        editPending: false,
        editError: null,
      }
    case ACTION_ADD_SELLER_BANK_ACCOUNT_PENDING:
      return {
        ...state,
        editPending: true,
        editError: null,
      }
    case ACTION_ADD_SELLER_BANK_ACCOUNT_ERROR: {
      const {message} = payload
      return {
        ...state,
        editPending: false,
        editError: message,
      }
    }
    case ACTION_ADD_SELLER_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        editPending: false,
        editError: null,
      }
    default:
      return state
  }
}

export const aEditSellerBankAccountCleanup = () => ({
  type: ACTION_ADD_SELLER_BANK_ACCOUNT_CLEANUP,
  payload: {},
})

export const aEditSellerBankAccount =
  (
    externalOrderId,
    {
      externalId,
      transferMethod,
      accountHolder,
      accountNumber,
      bankCode,
      beneficiaryStreet,
      beneficiaryCity,
      beneficiaryZipCode,
      beneficiaryCountryCode,
    }
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: ACTION_ADD_SELLER_BANK_ACCOUNT_PENDING,
      payload: {externalOrderId},
    })

    let order = {}
    try {
      const client = getAuthenticatedAresClient(getState())
      let body = {externalId}
      if (!externalId) {
        body = {
          transferMethod,
          accountHolder,
          accountNumber,
          bankCode,
        }
        if (transferMethod === TransferMethod.SWIFT) {
          body = {
            ...body,
            beneficiaryStreet,
            beneficiaryCity,
            beneficiaryZipCode,
            beneficiaryCountryCode,
          }
        }
      }

      order = await client.Orders.addSellerBankAccount({
        externalOrderId,
        body,
      }).then(res => res.data())
    } catch (error) {
      dispatch(aGenericError(ACTION_ADD_SELLER_BANK_ACCOUNT_ERROR, error))
      throw error
    }

    dispatch({
      type: ACTION_ADD_SELLER_BANK_ACCOUNT_SUCCESS,
      payload: order,
    })

    // workaround for incomplete order response
    // const currentOrder = selectOrder(getState())

    dispatch(aUpdateOrder(order))
  }

export const selectIsEditSellerBankAccountPending = state =>
  path(['order', 'sellerBankAccount', 'editPending'], state)
export const selectEditSellerBankAccountError = state =>
  path(['order', 'sellerBankAccount', 'editError'], state)
