import {connect} from '@modifi/redux'
import {compose} from 'ramda'
import {aReplace} from '@modifi/router'
import {withTranslation} from '@modifi/plugin-i18n'

import MobileFilterScreen from './MobileFilters'
import {
  selectMaxAmount,
  selectNormalizeSearchObj,
  selectTradePartnerOrderStatusOptions,
} from '../store'

const mapStateToProps = (state, ownProps) => ({
  location: state?.router?.location,
  search: selectNormalizeSearchObj(state),
  maxAmount: selectMaxAmount(state),
  tradePartnerOrderStatusOptions: selectTradePartnerOrderStatusOptions(state, ownProps?.t),
})

const mapDispatchToProps = {
  replaceUrl: aReplace,
}

export default compose(
  withTranslation('matrix'),
  connect(mapStateToProps, mapDispatchToProps)
)(MobileFilterScreen)
