import {AnyAction} from '@modifi/redux'

import {ACTION_CLEAR_STATUS, ACTION_START_POLLING, ACTION_UPDATE_STATUS} from './constants'

const initialState = {
  autoApprovalStatus: null,
}

export default (state = initialState, {type, payload}: AnyAction): Record<string, any> => {
  switch (type) {
    case ACTION_START_POLLING:
      return {
        ...state,
        autoApprovalStatus: 'PROCESSING',
      }
    case ACTION_UPDATE_STATUS:
      return {
        ...state,
        autoApprovalStatus: payload || 'PROCESSING',
      }
    case ACTION_CLEAR_STATUS:
      return initialState
    default:
      return state
  }
}

export const selectAutoApprovalStatus = (state: any): string =>
  state?.data?.autoTradeApproval?.autoApprovalStatus
