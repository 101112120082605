import {connect} from '@modifi/redux'
import {compose} from 'ramda'
import {withTranslation} from '@modifi/plugin-i18n'

import Layout from './Layout'
import {selectUserIsLoading} from '../modules/user'

const mapStateToProps = state => ({
  loading: selectUserIsLoading(state),
})
const mapDispatchToProps = {}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['matrix', 'shared'])
)(Layout)
