import React from 'react'
import * as PropTypes from 'prop-types'

import {KEY_MATRIX, Trans, useTranslation} from '@modifi/plugin-i18n'

const TranslatedString = ({transString, values}) => {
  const {t} = useTranslation(KEY_MATRIX)
  return <Trans i18nKey={transString} t={t} values={values} />
}

TranslatedString.propTypes = {
  transString: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object,
}

TranslatedString.defaultProps = {
  values: null,
}

export default TranslatedString
