/* istanbul ignore file */
import {ProductType} from '@modifi/enums'
import {createSelector} from 'reselect'
import {
  selectHasSFProductType,
  selectHasBFProductType,
  selectHasFinancingOptions,
  selectAvailableProductCurrencies,
} from '../user/selectors'
import {selectHasTradePartnerStatisticsWithAllocatedLimits} from '../../data/trade-partner-statistics'

export const selectHasTradePartnersWithLimits = createSelector(
  selectHasSFProductType,
  state => {
    const referenceCurrencies = selectAvailableProductCurrencies(
      state,
      ProductType.SELLER_FINANCING
    )
    return referenceCurrencies.some(referenceCurrency =>
      selectHasTradePartnerStatisticsWithAllocatedLimits(state, {
        referenceCurrency,
        productType: ProductType.SELLER_FINANCING,
      })
    )
  },
  (hasSFProductType, hasAllocatedLimits) => {
    if (!hasSFProductType) return true
    return hasAllocatedLimits
  }
)

export const selectIsOrderCreationDisabled = createSelector(
  selectHasBFProductType,
  selectHasTradePartnersWithLimits,
  selectHasFinancingOptions,
  (hasBFProductType, hasTradePartnersWithLimits, hasFinancingOptions) =>
    (!hasBFProductType && !hasTradePartnersWithLimits) || !hasFinancingOptions
)
