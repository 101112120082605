import {handleUnauthorizedMiddleware, selectAccessToken} from '@modifi/plugin-authentication'
import {clientConfig} from '@modifi/client-config'

import {rawClientFactory, ApolloApiClient} from './factory'

export default (): ApolloApiClient =>
  rawClientFactory({
    getAccessToken: () => selectAccessToken() as string,
    host: clientConfig.get('SERVICE_DOCUMENTS') as string,
    additionalMiddleware: [handleUnauthorizedMiddleware],
  })
