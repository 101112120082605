import {DialogManager} from '@modifi/plugin-dialogs'
import {CustomerDialogLayout} from '@modifi/ui'

import RepaymentInformation, {
  DIALOG_NAME as DIALOG_REPAYMENT_INFORMATION,
} from './repayment-information'

import TradeConfirmation, {DIALOG_NAME as DIALOG_TRADE_CONFIRMATION} from './trade-confirmation'

import ShipmentTracking, {
  DIALOG_NAME as DIALOG_SHIPMENT_TRACKING,
} from './shipment-tracking-information'

import BuyerConfirmationDialog, {
  DIALOG_NAME as DIALOG_BUYER_CONFIRMATION,
} from './buyer-confirmation'

DialogManager.setDefaultLayout(CustomerDialogLayout)
DialogManager.addDialog(DIALOG_REPAYMENT_INFORMATION, RepaymentInformation)
DialogManager.addDialog(DIALOG_TRADE_CONFIRMATION, TradeConfirmation)
DialogManager.addDialog(DIALOG_SHIPMENT_TRACKING, ShipmentTracking)
DialogManager.addDialog(DIALOG_BUYER_CONFIRMATION, BuyerConfirmationDialog)
