import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {landGreen} from '@modifi/colors'
import {SkeletonLine} from '../../../../../ui/skeleton-loaders'
import {ArrivalPort, DeparturePort, Dock, Path, ShipLineWrapper, ProgressIndicator} from '../styles'
import {ShipIcon} from '../../../assets/graphics'

export const ShipLine = ({isLoading, lineWidth, progress, completed}) => {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    requestAnimationFrame(() => {
      setWidth(progress)
    })
  }, [progress])

  return (
    <ShipLineWrapper>
      {isLoading ? (
        <SkeletonLine stringLength={lineWidth / 8} />
      ) : (
        <>
          <DeparturePort />
          <Dock started={width !== 0} /> {/* starting place of the ship */}
          <ProgressIndicator progress={width}>
            <ShipIcon color={landGreen} width={40} height={15} />
          </ProgressIndicator>
          <Path width={lineWidth + 40} />
          <ArrivalPort completed={completed} />
        </>
      )}
    </ShipLineWrapper>
  )
}

ShipLine.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  lineWidth: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired,
}

export default ShipLine
