import React, {ComponentType} from 'react'
import {AbilityContextProvider} from '@modifi/plugin-authorization'
import {abilityFactory} from '../constants/permissions'
import handleAbilityBasedRedirect from '../utils/handle-ability-based-redirect'

const withAbilityProvider = <P extends {}>(
  WrappedComponent: ComponentType<P>
): ComponentType<P> => {
  const ability = abilityFactory()

  ability.on('updated', ({target}) => {
    handleAbilityBasedRedirect(target)
  })
  handleAbilityBasedRedirect(ability)

  const ScopedComponent: React.FC<P> = props => (
    <AbilityContextProvider ability={ability}>
      <WrappedComponent {...props} />
    </AbilityContextProvider>
  )

  return React.memo(ScopedComponent)
}

export default withAbilityProvider
