import React, {useCallback, useContext} from 'react'
import * as PropTypes from 'prop-types'
import {BuyerConfirmationStatus} from '@modifi/enums'
import {CustomerDialogButtonRow} from '@modifi/ui'
import {FormDataContext, FormActionsContext} from '@modifi/plugin-forms'
import {useTranslation} from '@modifi/plugin-i18n'
import {InferPropTypes} from '@modifi/typescript-utils'

const propTypes = {
  externalOrderId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const defaultProps = {
  externalOrderId: null,
}

type CustomActionFooterProps = InferPropTypes<typeof propTypes, typeof defaultProps>

const CustomActionFooter: React.FC<CustomActionFooterProps> = ({
  externalOrderId,
  onSubmit,
  loading,
}) => {
  const {t} = useTranslation('matrix')
  const {getChanges} = useContext(FormDataContext)
  const {canSubmit} = useContext(FormActionsContext)

  const handleSubmit = useCallback(
    async (e: React.UIEvent, buyerConfirmationStatus: BuyerConfirmationStatus) => {
      e.preventDefault()
      try {
        await onSubmit({
          externalOrderId,
          buyerComment: getChanges()?.comment,
          buyerConfirmationStatus,
        })
      } catch (err) {
        // do nothing
      }
    },
    [externalOrderId, onSubmit, getChanges]
  )
  return (
    <CustomerDialogButtonRow
      canSubmit={canSubmit && !loading}
      onSubmitClick={e => handleSubmit(e, BuyerConfirmationStatus.CONFIRMED)}
      submitText={t('dialog.buyer-confirmation-note.cta-submit')}
      hasCancel
      canCancel={canSubmit && !loading}
      cancelText={t('dialog.buyer-confirmation-note.cta-cancel')}
      onCancelClick={e => handleSubmit(e, BuyerConfirmationStatus.DECLINED)}
      loading={loading}
    />
  )
}

CustomActionFooter.propTypes = propTypes
CustomActionFooter.defaultProps = defaultProps

export default CustomActionFooter
