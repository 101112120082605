import React, {Suspense} from 'react'
import {FullScreenLoader} from '@modifi/ui'
import {getComponentDisplayName} from '@modifi/utilities'

const withSuspense = WrappedComponent => {
  const ScopedComponent = props => (
    <Suspense fallback={<FullScreenLoader />}>
      <WrappedComponent {...props} />
    </Suspense>
  )
  ScopedComponent.displayName = `withSuspense(${getComponentDisplayName(WrappedComponent)})`
  return ScopedComponent
}

export default withSuspense
