import {CollectionFactory} from '@modifi/redux'
import {path} from 'ramda'

import {getAuthenticatedApolloClient} from '../../lib'

const {selectors, reducer, actions} = CollectionFactory({
  actionNamePrefix: 'document-meta',
  entityPrimaryKeySelector: path(['fileId']),
  reducerPath: ['documentMeta'],
  fetchRequest: async (state, params) => {
    try {
      const client = getAuthenticatedApolloClient()
      return client.Documents.metaByIds({fileIds: params?.fileIds})
        .then(res => res.data().metaData)
        .then(metaData =>
          metaData.map(({originalFileName, ...entry}) => ({
            ...entry,
            fileName: originalFileName,
          }))
        )
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
    return []
  },
})

export default reducer

export const aFetchDocumentMeta = fileIds => actions.fetch(fileIds)
export const selectDocumentMeta = selectors.selectByKeyWindow
export const selectDocumentMetaById = selectors.selectById
