import {DocumentType} from '@modifi/enums'

export const REDUCER_KEY = 'order'

export const transportDocumentMap = {
  AIR: DocumentType.AIRWAY_BILL,
  SEA: DocumentType.BILL_OF_LADING,
  ROAD_RAIL: DocumentType.CMR_CIM,
}

export const TAB_ORDER_DETAILS = 'ORDER_DETAILS'
export const TAB_DOCUMENTS = 'DOCUMENTS'

export const tabRoutesMap = {
  [TAB_ORDER_DETAILS]: '/orders/:orderId',
  [TAB_DOCUMENTS]: '/orders/:orderId/documents',
}
