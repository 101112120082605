import React from 'react'
import {getDisplayName} from '@modifi/utilities'
import Context from './Context'

const withShipmentTrackingContext = WrappedComponent => {
  const ScopedComponent = props => (
    <Context.Consumer>
      {({externalOrderId, containerId, orderReference, onFetch}) => (
        <WrappedComponent
          externalOrderId={externalOrderId}
          containerId={containerId}
          orderReference={orderReference}
          onFetch={onFetch}
          {...props}
        />
      )}
    </Context.Consumer>
  )

  ScopedComponent.WrappedComponent = WrappedComponent
  ScopedComponent.displayName = `withShipmentTrackingContext(${getDisplayName(WrappedComponent)})`

  return ScopedComponent
}

export default withShipmentTrackingContext
