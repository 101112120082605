import type {Middleware} from 'mappersmith'
import {
  accessTokenMiddleware,
  correlationIdMiddleware,
  Duration,
  EncodeJSON,
  Retry,
  Timeout,
} from '@modifi/api-clients'
import ms from 'ms'
import GeneratedClientInterface from './gen/core/ApolloClientClientInterface'
import GeneratedClient from './gen/core/ApolloClientClient'

export type ClientFactoryOpts = {
  getAccessToken: () => string
  host: string
  additionalMiddleware?: Middleware[]
}

export type ApolloApiClient = GeneratedClientInterface

export const rawClientFactory = ({
  getAccessToken,
  host,
  additionalMiddleware,
}: ClientFactoryOpts): ApolloApiClient => {
  const client = GeneratedClient({
    host,
    middleware: [
      Retry({
        retries: 2,
      }),
      Timeout(ms('30s')),
      EncodeJSON,
      accessTokenMiddleware(getAccessToken),
      correlationIdMiddleware,
      Duration,
      ...(additionalMiddleware || []),
    ],
  })

  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
    // @ts-expect-error don't care
    window.apolloClient = client
  }

  return client
}
