// @Todo to be moved to ui-icons
import React from 'react'
import * as PropTypes from 'prop-types'
import {Svg} from '@modifi/ui'

const ShipIcon = ({color, ...props}) => (
  <Svg viewBox="0 0 40 15" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill={color} stroke="#64BB00">
        <g>
          <g>
            <path
              d="M2 4.734L4.492 0 8 0 8 7 2 7zM2 12L34.79 12 37 7 8.064 7.21 0 7.254zM8 7L26 7 26 4 8 4z"
              transform="translate(-328 -368) translate(81 325) translate(248 44)"
            />
          </g>
        </g>
      </g>
    </g>
  </Svg>
)

ShipIcon.propTypes = {
  color: PropTypes.string,
}

ShipIcon.defaultProps = {
  color: 'currentColor',
}

export default ShipIcon
