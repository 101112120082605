import {aGenericError} from '@modifi/redux'
import {path} from 'ramda'
import {getAuthenticatedAresClient} from '../../../lib'

import {selectOrder, aUpdateOrder} from './data.store'

export const ACTION_EDIT_ORDER_REFERENCE_CLEANUP = 'order/EDIT_REFERENCE_CLEANUP'
export const ACTION_EDIT_ORDER_REFERENCE_PENDING = 'order/EDIT_REFERENCE_PENDING'
export const ACTION_EDIT_ORDER_REFERENCE_SUCCESS = 'order/EDIT_REFERENCE_SUCCESS'
export const ACTION_EDIT_ORDER_REFERENCE_ERROR = 'order/EDIT_REFERENCE_ERROR'

const initialState = {
  editReferencePending: false,
  editReferenceError: null,
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_EDIT_ORDER_REFERENCE_CLEANUP:
      return {
        ...state,
        editReferencePending: false,
        editReferenceError: null,
      }
    case ACTION_EDIT_ORDER_REFERENCE_PENDING:
      return {
        ...state,
        editReferencePending: true,
        editReferenceError: null,
      }
    case ACTION_EDIT_ORDER_REFERENCE_ERROR: {
      const {message} = payload
      return {
        ...state,
        editReferencePending: false,
        editReferenceError: message,
      }
    }
    case ACTION_EDIT_ORDER_REFERENCE_SUCCESS:
      return {
        ...state,
        editReferencePending: false,
        editReferenceError: null,
      }
    default:
      return state
  }
}

export const aEditOrderReferenceCleanup = () => ({
  type: ACTION_EDIT_ORDER_REFERENCE_CLEANUP,
  payload: {},
})

export const aEditOrderReference =
  (externalOrderId, newOrderReference) => async (dispatch, getState) => {
    dispatch({
      type: ACTION_EDIT_ORDER_REFERENCE_PENDING,
      payload: {externalOrderId},
    })

    let result = {}
    try {
      const client = getAuthenticatedAresClient()
      result = await client.Orders.updateReferenceCode({
        externalOrderId,
        body: {
          orderReferenceCode: newOrderReference,
        },
      }).then(res => res.data())
    } catch (error) {
      dispatch(aGenericError(ACTION_EDIT_ORDER_REFERENCE_ERROR, error))
      throw error
    }

    dispatch({
      type: ACTION_EDIT_ORDER_REFERENCE_SUCCESS,
      payload: result,
    })

    // workaround for missing order response
    const currentOrder = selectOrder(getState())

    dispatch(
      aUpdateOrder({
        ...currentOrder,
        ...result, // making sure we have correct payment reference codes
        orderReferenceCode: String(newOrderReference).toUpperCase(),
      })
    )
  }

export const selectIsEditReferencePending = state =>
  path(['order', 'editReference', 'editReferencePending'], state)
export const selectEditReferenceError = state =>
  path(['order', 'editReference', 'editReferenceError'], state)
