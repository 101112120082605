// @todo move to components package
import React from 'react'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default ({...props}) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M6.87685 18.798H16.3941V16.9655H6.87685V18.798ZM6.87685 13.7734H16.3941V11.9409H6.87685V13.7734ZM4.00985 24C3.47783 24 3.00985 23.798 2.60591 23.3941C2.20197 22.9901 2 22.5222 2 21.9901V2.00985C2 1.45813 2.20197 0.985222 2.60591 0.591133C3.00985 0.197044 3.47783 0 4.00985 0H14.7094L21.2709 6.56158V21.9901C21.2709 22.5222 21.0739 22.9901 20.6798 23.3941C20.2857 23.798 19.8128 24 19.2611 24H4.00985ZM13.6749 7.50739V2.00985H4.00985V21.9901H19.2611V7.50739H13.6749ZM4.00985 2.00985V7.50739V2.00985V21.9901V2.00985Z" />
  </Svg>
)
