import {combineReducers} from '@modifi/redux'

import orderShipmentsReducer from './order-shipments.store'
import orderContainerRouteReducer from './order-container-route.store'

export default combineReducers({
  orderShipments: orderShipmentsReducer,
  orderContainerRoute: orderContainerRouteReducer,
})

export {
  selectCurrentPageIndex,
  selectOrderShipmentsTotalCount,
  selectOrderShipmentsPageSize,
  selectOrderShipmentsTotalPages,
  aSetPaginationIndex,
  selectOrderShipmentsIsLoading,
  selectOrderShipments,
  selectFormattedOrderShipments,
  aFetchOrderShipments,
} from './order-shipments.store'

export {
  selectContainerRouteData,
  selectContainerRouteContainsOrder,
  selectContainerId,
  selectContainerRouteEvents,
  selectContainerRouteIsLoading,
  selectContainerRouteHasLoaded,
  selectIsContainerRouteEmpty,
  selectIsContainerRouteDataLoading,
  selectFormattedContainerRouteEvents,
  selectIsContainerRouteError,
  aFetchContainerRoute,
  aEnsureContainerRoute,
} from './order-container-route.store'
