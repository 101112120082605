import React from 'react'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default ({color = 'currentColor', ...props}) => (
  <Svg viewBox="0 0 72 72" color={color} {...props}>
    <g clipPath="url(#clip0_901_3518)">
      <path
        d="M67.5232 67.5978C65.2551 67.5978 64.0723 66.9275 63.0258 66.3355C62.1019 65.8132 61.3059 65.3636 59.6222 65.3636C57.9385 65.3636 57.1425 65.816 56.2186 66.3355C55.1721 66.9275 53.9893 67.5978 51.7212 67.5978C49.453 67.5978 48.2674 66.9275 47.2238 66.3383C46.2998 65.816 45.5039 65.3636 43.8202 65.3636C42.1364 65.3636 41.3377 65.816 40.4165 66.3383C39.3701 66.9303 38.1873 67.5978 35.9191 67.5978C33.651 67.5978 32.4654 66.9275 31.4217 66.3383C30.4978 65.816 29.699 65.3636 28.0153 65.3636C26.3316 65.3636 25.5329 65.816 24.6089 66.3383C23.5625 66.9303 22.3797 67.5978 20.1115 67.5978C17.8433 67.5978 16.6578 66.9275 15.6141 66.3383C14.6902 65.816 13.8914 65.3636 12.2077 65.3636C10.524 65.3636 9.72523 65.816 8.80127 66.3383C7.75485 66.9303 6.57206 67.5978 4.30389 67.5978C3.68884 67.5978 3.19067 67.0979 3.19067 66.4807C3.19067 65.8635 3.68884 65.3636 4.30389 65.3636C5.98762 65.3636 6.78635 64.9112 7.71032 64.3889C8.75674 63.7969 9.93952 63.1294 12.2077 63.1294C14.4759 63.1294 15.6614 63.7997 16.7051 64.3889C17.629 64.9112 18.4278 65.3636 20.1115 65.3636C21.7952 65.3636 22.594 64.9112 23.5179 64.3889C24.5644 63.7969 25.7471 63.1294 28.0153 63.1294C30.2835 63.1294 31.4691 63.7997 32.5127 64.3889C33.4367 64.9112 34.2354 65.3636 35.9191 65.3636C37.6029 65.3636 38.4016 64.9112 39.3228 64.3889C40.3692 63.7969 41.552 63.1294 43.8202 63.1294C46.0883 63.1294 47.2739 63.7997 48.3175 64.3889C49.2415 64.9112 50.0374 65.3636 51.7212 65.3636C53.4049 65.3636 54.2009 64.9112 55.1248 64.3917C56.1712 63.7997 57.354 63.1294 59.6222 63.1294C61.8904 63.1294 63.0732 63.7997 64.1196 64.3917C65.0435 64.914 65.8395 65.3636 67.5232 65.3636C68.1383 65.3636 68.6364 65.8635 68.6364 66.4807C68.6364 67.0979 68.1383 67.5978 67.5232 67.5978Z"
        fill="#19539F"
      />
      <path
        d="M35.2761 67.5364C34.661 67.5364 34.1628 67.0365 34.1628 66.4193V33.7441C34.1628 33.1269 34.661 32.627 35.2761 32.627C35.8911 32.627 36.3893 33.1269 36.3893 33.7441V66.4193C36.3893 67.0365 35.8911 67.5364 35.2761 67.5364Z"
        fill="#1654A0"
      />
      <path
        d="M63.8495 48.557C63.7465 48.557 63.6408 48.543 63.5378 48.5123L35.5461 40.2988C34.9561 40.1257 34.6165 39.5057 34.7891 38.9136C34.9616 38.3216 35.5795 37.9808 36.1695 38.154L64.1612 46.3675C64.7512 46.5406 65.0907 47.1606 64.9182 47.7527C64.7762 48.2414 64.331 48.557 63.8495 48.557Z"
        fill="#1654A0"
      />
      <path
        d="M7.00618 48.6461C6.52471 48.6461 6.07943 48.3305 5.93749 47.8418C5.76495 47.2497 6.10169 46.6298 6.69448 46.4566L34.9534 38.1537C35.5434 37.9806 36.1612 38.3185 36.3338 38.9106C36.5063 39.5026 36.1696 40.1226 35.5768 40.2958L7.31788 48.5986C7.21212 48.6294 7.10915 48.6433 7.0034 48.6433L7.00618 48.6461Z"
        fill="#1654A0"
      />
      <path
        d="M35.3542 33.4704C34.7391 33.4704 34.241 32.9705 34.241 32.3533V29.2143C34.241 28.5971 34.7391 28.0972 35.3542 28.0972C35.9692 28.0972 36.4674 28.5971 36.4674 29.2143V32.3533C36.4674 32.9705 35.9692 33.4704 35.3542 33.4704Z"
        fill="#7DB942"
      />
      <path d="M23.9547 29.6777H21.7283V36.0145H23.9547V29.6777Z" fill="#7DB942" />
      <path d="M49.9288 29.6777H47.7024V36.4837H49.9288V29.6777Z" fill="#7DB942" />
      <path
        d="M42.4118 30.3705C41.7967 30.3705 41.2986 29.8706 41.2986 29.2534V16.5268C41.2986 15.9096 41.7967 15.4097 42.4118 15.4097C43.0268 15.4097 43.525 15.9096 43.525 16.5268V29.2534C43.525 29.8706 43.0268 30.3705 42.4118 30.3705Z"
        fill="#7DB942"
      />
      <path d="M29.9132 16.1079H27.6868V28.8736H29.9132V16.1079Z" fill="#7DB942" />
      <path
        d="M35.3542 17.1355C34.7391 17.1355 34.241 16.6356 34.241 16.0184V5.74943C34.241 5.13223 34.7391 4.63232 35.3542 4.63232C35.9692 4.63232 36.4674 5.13223 36.4674 5.74943V16.0184C36.4674 16.6356 35.9692 17.1355 35.3542 17.1355Z"
        fill="#7DB942"
      />
      <path
        d="M48.1588 17.2501H23.1922C21.8118 17.2501 20.6875 16.1219 20.6875 14.7367V6.55108C20.6875 5.16587 21.8118 4.0376 23.1922 4.0376H48.1588C49.5392 4.0376 50.6635 5.16587 50.6635 6.55108V14.7367C50.6635 16.1219 49.5392 17.2501 48.1588 17.2501ZM23.195 6.2718C23.0419 6.2718 22.9167 6.39748 22.9167 6.55108V14.7367C22.9167 14.8903 23.0419 15.0159 23.195 15.0159H48.1616C48.3146 15.0159 48.4399 14.8903 48.4399 14.7367V6.55108C48.4399 6.39748 48.3146 6.2718 48.1616 6.2718H23.195Z"
        fill="#7DB942"
      />
      <path
        d="M54.8911 30.376H16.463C15.0826 30.376 13.9583 29.2477 13.9583 27.8625V17.5628C13.9583 16.1776 15.0826 15.0493 16.463 15.0493H54.8938C56.2742 15.0493 57.3986 16.1776 57.3986 17.5628V27.8625C57.3986 29.2477 56.2742 30.376 54.8938 30.376H54.8911ZM16.463 17.2863C16.3099 17.2863 16.1847 17.412 16.1847 17.5656V27.8653C16.1847 28.0189 16.3099 28.1446 16.463 28.1446H54.8938C55.0469 28.1446 55.1721 28.0189 55.1721 27.8653V17.5656C55.1721 17.412 55.0469 17.2863 54.8938 17.2863H16.463Z"
        fill="#7DB942"
      />
      <path
        d="M62.0071 41.6451C61.8986 41.6451 61.7872 41.6283 61.6815 41.5976L35.354 33.5209L9.67222 41.3686C9.33548 41.4719 8.96812 41.4077 8.68425 41.1982C8.40038 40.9888 8.2334 40.6536 8.2334 40.3018V30.4294C8.2334 29.1698 9.25477 28.1421 10.5127 28.1421H60.8438C62.0989 28.1421 63.1231 29.167 63.1231 30.4294V40.528C63.1231 40.8826 62.9561 41.215 62.6722 41.4272C62.4774 41.5725 62.2464 41.6451 62.0099 41.6451H62.0071ZM35.354 31.2365C35.4626 31.2365 35.5739 31.2532 35.6796 31.2839L60.8939 39.0171V30.4266C60.8939 30.3986 60.8716 30.3735 60.841 30.3735H10.5127C10.4821 30.3735 10.4598 30.3958 10.4598 30.4266V38.7909L35.0284 31.2812C35.1342 31.2476 35.2427 31.2337 35.354 31.2337V31.2365Z"
        fill="#7DB942"
      />
      <path
        d="M54.4485 66.4363C54.2703 66.4363 54.0922 66.3944 53.9252 66.305C53.3826 66.0146 53.1794 65.3359 53.4688 64.7941L65.1687 42.8374L35.3541 33.5264L5.42258 42.8374L17.1224 64.7941C17.4119 65.3387 17.2087 66.0146 16.666 66.305C16.1233 66.5954 15.4498 66.3916 15.1604 65.847L2.82323 42.6978C2.66738 42.4046 2.6479 42.0555 2.77592 41.7455C2.90115 41.4383 3.15997 41.2009 3.47724 41.1031L35.0257 31.2866C35.24 31.2196 35.471 31.2196 35.6853 31.2866L67.1168 41.1031C67.4341 41.2009 67.6901 41.4383 67.8154 41.7455C67.9406 42.0527 67.9239 42.4018 67.768 42.695L55.4337 65.8442C55.2333 66.2212 54.8492 66.4363 54.4512 66.4363H54.4485Z"
        fill="#1654A0"
      />
    </g>
    <g clipPath="url(#clip1_901_3518)">
      <circle cx="13.4579" cy="58.5419" r="13.4579" fill="#51565C" />
      <g clipPath="url(#clip2_901_3518)">
        <path
          d="M16.4094 64.302L16.4269 62.6005L10.6558 62.618L19.7949 53.4789L18.602 52.2861L9.46297 61.4252L9.48052 55.619L7.779 55.6365L7.779 64.302L16.4094 64.302Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_901_3518">
        <rect width="65.9447" height="63.5604" fill="white" transform="translate(2.69165 4.0376)" />
      </clipPath>
      <clipPath id="clip1_901_3518">
        <rect width="26.9159" height="26.9159" fill="white" transform="translate(0 45.084)" />
      </clipPath>
      <clipPath id="clip2_901_3518">
        <rect
          width="16.1495"
          height="16.1495"
          fill="white"
          transform="matrix(0.707107 0.707107 0.707107 -0.707107 2.0188 58.542)"
        />
      </clipPath>
    </defs>
  </Svg>
)
