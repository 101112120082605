import {combineReducers} from '@modifi/redux'
import dataReducer from './data.store'

export default combineReducers({
  data: dataReducer,
})

export {
  aFetchOrder,
  aClearOrder,
  selectOrder,
  selectOrderIsLoading,
  selectOrderIsError,
  selectOrderErrorCode,
  selectExternalOrderId,
  selectDocumentsToConfirm,
  selectRepaymentBankAccount,
  selectPaymentReference,
  selectFormattedDocuments,
  selectReferencedDocuments,
} from './data.store'

export {aFetchFile} from './documents/download-document.actions'
