import {EntityFactory, Reducer, ThunkAction} from '@modifi/redux'
import {createSelector} from 'reselect'
import {getAuthenticatedAresClient} from '../../lib'

const apiClient = getAuthenticatedAresClient()

const {actions, reducer, selectors} = EntityFactory({
  actionNamePrefix: 'data/buyer-confirmation/entity',
  rootSelector: state => state?.data?.buyerConfirmation?.data,
  fetchRequest: (state, params: {externalOrderId: string}) =>
    apiClient.TradePartnerOrders.fetchBuyerConfirmation({
      externalOrderId: params?.externalOrderId,
    })
      .then(res => res.data())
      .then(data => ({
        ...data,
        externalOrderId: params?.externalOrderId,
      })),
})

export default reducer as Reducer

export const {
  selectIsLoading: selectBuyerConfirmationDetailsAreLoading,
  selectIsError: selectBuyerConfirmationDetailsHasError,
  selectErrorMessage: selectBuyerConfirmationDetailsErrorMessage,
} = selectors

export const selectBuyerConfirmationDetails = (state: Record<string, any>): Record<string, any> =>
  (selectors.selectEntity(state) || {}) as Record<string, any>

export const selectModifiComment = createSelector(
  selectBuyerConfirmationDetails,
  data => data?.modifiComment
)

export const selectBuyerCommentRequired = createSelector(
  selectBuyerConfirmationDetails,
  data => data?.buyerCommentRequired
)

export const selectBuyerConfirmationDetailsWasLoaded = (state: Record<string, any>): boolean =>
  !!selectBuyerConfirmationDetails(state)?.orderReferenceCode

export const aFetchBuyerConfirmationDetails = (externalOrderId: string): any =>
  actions?.fetch({externalOrderId})

export const aCleanUpBuyerConfirmationDetails = actions!.clear

export const aEnsureBuyerConfirmationDetails =
  (externalOrderId: string): ThunkAction<any, any, any, any> =>
  (dispatch, getState): void => {
    if (selectBuyerConfirmationDetails(getState())?.externalOrderId === externalOrderId) {
      return
    }
    dispatch(aFetchBuyerConfirmationDetails(externalOrderId))
  }
