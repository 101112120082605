import React from 'react'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default ({color = 'currentColor', ...props}) => (
  <Svg viewBox="0 0 72 72" color={color} {...props}>
    <path
      d="M67.5223 67.5983C65.2541 67.5983 64.0713 66.928 63.0249 66.336C62.1009 65.8137 61.305 65.3641 59.6212 65.3641C57.9375 65.3641 57.1416 65.8165 56.2176 66.336C55.1712 66.928 53.9884 67.5983 51.7202 67.5983C49.452 67.5983 48.2665 66.928 47.2228 66.3388C46.2989 65.8165 45.5029 65.3641 43.8192 65.3641C42.1354 65.3641 41.3367 65.8165 40.4155 66.3388C39.3691 66.9308 38.1863 67.5983 35.9182 67.5983C33.65 67.5983 32.4644 66.928 31.4208 66.3388C30.4968 65.8165 29.6981 65.3641 28.0143 65.3641C26.3306 65.3641 25.5319 65.8165 24.6079 66.3388C23.5615 66.9308 22.3787 67.5983 20.1105 67.5983C17.8424 67.5983 16.6568 66.928 15.6132 66.3388C14.6892 65.8165 13.8905 65.3641 12.2067 65.3641C10.523 65.3641 9.72426 65.8165 8.80029 66.3388C7.75387 66.9308 6.57108 67.5983 4.30291 67.5983C3.68786 67.5983 3.1897 67.0984 3.1897 66.4812C3.1897 65.864 3.68786 65.3641 4.30291 65.3641C5.98664 65.3641 6.78537 64.9117 7.70934 64.3894C8.75576 63.7974 9.93855 63.1299 12.2067 63.1299C14.4749 63.1299 15.6605 63.8001 16.7041 64.3894C17.6281 64.9117 18.4268 65.3641 20.1105 65.3641C21.7943 65.3641 22.593 64.9117 23.517 64.3894C24.5634 63.7974 25.7462 63.1299 28.0143 63.1299C30.2825 63.1299 31.4681 63.8001 32.5117 64.3894C33.4357 64.9117 34.2344 65.3641 35.9182 65.3641C37.6019 65.3641 38.4006 64.9117 39.3218 64.3894C40.3682 63.7974 41.551 63.1299 43.8192 63.1299C46.0873 63.1299 47.2729 63.8001 48.3166 64.3894C49.2405 64.9117 50.0365 65.3641 51.7202 65.3641C53.4039 65.3641 54.1999 64.9117 55.1239 64.3922C56.1703 63.8001 57.3531 63.1299 59.6212 63.1299C61.8894 63.1299 63.0722 63.8001 64.1186 64.3922C65.0426 64.9145 65.8385 65.3641 67.5223 65.3641C68.1373 65.3641 68.6355 65.864 68.6355 66.4812C68.6355 67.0984 68.1373 67.5983 67.5223 67.5983Z"
      fill="#19539F"
    />
    <g clipPath="url(#clip0_901_3535)">
      <g clipPath="url(#clip1_901_3535)">
        <path
          d="M48.1537 17.2531H23.1871C21.8067 17.2531 20.6824 16.1248 20.6824 14.7396V6.55401C20.6824 5.1688 21.8067 4.04053 23.1871 4.04053H48.1537C49.534 4.04053 50.6584 5.1688 50.6584 6.55401V14.7396C50.6584 16.1248 49.534 17.2531 48.1537 17.2531ZM23.1899 6.27473C23.0368 6.27473 22.9116 6.40041 22.9116 6.55401V14.7396C22.9116 14.8932 23.0368 15.0189 23.1899 15.0189H48.1564C48.3095 15.0189 48.4348 14.8932 48.4348 14.7396V6.55401C48.4348 6.40041 48.3095 6.27473 48.1564 6.27473H23.1899Z"
          fill="#7DB942"
        />
        <path
          d="M54.8859 30.3789H16.4579C15.0775 30.3789 13.9531 29.2506 13.9531 27.8654V17.5657C13.9531 16.1805 15.0775 15.0522 16.4579 15.0522H54.8887C56.2691 15.0522 57.3935 16.1805 57.3935 17.5657V27.8654C57.3935 29.2506 56.2691 30.3789 54.8887 30.3789H54.8859ZM16.4579 17.2892C16.3048 17.2892 16.1795 17.4149 16.1795 17.5685V27.8682C16.1795 28.0218 16.3048 28.1475 16.4579 28.1475H54.8887C55.0418 28.1475 55.167 28.0218 55.167 27.8682V17.5685C55.167 17.4149 55.0418 17.2892 54.8887 17.2892H16.4579Z"
          fill="#7DB942"
        />
        <path
          d="M62.002 41.648C61.8934 41.648 61.7821 41.6312 61.6764 41.6005L35.3489 33.5239L9.6671 41.3715C9.33035 41.4748 8.96299 41.4106 8.67912 41.2012C8.39525 40.9917 8.22827 40.6566 8.22827 40.3047V30.4323C8.22827 29.1728 9.24964 28.145 10.5076 28.145H60.8387C62.0938 28.145 63.118 29.17 63.118 30.4323V40.5309C63.118 40.8856 62.951 41.2179 62.6671 41.4302C62.4723 41.5754 62.2413 41.648 62.0048 41.648H62.002ZM35.3489 31.2394C35.4574 31.2394 35.5688 31.2562 35.6745 31.2869L60.8888 39.02V30.4295C60.8888 30.4016 60.8665 30.3764 60.8359 30.3764H10.5076C10.477 30.3764 10.4547 30.3988 10.4547 30.4295V38.7938L35.0233 31.2841C35.129 31.2506 35.2376 31.2366 35.3489 31.2366V31.2394Z"
          fill="#7DB942"
        />
        <rect
          x="16.1453"
          y="16.8257"
          width="39.7009"
          height="12.1122"
          fill="#7BB835"
          fillOpacity="0.25"
        />
        <rect
          x="22.8743"
          y="6.05908"
          width="26.243"
          height="9.42056"
          fill="#7BB835"
          fillOpacity="0.25"
        />
        <path
          d="M10.0891 30.2837H61.9022L61.5658 39.3678L35.3228 31.293L10.0891 39.0314V30.2837Z"
          fill="#7BB835"
          fillOpacity="0.25"
        />
        <path
          d="M35.3491 33.4734C34.734 33.4734 34.2358 32.9735 34.2358 32.3563V29.2172C34.2358 28.6 34.734 28.1001 35.3491 28.1001C35.9641 28.1001 36.4623 28.6 36.4623 29.2172V32.3563C36.4623 32.9735 35.9641 33.4734 35.3491 33.4734Z"
          fill="#7DB942"
        />
        <path d="M23.9496 29.6807H21.7231V36.0174H23.9496V29.6807Z" fill="#7DB942" />
        <path d="M49.9237 29.6807H47.6973V36.4866H49.9237V29.6807Z" fill="#7DB942" />
        <path
          d="M42.4067 30.3734C41.7916 30.3734 41.2935 29.8735 41.2935 29.2563V16.5297C41.2935 15.9125 41.7916 15.4126 42.4067 15.4126C43.0217 15.4126 43.5199 15.9125 43.5199 16.5297V29.2563C43.5199 29.8735 43.0217 30.3734 42.4067 30.3734Z"
          fill="#7DB942"
        />
        <path d="M29.9081 16.1108H27.6816V28.8765H29.9081V16.1108Z" fill="#7DB942" />
        <path
          d="M35.3491 17.1384C34.734 17.1384 34.2358 16.6385 34.2358 16.0213V5.75236C34.2358 5.13516 34.734 4.63525 35.3491 4.63525C35.9641 4.63525 36.4623 5.13516 36.4623 5.75236V16.0213C36.4623 16.6385 35.9641 17.1384 35.3491 17.1384Z"
          fill="#7DB942"
        />
      </g>
      <g clipPath="url(#clip2_901_3535)">
        <path
          d="M35.2709 67.5394C34.6559 67.5394 34.1577 67.0395 34.1577 66.4223V33.747C34.1577 33.1298 34.6559 32.6299 35.2709 32.6299C35.886 32.6299 36.3841 33.1298 36.3841 33.747V66.4223C36.3841 67.0395 35.886 67.5394 35.2709 67.5394Z"
          fill="#1654A0"
        />
        <path
          d="M63.8444 48.5599C63.7414 48.5599 63.6356 48.546 63.5327 48.5153L35.5409 40.3018C34.9509 40.1286 34.6114 39.5086 34.784 38.9165C34.9565 38.3245 35.5743 37.9838 36.1643 38.1569L64.1561 46.3704C64.7461 46.5436 65.0856 47.1636 64.913 47.7556C64.7711 48.2444 64.3258 48.5599 63.8444 48.5599Z"
          fill="#1654A0"
        />
        <path
          d="M7.00105 48.6491C6.51959 48.6491 6.0743 48.3335 5.93237 47.8447C5.75982 47.2527 6.09657 46.6327 6.68935 46.4595L34.9482 38.1567C35.5383 37.9835 36.1561 38.3214 36.3286 38.9135C36.5012 39.5056 36.1644 40.1256 35.5717 40.2987L7.31275 48.6016C7.207 48.6323 7.10402 48.6463 6.99827 48.6463L7.00105 48.6491Z"
          fill="#1654A0"
        />
        <path
          d="M54.4433 66.4392C54.2652 66.4392 54.0871 66.3973 53.9201 66.3079C53.3774 66.0175 53.1743 65.3388 53.4637 64.797L65.1636 42.8404L35.349 33.5293L5.41745 42.8404L17.1173 64.797C17.4068 65.3416 17.2036 66.0175 16.6609 66.3079C16.1182 66.5984 15.4447 66.3945 15.1553 65.8499L2.8181 42.7007C2.66225 42.4075 2.64277 42.0584 2.77079 41.7484C2.89603 41.4412 3.15485 41.2038 3.47211 41.1061L35.0206 31.2895C35.2349 31.2225 35.4658 31.2225 35.6801 31.2895L67.1117 41.1061C67.429 41.2038 67.685 41.4412 67.8102 41.7484C67.9355 42.0556 67.9188 42.4047 67.7629 42.6979L55.4285 65.8471C55.2281 66.2241 54.8441 66.4392 54.4461 66.4392H54.4433Z"
          fill="#1654A0"
        />
      </g>
    </g>
    <g clipPath="url(#clip3_901_3535)">
      <circle cx="58.5422" cy="58.5419" r="13.4579" fill="#51565C" />
      <g clipPath="url(#clip4_901_3535)">
        <path
          d="M55.5513 52.782L55.5338 54.4835L61.3049 54.466L52.1658 63.605L53.3587 64.7979L62.4977 55.6588L62.4802 61.465L64.1817 61.4475L64.1817 52.782L55.5513 52.782Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_901_3535">
        <rect
          width="65.2068"
          height="63.4988"
          fill="white"
          transform="translate(2.68652 4.04053)"
        />
      </clipPath>
      <clipPath id="clip1_901_3535">
        <rect
          width="54.8897"
          height="37.6075"
          fill="white"
          transform="translate(8.22827 4.04053)"
        />
      </clipPath>
      <clipPath id="clip2_901_3535">
        <rect
          width="65.2068"
          height="36.3001"
          fill="white"
          transform="translate(2.68652 31.2393)"
        />
      </clipPath>
      <clipPath id="clip3_901_3535">
        <rect width="26.9159" height="26.9159" fill="white" transform="translate(45.0842 45.084)" />
      </clipPath>
      <clipPath id="clip4_901_3535">
        <rect
          width="16.1495"
          height="16.1495"
          fill="white"
          transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 69.9419 58.542)"
        />
      </clipPath>
    </defs>
  </Svg>
)
