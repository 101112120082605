export default [
  {
    code: 'SEA',
    langEN: 'Sea',
  },
  {
    code: 'AIR',
    langEN: 'Air',
  },
  {
    code: 'ROAD_RAIL',
    langEN: 'Road/Rail',
  },
]
