import styled from 'styled-components'
import {lightGray} from '@modifi/constants/colors'

const Hr = styled.hr`
  border-top: none;
  border-color: ${lightGray};
  margin: 0;
`

export default Hr
