import React from 'react'
import PropTypes from 'prop-types'
import {H5, ButtonSecondary, Row, Col, Alert} from '@modifi/ui'
import {Trans} from '@modifi/plugin-i18n'
import {
  ShipmentTrackingManager,
  ProgressBar,
  EventTimeline,
  ContainsOrderHint,
} from '../../custom-plugins/shipment-tracking'

import {Container, TextCenter, FooterSeparator} from './styles'

const ShipmentTrackingInformation = ({error, fetchContainerRoute, handleClose, params, t}) => (
  <Container>
    <TextCenter>
      <H5 mt={error ? 0 : 30} mb={40}>
        <Trans
          i18nKey="shipment-tracking.dialog.title"
          t={t}
          values={{
            orderReferenceCode: params?.orderReference,
            containerId: params?.containerId,
          }}
        />
      </H5>
    </TextCenter>

    {error ? (
      <Alert type="danger" mt={30}>
        {t('shipment-tracking.dialog.error.message')}
      </Alert>
    ) : (
      <ShipmentTrackingManager onFetch={fetchContainerRoute} {...params}>
        <Row>
          <Col col={12}>
            <ContainsOrderHint />
          </Col>
          <Col col={12} mt={20}>
            <ProgressBar />
          </Col>
          <Col col={12} mt={60}>
            <EventTimeline />
          </Col>
        </Row>
      </ShipmentTrackingManager>
    )}

    <FooterSeparator />

    <Row justifyContent="end" mt={30}>
      <Col auto>
        <ButtonSecondary onClick={handleClose}>{t('shipment-tracking.dialog.cta')}</ButtonSecondary>
      </Col>
    </Row>
  </Container>
)

ShipmentTrackingInformation.propTypes = {
  params: PropTypes.shape({
    orderExternalId: PropTypes.string,
    containerId: PropTypes.string,
    orderReference: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  fetchContainerRoute: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default ShipmentTrackingInformation
