import {aPush, selectPathname} from '@modifi/router'
import {aFetchUser, selectUserHasBFOnly} from '../modules/user'

const matrixMiddleware = store => next => action => {
  const res = next(action)

  if (typeof action !== 'function' && typeof action.type === 'string') {
    switch (action.type) {
      case 'plugins/authentication/AUTHENTICATED':
        store.dispatch(aFetchUser())
        break
      case 'plugins/user/FETCH_SUCCESS': {
        // BF customers should be redirected to orders
        const userIsBF = selectUserHasBFOnly(store.getState())
        const path = selectPathname(store.getState())
        if (userIsBF && path.startsWith('/dashboard')) {
          store.dispatch(aPush('/orders'))
        }
        break
      }
      default:
      // do nothing
    }
  }
  return res
}

export default matrixMiddleware
