import {path} from 'ramda'
import {CollectionFactory} from '@modifi/redux'

import {byIncoTerm} from '../../../utils/static/transport-modality-to-inco-term'
import staticTransportModalities from './static'

const request = async () => staticTransportModalities

const {selectors, actions, reducer} = CollectionFactory({
  reducerPath: ['static', 'transportModalities'],
  entityPrimaryKeySelector: path(['code']),
  actionNamePrefix: 'static/transport-modalities',
  fetchRequest: () => request(),
})

export default reducer

export const selectTransportModalities = state => selectors.selectByKeyWindow(state, 'all')
export const selectTransportModalitiesLoading = state =>
  selectors.selectIsKeyWindowLoading(state, 'all')
export const selectTransportModalityById = selectors.selectById
export const selectTransportModalityNameById = (state, id) =>
  path(['langEN'], selectTransportModalityById(state, id))
export const selectTransportModalitiesByIncoTerm = (state, incoTerm) => {
  const allTransportModalities = selectTransportModalities(state)

  if (incoTerm) {
    const validTransportModalitiesByIncoTerm = byIncoTerm(incoTerm)
    return allTransportModalities.filter(({code}) =>
      validTransportModalitiesByIncoTerm.includes(code)
    )
  }
  return allTransportModalities
}

export const aFetchTransportModalities = () => actions.fetch('all')
export const aEnsureTransportModalities = () => (dispatch, getState) => {
  if (selectTransportModalities(getState()).length === 0) {
    dispatch(aFetchTransportModalities())
  }
}
