import {withTranslation} from '@modifi/plugin-i18n'
import {connect} from '@modifi/redux'
import {aReplace} from '@modifi/router'
import {rejectEmpty} from '@modifi/utilities'
import qs from 'qs'
import {
  compose,
  equals,
  isEmpty,
  isNil,
  length,
  not,
  path,
  pickBy,
  reject,
  startsWith,
  unnest,
  values,
} from 'ramda'
import {
  searchTradePartners,
  selectHasMetaDataError,
  selectHasMultipleRoles,
  selectIsOrdersMetaDataLoading,
  selectMaxAmount,
  selectMinAmount,
  selectNormalizeSearchObj,
  selectOrderBuyerStatusOptions,
} from '../store'

import OrderFilterContainer from './OrderFilterContainer'

const mapStateToProps = (state, ownProps) => {
  const translationsReady = ownProps?.tReady ?? true

  return {
    location: path(['router', 'location'], state),
    search: selectNormalizeSearchObj(state),
    hasMultipleRoles: selectHasMultipleRoles(state),
    isMetaDataLoading: selectIsOrdersMetaDataLoading(state) || !translationsReady,
    hasMetaDataError: selectHasMetaDataError(state),
    searchTradePartners,
    orderBuyerStatusOptions: selectOrderBuyerStatusOptions(state, ownProps?.t),
    maxAmount: selectMaxAmount(state),
    minAmount: selectMinAmount(state),
  }
}

const mapDispatchToProps = {
  replaceUrl: aReplace,
}

const getFilterCount = compose(
  length,
  unnest,
  values,
  pickBy((val, key) => startsWith('filter', key) || (key === 'hasActionRequired' && val === true))
)
const mergeProps = (mapProps, dispatchProps, ownProps) => {
  const {search, location} = mapProps
  const {replaceUrl} = dispatchProps

  return {
    handleSubmit: searchParams => {
      if (
        !equals(
          search,
          reject(prop => isEmpty(prop) || isNil(prop), {...search, ...searchParams})
        )
      )
        replaceUrl({
          ...location,
          search: qs.stringify(
            rejectEmpty({
              ...search,
              ...searchParams,
              page: 1,
            })
          ),
        })
    },
    filterCount: getFilterCount(search),
    resetAllFilters: () => {
      replaceUrl({
        ...location,
        search: qs.stringify({
          ...pickBy((val, key) => not(startsWith('filter', key)), search),
          hasActionRequired: undefined,
        }),
      })
    },
    ...mapProps,
    ...dispatchProps,
    ...ownProps,
  }
}

export default compose(
  withTranslation(['matrix', 'ui']),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(OrderFilterContainer)
