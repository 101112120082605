import {curry} from 'ramda'
import {EntityFactory} from '@modifi/redux'
import {orderBuyerStatus} from '@modifi/utilities/order'
import {paymentBuyerStatus} from '@modifi/utilities/payment'
import {createSelector} from 'reselect'
import {getAuthenticatedAresClient} from '../../../lib'

import {REDUCER_KEY} from '../constants'

const ACTION_KEY = 'order-management/order'

const {actions, selectors, reducer} = EntityFactory({
  actionNamePrefix: ACTION_KEY,
  reducerPath: [REDUCER_KEY, 'data'],
  fetchRequest: async (state, id) => {
    const client = getAuthenticatedAresClient()
    return client.Orders.byId({externalOrderId: id.toString()})
      .then(res => res.data())
      .then(order => ({
        ...order,
        orderBuyerStatus: orderBuyerStatus({
          ...order,
          sellerBankAccount: order?.advanceRateSellerBankAccount,
        }),
        payments: order.payments.map(payment => ({
          ...payment,
          paymentBuyerStatus: paymentBuyerStatus(payment),
        })),
      }))
  },
})

export default reducer

const {selectEntity, selectIsLoading, selectIsError, selectErrorCode} = selectors
export const selectOrder = selectEntity
export const selectOrderIsLoading = selectIsLoading
export const selectOrderIsError = selectIsError
export const selectOrderErrorCode = selectErrorCode

export const selectPayments = createSelector(selectOrder, order => order?.payments || [])
export const selectPaymentByExternalId = curry((state, externalId) => {
  const payments = selectPayments(state)
  return payments.find(p => p.externalId === externalId)
})
export const selectBuyerConfirmationStatus = createSelector(
  selectOrder,
  order => order?.buyerConfirmationStatus
)
export const selectOrderExternalId = createSelector(selectOrder, order => order?.externalId)

export const aFetchOrder = actions.fetch
export const aClearOrder = actions.clear

const mergePayments = (basePayments, paymentUpdates, recomputeStatus = true) =>
  basePayments.map(basePayment => {
    const baseIdent = basePayment.externalId
    const paymentChanges = paymentUpdates.find(p => p.externalId === baseIdent)

    const merged = {
      ...basePayment,
      ...paymentChanges,
    }
    if (recomputeStatus) {
      merged.paymentBuyerStatus = paymentBuyerStatus(merged)
    }
    return merged
  })

export const aUpdateOrder =
  (partialOrder, merge = true, recomputeStatuses = true) =>
  (dispatch, getState) => {
    const fullOrder = selectOrder(getState())

    // process payments
    if (partialOrder?.payments) {
      if (merge) {
        // additions to handle merging of payments
        if (fullOrder?.payments) {
          // eslint-disable-next-line no-param-reassign
          partialOrder.payments = mergePayments(
            fullOrder.payments,
            partialOrder.payments,
            recomputeStatuses
          )
        }
      } else if (recomputeStatuses) {
        // eslint-disable-next-line no-param-reassign
        partialOrder.payments = partialOrder.payments.map(payment => ({
          ...payment,
          paymentBuyerStatus: paymentBuyerStatus(payment),
        }))
      }
    }

    if (recomputeStatuses) {
      const mergedOrder = {
        ...fullOrder,
        ...partialOrder,
      }
      // eslint-disable-next-line no-param-reassign
      partialOrder.orderBuyerStatus = orderBuyerStatus(mergedOrder)
    }

    dispatch(actions.update(partialOrder, merge))
  }

export const aAppendDocumentRefToLocalPayment =
  (externalPaymentId, documentRef) => (dispatch, getState) => {
    const targetPayment = selectPaymentByExternalId(getState(), externalPaymentId)
    const paymentUpdate = {
      externalId: externalPaymentId,
      paymentDocumentRefs: [...targetPayment.paymentDocumentRefs, documentRef],
    }

    dispatch(aUpdateOrder({payments: [paymentUpdate]}))
  }
