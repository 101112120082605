import {aGenericError} from '@modifi/redux'
import {createSelector} from 'reselect'
import {getAuthenticatedAresClient} from '../../../../lib'

const ACTION_UNLINK_DOCUMENT_FROM_PAYMENT_PENDING = 'order/payment/UNLINK_DOCUMENT_PENDING'
const ACTION_UNLINK_DOCUMENT_FROM_PAYMENT_SUCCESS = 'order/payment/UNLINK_DOCUMENT_SUCCESS'
const ACTION_UNLINK_DOCUMENT_FROM_PAYMENT_ERROR = 'order/payment/UNLINK_DOCUMENT_ERROR'
const ACTION_UNLINK_DOCUMENTS_TO_PAYMENT_CLEANUP =
  'order-management/documents/UNLINK_DOCUMENTS_TO_PAYMENT_CLEANUP'

const initialState = {
  inProgress: false,
  error: false,
  errorMessage: null,
  errorCode: null,
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_UNLINK_DOCUMENT_FROM_PAYMENT_PENDING: {
      return {
        ...state,
        inProgress: true,
        error: false,
        errorMessage: null,
        errorCode: null,
      }
    }
    case ACTION_UNLINK_DOCUMENT_FROM_PAYMENT_SUCCESS: {
      return {
        ...initialState,
      }
    }
    case ACTION_UNLINK_DOCUMENT_FROM_PAYMENT_ERROR: {
      const {message, code} = payload
      return {
        ...state,
        inProgress: false,
        error: true,
        errorMessage: message,
        errorCode: code,
      }
    }
    case ACTION_UNLINK_DOCUMENTS_TO_PAYMENT_CLEANUP: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

const baseSelector = state => state.order.documents.unlink
export const selectIsUnlinkDocumentsFromPaymentInProgress = createSelector(
  baseSelector,
  unlink => unlink.inProgress
)
export const selectIsUnlinkDocumentsFromPaymentError = createSelector(
  baseSelector,
  unlink => unlink.error
)
export const selectUnlinkDocumentsFromPaymentErrorMessage = createSelector(
  baseSelector,
  unlink => unlink.errorMessage
)
export const selectUnlinkDocumentsFromPaymentErrorCode = createSelector(
  baseSelector,
  unlink => unlink.errorCode
)

const sendUnlinkDocumentFromPaymentRequest = (externalOrderId, externalPaymentId, fileId) => {
  const client = getAuthenticatedAresClient()
  return client.OrderDocuments.detachDocument({
    externalOrderId,
    externalPaymentId,
    fileId,
  }).then(res => res.data())
}

export const aUnlinkDocumentFromPayment =
  (externalOrderId, externalPaymentId, fileId) => async dispatch => {
    dispatch({
      type: ACTION_UNLINK_DOCUMENT_FROM_PAYMENT_PENDING,
    })

    try {
      await sendUnlinkDocumentFromPaymentRequest(externalOrderId, externalPaymentId, fileId)
    } catch (error) {
      const errorAction = aGenericError(ACTION_UNLINK_DOCUMENT_FROM_PAYMENT_ERROR, error)
      if (errorAction.payload?.code !== 404) {
        // swallow 404 errors since we want to delete the ref and 404 indicates it is deleted already
        dispatch(errorAction)

        throw new Error(errorAction.payload.message || 'Internal Error')
      }
    }

    dispatch({
      type: ACTION_UNLINK_DOCUMENT_FROM_PAYMENT_SUCCESS,
    })
    return true
  }

export const aUnlinkDocumentToPaymentCleanup = () => ({
  type: ACTION_UNLINK_DOCUMENTS_TO_PAYMENT_CLEANUP,
})
