import React, {useEffect} from 'react'
import * as PropTypes from 'prop-types'

const TransportModalityName = ({transportModalityName, ensureTransportModalities}) => {
  useEffect(() => {
    ensureTransportModalities()
  }, [null]) // eslint-disable-line react-hooks/exhaustive-deps
  return <>{transportModalityName}</>
}

TransportModalityName.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  children: PropTypes.string.isRequired,
  ensureTransportModalities: PropTypes.func.isRequired,
  transportModalityName: PropTypes.string,
}

TransportModalityName.defaultProps = {
  transportModalityName: null,
}

export default TransportModalityName
