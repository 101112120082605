import styled, {css, keyframes} from 'styled-components'
import {RegularBlock, media} from '@modifi/ui'
import {landGreen, lightGray} from '@modifi/colors'

export const Wrapper = styled.div`
  position: relative;
  margin: 0;
  ${media.xs`
    margin: 20px 0 0;
  `}
`

export const ProgressBarLabel = styled(RegularBlock)`
  font-size: 14px;
  font-weight: ${({bold}) => (bold ? '600' : 'normal')};
  text-align: ${({departure}) => (departure ? 'start' : 'end')};
`

const movePath = keyframes`
  0% {
    background-color: ${lightGray};
  }

  100% {
    background-color: ${landGreen};
  }
`

export const DeparturePort = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${landGreen};
  border-radius: 2px;
  ${({completed}) =>
    completed
      ? css`
          animation: ${movePath} 3s ease 2s forwards;
        `
      : ''}
`

export const ArrivalPort = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${lightGray};
  border-radius: 2px;
  margin-left: auto;
  ${({completed}) =>
    completed
      ? css`
          animation: ${movePath} 3s ease 2s forwards;
        `
      : ''}
`

export const Dock = styled.div`
  position: relative;
  z-index: 9;
  width: ${({started}) => (started ? '40px' : 0)};
  height: 5px;
  background-color: ${landGreen};
`

export const Path = styled.div`
  position: absolute;
  left: 15px;
  z-index: 1;
  border-top: 2px dashed ${lightGray};
  width: ${({width}) => `${width}px`};
`

export const ProgressIndicator = styled.div`
  position: relative;
  z-index: 9;
  height: 5px;
  width: ${({progress}) => `${progress}px`};
  background-color: ${landGreen};
  transition: width 2s ease 1s;

  > svg {
    position: absolute;
    bottom: 3px;
    right: ${({progress}) => (progress !== 0 ? 0 : '-40px')};
  }
`

export const ShipLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;

  ${ProgressIndicator} {
    > svg {
      display: ${({isLoading}) => (isLoading ? 'none' : 'block')};
    }
  }
`
