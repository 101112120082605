import {AnyAction, CollectionFactory, Reducer, ThunkAction} from '@modifi/redux'
import {createSelector} from 'reselect'
import createCachedSelector from 're-reselect'
import {CurrencyVal, ProductTypeVal} from '@modifi/enums'
import {getAuthenticatedAresClient} from '../../lib'
import {TradePartnerStatisticsView} from '../../lib/ares/gen'

export type TradePartnerStatisticsKeyWindow = {
  productType: ProductTypeVal
  referenceCurrency: CurrencyVal
}

const {actions, selectors, reducer} = CollectionFactory<
  Record<string, any>,
  TradePartnerStatisticsView,
  TradePartnerStatisticsKeyWindow
>({
  actionNamePrefix: 'data/tradePartnerStatistics',
  reducerPath: ['data', 'tradePartnerStatistics'],
  fetchRequest: (state, params) => {
    const client = getAuthenticatedAresClient()
    return client.TradePartnerStatistics.statistics(params).then(
      res => res.data().tradePartnerStatistics ?? []
    )
  },
  entityPrimaryKeySelector: ({externalCompanyId}) => externalCompanyId!,
})

export default reducer as Reducer

export const selectTradePartnerStatisticsIsLoading = (
  state: Record<string, any>,
  args: TradePartnerStatisticsKeyWindow
): boolean => selectors.selectIsKeyWindowLoading(state, args)

export const selectTradePartnerStatisticsFetchCount = (
  state: Record<string, any>,
  args: TradePartnerStatisticsKeyWindow
): number => selectors.selectFetchCountByKeyWindow(state, args)

export const selectTradePartnerStatisticsHasLoaded = createSelector(
  selectTradePartnerStatisticsFetchCount,
  fetchCount => fetchCount > 0
)

export const selectTradePartnerStatisticsIsLoaded = (
  state: Record<string, any>,
  args: TradePartnerStatisticsKeyWindow
): boolean =>
  !selectTradePartnerStatisticsIsLoading(state, args) &&
  selectTradePartnerStatisticsHasLoaded(state, args)

export const selectTradePartnerStatistics = (
  state: Record<string, any>,
  args: TradePartnerStatisticsKeyWindow
): TradePartnerStatisticsView[] => selectors.selectByKeyWindow(state, args)

export const selectTradePartnerStatisticsWithAllocatedLimits = createCachedSelector(
  selectTradePartnerStatistics,
  (tradePartnerStatistics: TradePartnerStatisticsView[]) =>
    tradePartnerStatistics.filter(s => (s.allocatedLimit?.amount ?? 0) > 0)
)((state, args) => `${args?.productType}|${args?.referenceCurrency}`)

export const selectHasTradePartnerStatisticsWithAllocatedLimits = createCachedSelector(
  selectTradePartnerStatisticsWithAllocatedLimits,
  selectTradePartnerStatisticsIsLoaded,
  (tradePartnersWithLimits, tradePartnersLoaded) => {
    if (!tradePartnersLoaded) return true
    return tradePartnersWithLimits.length > 0
  }
)((state, args) => `${args?.productType}|${args?.referenceCurrency}`)
//
export const aFetchTradePartnerStatistics = (params: TradePartnerStatisticsKeyWindow): any =>
  actions!.fetch(params)
//
export const aEnsureTradePartnerStatistics =
  (
    referenceCurrency: CurrencyVal,
    productType: ProductTypeVal
  ): ThunkAction<unknown, any, unknown, AnyAction> =>
  (dispatch, getState) => {
    if (
      selectTradePartnerStatisticsIsLoading(getState(), {referenceCurrency, productType}) ||
      selectTradePartnerStatisticsHasLoaded(getState(), {referenceCurrency, productType})
    ) {
      return
    }
    dispatch(aFetchTradePartnerStatistics({referenceCurrency, productType}))
  }
