import {aGenericError} from '@modifi/redux'
import {createSelector} from 'reselect'
import {getAuthenticatedAresClient} from '../../../../lib'
import {aAppendDocumentRefToLocalPayment} from '../data.store'

const ACTION_LINK_DOCUMENT_TO_PAYMENT_PENDING =
  'order-management/documents/LINK_DOCUMENT_TO_PAYMENT_PENDING'
const ACTION_LINK_DOCUMENT_TO_PAYMENT_SUCCESS =
  'order-management/documents/LINK_DOCUMENT_TO_PAYMENT_SUCCESS'
const ACTION_LINK_DOCUMENT_TO_PAYMENT_ERROR =
  'order-management/documents/LINK_DOCUMENT_TO_PAYMENT_ERROR'
const ACTION_LINK_DOCUMENTS_TO_PAYMENT_CLEANUP =
  'order-management/documents/LINK_DOCUMENTS_TO_PAYMENT_CLEANUP'

const initialState = {
  inProgress: 0,
  error: false,
  errors: [],
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_LINK_DOCUMENT_TO_PAYMENT_PENDING: {
      return {
        ...state,
        inProgress: state.inProgress + 1,
      }
    }
    case ACTION_LINK_DOCUMENT_TO_PAYMENT_SUCCESS: {
      return {
        ...state,
        inProgress: state.inProgress - 1,
      }
    }
    case ACTION_LINK_DOCUMENT_TO_PAYMENT_ERROR: {
      const {message, code} = payload
      return {
        ...state,
        inProgress: state.inProgress - 1,
        error: true,
        errors: [
          ...state,
          {
            errorMessage: message,
            errorCode: code,
          },
        ],
      }
    }
    case ACTION_LINK_DOCUMENTS_TO_PAYMENT_CLEANUP: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

const sendLinkDocumentToPaymentRequest = (
  externalOrderId,
  externalPaymentId,
  fileId,
  documentType
) => {
  const client = getAuthenticatedAresClient()
  return client.OrderDocuments.attachDocument({
    externalOrderId,
    body: {
      externalPaymentId,
      documentType,
      fileId,
    },
  }).then(res => res.data())
}

const baseSelector = state => state.order.documents.link
export const selectIsLinkDocumentsFromPaymentInProgress = createSelector(
  baseSelector,
  link => link.inProgress > 0
)
export const selectIsLinkDocumentsFromPaymentError = createSelector(
  baseSelector,
  link => link.error
)
export const selectLinkDocumentsFromPaymentErrors = createSelector(
  baseSelector,
  link => link.errors
)

export const aLinkDocumentRefToPayment =
  (externalOrderId, externalPaymentId, {fileId, documentType}) =>
  async dispatch => {
    dispatch({
      type: ACTION_LINK_DOCUMENT_TO_PAYMENT_PENDING,
      payload: {externalOrderId},
    })

    try {
      const order = await sendLinkDocumentToPaymentRequest(
        externalOrderId,
        externalPaymentId,
        fileId,
        documentType
      )
      dispatch({
        type: ACTION_LINK_DOCUMENT_TO_PAYMENT_SUCCESS,
        payload: {},
      })

      // add the new document ref to the respective payment locally
      // we try to make the update as atomic as possible to prevent race conditions
      const targetPayment = order?.payments?.find(p => p.externalId === externalPaymentId)
      if (targetPayment?.paymentDocumentRefs) {
        // search for the document ref
        const newPaymentDocumentRef = targetPayment.paymentDocumentRefs?.find(
          ref => ref.fileId === fileId
        )
        if (newPaymentDocumentRef) {
          // yay, found it
          dispatch(
            aAppendDocumentRefToLocalPayment(targetPayment.externalId, newPaymentDocumentRef)
          )
        }
      }
    } catch (e) {
      dispatch(aGenericError(ACTION_LINK_DOCUMENT_TO_PAYMENT_ERROR, e))
      throw e
    }
  }

export const aLinkDocumentToPaymentCleanup = () => ({
  type: ACTION_LINK_DOCUMENTS_TO_PAYMENT_CLEANUP,
})
