import {combineReducers} from '@modifi/redux'
import cancelReducer from './cancel.store'
import dataReducer from './data.store'
import paymentActivationReducer from './payment-activation.store'
import extensionsReducer from './extensions.store'
import sellerBankAccountReducer from './seller-bank-account.store'
import editReferenceReducer from './edit-reference.store'
import documentsReducer from './documents'
import updateReviewStatusReducer from './update-review-status.store'
import editFundsTransferArrivalDateReducer from './edit-funds-transfer-arrival-date.store'
import buyerConfirmationReducer from './buyer-confirmation.store'

export default combineReducers({
  data: dataReducer,
  paymentActivation: paymentActivationReducer,
  extensions: extensionsReducer,
  cancel: cancelReducer,
  sellerBankAccount: sellerBankAccountReducer,
  editReference: editReferenceReducer,
  documents: documentsReducer,
  updateReviewStatus: updateReviewStatusReducer,
  editFundsTransferArrivalDate: editFundsTransferArrivalDateReducer,
  buyerConfirmation: buyerConfirmationReducer,
})

export {
  aFetchOrder,
  aClearOrder,
  aUpdateOrder,
  selectOrder,
  selectOrderIsLoading,
  selectOrderIsError,
  selectOrderErrorCode,
  selectPayments,
  selectBuyerConfirmationStatus,
  selectOrderExternalId,
} from './data.store'
export {
  selectActivationError,
  selectActivationPending,
  aSubmitActivation,
  aCleanup,
} from './payment-activation.store'
export {
  aFetchExtensions,
  selectAvailableExtensions,
  selectAvailableExtensionsCount,
  selectHasAvailableExtensions,
  aApplyExtension,
  selectApplyExtensionError,
  selectIsApplyExtensionPending,
  aApplyExtensionCleanup,
} from './extensions.store'
export {
  aCancelOrderCleanup,
  aCancelOrder,
  selectCancelError,
  selectIsCancelPending,
} from './cancel.store'
export {
  aEditSellerBankAccountCleanup,
  aEditSellerBankAccount,
  selectEditSellerBankAccountError,
  selectIsEditSellerBankAccountPending,
} from './seller-bank-account.store'
export {
  aEditOrderReference,
  aEditOrderReferenceCleanup,
  selectEditReferenceError,
  selectIsEditReferencePending,
} from './edit-reference.store'
export {
  aLinkDocumentRefToPayment,
  aLinkDocumentToPaymentCleanup,
  selectIsLinkDocumentsFromPaymentError,
  selectIsLinkDocumentsFromPaymentInProgress,
  selectLinkDocumentsFromPaymentErrors,
  aUnlinkDocumentFromPayment,
  aUnlinkDocumentToPaymentCleanup,
  selectIsUnlinkDocumentsFromPaymentError,
  selectIsUnlinkDocumentsFromPaymentInProgress,
  selectUnlinkDocumentsFromPaymentErrorCode,
  selectUnlinkDocumentsFromPaymentErrorMessage,
} from './documents'
export {
  aUpdateTradeConfirmation,
  selectIsUpdateReviewStatusPending,
  selectUpdateReviewStatusError,
  aUpdateReviewStatusCleanup,
  aClearError,
} from './update-review-status.store'
export {
  aEditFundsTransferArrivalDateCleanup,
  aEditFundsTransferArrivalDate,
  selectEditFundsTransferArrivalDateError,
  selectIsEditFundsTransferArrivalDatePending,
} from './edit-funds-transfer-arrival-date.store'

export {
  selectBuyerConfirmationSentRequest,
  selectBuyerConfirmationHasError,
  selectBuyerConfirmationIsLoading,
  aFetchBuyerConfirmation,
} from './buyer-confirmation.store'
