import {connect} from '@modifi/redux'
import {path, compose} from 'ramda'
import {aReplace} from '@modifi/router'
import {withTranslation} from '@modifi/plugin-i18n'

import MobileFilterScreen from './MobileFilters'
import {
  searchTradePartners,
  selectFirstOrderDate,
  selectMaxAmount,
  selectHasMultipleRoles,
  selectNormalizeSearchObj,
  selectOrderBuyerStatusOptions,
} from '../store'

const mapStateToProps = (state, ownProps) => ({
  location: path(['router', 'location'], state),
  search: selectNormalizeSearchObj(state),
  searchTradePartners,
  firstOrderDate: selectFirstOrderDate(state),
  maxAmount: selectMaxAmount(state),
  hasMultipleRoles: selectHasMultipleRoles(state),
  orderBuyerStatusOptions: selectOrderBuyerStatusOptions(state, ownProps?.t),
})

const mapDispatchToProps = {
  replaceUrl: aReplace,
}

export default compose(
  withTranslation('matrix'),
  connect(mapStateToProps, mapDispatchToProps)
)(MobileFilterScreen)
