import styled from 'styled-components'
import {P} from '@modifi/ui'
import {backgroundColor} from '@modifi/colors'

export const Wrapper = styled.div`
  background-color: ${backgroundColor};
  padding: 4px 16px;
  border-radius: 8px;
}
`

export const StyledComment = styled(P)`
  white-space: pre-line;
`
