import {EntityFactory} from '@modifi/redux'
import {TradePartnerOrderView} from '@modifi/api-clients'
import {BuyerConfirmationStatus, TradePartnerOrderStatus, DocumentType} from '@modifi/enums'
import {createSelector} from 'reselect'
import {getAuthenticatedAresClient} from '../../../lib'
import {DocumentView, ReferencedDocumentView} from '../../../lib/ares/gen'

import {REDUCER_KEY} from '../constants'

const ACTION_KEY = 'order-management/trade-partner-order'

// @todo fix typings in api-clients
// Extended type without the 'testProperty'
type TradePartnerOrderViewWithoutTradePartnerStatus = Omit<
  TradePartnerOrderView,
  'tradePartnerOrderStatus'
>

// Extended type with the new 'testProperty' type
type ExtendedTradePartnerOrderView = TradePartnerOrderViewWithoutTradePartnerStatus & {
  tradePartnerOrderStatus: TradePartnerOrderStatus
  buyerConfirmationStatus: BuyerConfirmationStatus
}

const {actions, selectors, reducer} = EntityFactory({
  actionNamePrefix: ACTION_KEY,
  reducerPath: [REDUCER_KEY, 'data'],
  fetchRequest: async (state, id: number) => {
    const client = getAuthenticatedAresClient()
    return client.TradePartnerOrders.byId({externalOrderId: id.toString()}).then(res => res.data())
  },
})

export default reducer

const {selectEntity, selectIsLoading, selectIsError, selectErrorCode} = selectors
export const selectOrder = (
  state: Record<string, any>
): ExtendedTradePartnerOrderView | null | undefined =>
  selectEntity(state) as ExtendedTradePartnerOrderView | null | undefined
export const selectOrderIsLoading = selectIsLoading
export const selectOrderIsError = selectIsError
export const selectOrderErrorCode = selectErrorCode

export const selectExternalOrderId = createSelector(selectOrder, order => order?.externalId)

export const selectDocumentsToConfirm = createSelector(
  selectOrder,
  order => order?.documentsToConfirm
)

export const selectReferencedDocuments = createSelector(selectOrder, order =>
  order?.referencedDocuments?.filter(
    ({documentType}) => documentType === DocumentType.PURCHASE_ORDER
  )
)

// return PO document type when we have reference documents
// even when no PO documents available in documentsToConfirm
export const selectFormattedDocuments = createSelector(
  selectDocumentsToConfirm,
  selectReferencedDocuments,
  (documents: DocumentView[] = [], referenceDocuments: ReferencedDocumentView[] = []) => {
    const hasPurchaseOrderInDocuments = documents?.some(
      item => item.documentType === DocumentType.PURCHASE_ORDER
    )
    const hasPurchaseOrderInReferenceDocuments = referenceDocuments?.some(
      item => item.documentType === DocumentType.PURCHASE_ORDER
    )
    if (!hasPurchaseOrderInDocuments && hasPurchaseOrderInReferenceDocuments) {
      return [
        ...documents,
        ...referenceDocuments.map(file => ({...file, fileName: file?.documentNumber})),
      ]
    }
    return documents
  }
)

export const selectRepaymentBankAccount = createSelector(
  selectOrder,
  order => order?.repaymentBankAccount
)

export const selectPaymentReference = createSelector(
  selectOrder,
  order => order?.payments[0]?.repayment?.buyerIntendedPurpose
)

export const aFetchOrder = actions!.fetch
export const aClearOrder = actions!.clear
