import React, {Component} from 'react'
import {
  CheckBoxFilterContainer,
  DateFilterContainer,
  AmountFilterContainer,
  CheckboxPure,
} from '@modifi/ui'
import {v4 as uuid} from 'uuid'
import qs from 'qs'
import {pickBy, startsWith, not} from 'ramda'
import {rejectEmpty} from '@modifi/utilities'
import * as PropTypes from 'prop-types'
import {TradePartnerOrderStatus} from '@modifi/enums'
import Header from './components/header'
import {MobileFilterWrapper, FilterContainer, SubmitButton} from './styles'
import {getDefaultSelected} from '../utils/filterValues'
import {formatMomentToString} from '../../../utils/formatMomentToString'
import {getCleanAmountFilters} from '../utils/getCleanAmountFilters'

export default class MobileFilters extends Component {
  static propTypes = {
    search: PropTypes.shape({
      hasActionRequired: PropTypes.bool,
      'filter.amount.from': PropTypes.string,
      'filter.amount.to': PropTypes.string,
      'filter.createdOn.from': PropTypes.string,
      'filter.createdOn.to': PropTypes.string,
      'filter.repayment.repaymentDate.from': PropTypes.string,
      'filter.repayment.repaymentDate.to': PropTypes.string,
    }).isRequired,
    tradePartnerOrderStatusOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOf(Object.values(TradePartnerOrderStatus)),
      })
    ).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    replaceUrl: PropTypes.func.isRequired,
    maxAmount: PropTypes.string,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    maxAmount: null,
  }

  constructor(props) {
    super(props)
    const {search, tradePartnerOrderStatusOptions} = this.props
    this.state = {
      tradePartnerOrderStatusSelected: getDefaultSelected(
        'filter.tradePartnerOrderStatus',
        search,
        tradePartnerOrderStatusOptions
      ),
      hasActionRequired: search.hasActionRequired === true,
      fromAmount: search['filter.amount.from'],
      toAmount: search['filter.amount.to'],
      createdOnFromDate: search['filter.createdOn.from'],
      createdOnToDate: search['filter.createdOn.to'],
      repaymentFromDate: search['filter.repayment.repaymentDate.from'],
      repaymentToDate: search['filter.repayment.repaymentDate.to'],
      nonce: uuid(),
    }
  }

  resetAllFilters = () => {
    this.setState({
      tradePartnerOrderStatusSelected: [],
      hasActionRequired: null,
      fromAmount: null,
      toAmount: null,
      createdOnFromDate: null,
      createdOnToDate: null,
      repaymentFromDate: null,
      repaymentToDate: null,
      nonce: uuid(),
    })
    const {replaceUrl, location, search} = this.props
    replaceUrl({
      ...location,
      search: qs.stringify({
        ...pickBy((val, key) => not(startsWith('filter', key)), search),
        hasActionRequired: undefined,
      }),
    })
  }

  handleSubmit = () => {
    const {
      fromAmount,
      toAmount,
      tradePartnerOrderStatusSelected,
      createdOnFromDate,
      createdOnToDate,
      repaymentFromDate,
      repaymentToDate,
      hasActionRequired,
    } = this.state
    const {replaceUrl, location, search} = this.props
    const cleanAmount = getCleanAmountFilters(fromAmount || null, toAmount || null)
    const searchParams = {
      'filter.tradePartnerOrderStatus': tradePartnerOrderStatusSelected.map(({value}) => value),
      'filter.createdOn.from':
        typeof fromDate === 'string' || !createdOnFromDate
          ? createdOnFromDate
          : formatMomentToString(createdOnFromDate),
      'filter.createdOn.to':
        typeof toDate === 'string' || !createdOnToDate
          ? createdOnToDate
          : formatMomentToString(createdOnToDate),
      'filter.repayment.repaymentDate.from':
        typeof fromDate === 'string' || !repaymentFromDate
          ? repaymentFromDate
          : formatMomentToString(repaymentFromDate),
      'filter.repayment.repaymentDate.to':
        typeof toDate === 'string' || !repaymentToDate
          ? repaymentToDate
          : formatMomentToString(repaymentToDate),
      'filter.amount.from': cleanAmount.from,
      'filter.amount.to': cleanAmount.to,
      hasActionRequired,
    }
    replaceUrl({
      ...location,
      pathname: '/trade-partner-orders',
      search: qs.stringify(
        rejectEmpty({
          ...search,
          ...searchParams,
          page: 1,
        })
      ),
    })
  }

  getFilterCount = () => {
    const {
      fromAmount,
      toAmount,
      tradePartnerOrderStatusSelected,
      createdOnFromDate,
      createdOnToDate,
      repaymentFromDate,
      repaymentToDate,
      hasActionRequired,
    } = this.state
    const count =
      tradePartnerOrderStatusSelected.length +
      (hasActionRequired ? 1 : 0) +
      (fromAmount ? 1 : 0) +
      (toAmount ? 1 : 0) +
      (createdOnFromDate ? 1 : 0) +
      (createdOnToDate ? 1 : 0) +
      (repaymentFromDate ? 1 : 0) +
      (repaymentToDate ? 1 : 0)
    return count
  }

  render() {
    const {
      fromAmount,
      toAmount,
      tradePartnerOrderStatusSelected,
      createdOnFromDate,
      createdOnToDate,
      repaymentFromDate,
      repaymentToDate,
      nonce,
      hasActionRequired,
    } = this.state

    const {replaceUrl, location, search, t, tradePartnerOrderStatusOptions, maxAmount} = this.props
    return (
      <MobileFilterWrapper>
        <Header
          onReset={this.resetAllFilters}
          filterCount={this.getFilterCount()}
          onClose={() => {
            replaceUrl({
              ...location,
              pathname: '/trade-partner-orders',
              search: qs.stringify({
                ...search,
              }),
            })
          }}
        />
        <FilterContainer>
          <CheckboxPure
            checked={hasActionRequired}
            label={t('ol.filters.action-required.mobile.label')}
            name="actionRequired"
            onChange={e => this.setState({hasActionRequired: e.target.checked})}
          />
        </FilterContainer>
        <FilterContainer>
          <CheckBoxFilterContainer
            options={tradePartnerOrderStatusOptions}
            label={t('ol.filters.trade-partner-order-status.mobile.label')}
            defaultSelected={tradePartnerOrderStatusSelected}
            onChange={newStatus => this.setState({tradePartnerOrderStatusSelected: newStatus})}
          />
        </FilterContainer>
        <FilterContainer>
          <DateFilterContainer
            label={t('ol.filters.created-on.mobile.label')}
            fromDate={createdOnFromDate}
            toDate={createdOnToDate}
            onFromDateChange={from => this.setState({createdOnFromDate: from})}
            onToDateChange={to => this.setState({createdOnToDate: to})}
            toAnchorDirection="right"
            numberOfMonths={1}
            startCalendarFrom="1990-01-01"
          />
        </FilterContainer>
        <FilterContainer>
          <DateFilterContainer
            label={t('ol.filters.repayment-on.mobile.label')}
            fromDate={repaymentFromDate}
            toDate={repaymentToDate}
            onFromDateChange={from => this.setState({repaymentFromDate: from})}
            onToDateChange={to => this.setState({repaymentToDate: to})}
            toAnchorDirection="right"
            numberOfMonths={1}
            startCalendarFrom="1990-01-01"
            preferFutureDates
          />
        </FilterContainer>
        <FilterContainer>
          <AmountFilterContainer
            key={nonce}
            label={t('ol.filters.order-amount.mobile.label')}
            fromAmount={fromAmount}
            toAmount={toAmount}
            onFromAmountChange={newAmount => this.setState({fromAmount: newAmount.value})}
            onToAmountChange={newAmount => this.setState({toAmount: newAmount.value})}
            maxValue={maxAmount}
          />
        </FilterContainer>

        <SubmitButton block onClick={this.handleSubmit}>
          {t('ol.filters.mobile.cta-submit')}
        </SubmitButton>
      </MobileFilterWrapper>
    )
  }
}
