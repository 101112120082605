import Manager from '@modifi/plugin-manager'

import {PLUGIN_NAME} from './constants'
import reducer from './store'

if (!Manager.isRegistered(PLUGIN_NAME)) {
  Manager.registerPlugin(PLUGIN_NAME)

  Manager.addReducer(PLUGIN_NAME, reducer)
}

export * from './index'
