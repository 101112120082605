import {connect} from '@modifi/redux'

import {selectTransportModalityNameById, aEnsureTransportModalities} from '../../store'
import TransportModalityName from './TransportModalityName'

const mapStateToProps = (state, ownProps) => ({
  transportModalityName: selectTransportModalityNameById(state, `${ownProps.children}`.trim()),
})

const mapDispatchToProps = {
  ensureTransportModalities: aEnsureTransportModalities,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransportModalityName)
