import {equals} from 'ramda'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Row,
  Col,
  DateFilter,
  AmountFilter,
  ResetFilterButton,
  CheckboxPure,
  CheckboxFilter,
} from '@modifi/ui'
import * as PropTypes from 'prop-types'
import {TradePartnerOrderStatus} from '@modifi/enums'

import {
  Wrapper,
  FilterCol,
  FilterWrapper,
  ActionRequiredWrapper,
  ResetFilterWrapper,
} from './styles'
import {formatMomentToString} from '../../../utils/formatMomentToString'
import {getDefaultSelected} from '../utils/filterValues'
import {SkeletonButton} from '../../../ui/skeleton-loaders'
import SortFilter from '../sort-filter'

const OrderFilterContainer = ({
  search,
  isMetaDataLoading,
  t,
  handleSubmit,
  filterCount,
  resetAllFilters,
  tradePartnerOrderStatusOptions,
  hasMetaDataError,
  maxAmount,
  minAmount,
}) => {
  const [actionRequiredChecked, setActionRequiredChecked] = useState(!!search.hasActionRequired)
  const searchHasActionRequired = !!search.hasActionRequired
  const searchHasActionRequiredRef = useRef(searchHasActionRequired)

  useEffect(() => {
    // in case this filter got changed via quick filter
    if (searchHasActionRequired !== searchHasActionRequiredRef.current) {
      searchHasActionRequiredRef.current = searchHasActionRequired
      setActionRequiredChecked(searchHasActionRequired)
    }
  }, [searchHasActionRequired])

  const actionRequiredCheckedRef = useRef(actionRequiredChecked)
  useEffect(() => {
    if (actionRequiredCheckedRef.current !== actionRequiredChecked) {
      actionRequiredCheckedRef.current = actionRequiredChecked
      const timeout = setTimeout(() => {
        handleSubmit({
          hasActionRequired: actionRequiredChecked || null,
        })
      }, 100)
      return () => {
        clearTimeout(timeout)
      }
    }
    return undefined
  }, [actionRequiredChecked, handleSubmit, searchHasActionRequired])
  const handleTradePartnerOrderStatusSubmit = useCallback(
    selected => {
      const searchParams = {
        'filter.tradePartnerOrderStatus': selected.map(({value}) => value),
      }
      handleSubmit(searchParams)
    },
    [handleSubmit]
  )

  const handleTradePartnerOrderStatusReset = useCallback(() => {
    handleSubmit({'filter.tradePartnerOrderStatus': null})
  }, [handleSubmit])

  const handleDateFilterSubmit = useCallback(
    (selected, from, to) => {
      const searchParams = {
        [from]:
          typeof selected.from === 'string' || !selected.from
            ? selected.from
            : formatMomentToString(selected.from),
        [to]:
          typeof selected.to === 'string' || !selected.to
            ? selected.to
            : formatMomentToString(selected.to),
      }
      handleSubmit(searchParams)
    },
    [handleSubmit]
  )

  const handleDateFilterReset = useCallback(
    (selected, from, to) => {
      handleSubmit({
        [from]: selected.from,
        [to]: selected.to,
      })
    },
    [handleSubmit]
  )

  const handleAmountFilterSubmit = useCallback(
    selected => {
      const searchParams = {
        'filter.amount.from': selected.from,
        'filter.amount.to': selected.to,
      }
      handleSubmit(searchParams)
    },
    [handleSubmit]
  )

  const handleAmountFilterReset = useCallback(
    () =>
      handleSubmit({
        'filter.amount.from': null,
        'filter.amount.to': null,
      }),
    [handleSubmit]
  )

  const handleActionRequiredFilterChange = useCallback(e => {
    const isChecked = e.target.checked
    setActionRequiredChecked(isChecked)
  }, [])

  if (hasMetaDataError) return null
  return (
    <Wrapper data-ident="order-filters" mt={20} py={15} px={20} mdPy={32} mdPx={32}>
      <Row alignItems="center" justifyContent="between">
        <Col auto>
          <Row>
            <FilterCol col="auto">
              {!isMetaDataLoading ? (
                <FilterWrapper>
                  <CheckboxFilter
                    options={tradePartnerOrderStatusOptions}
                    fieldName={t('ol.filters.trade-partner-order-status.fieldName')}
                    dropdownPosition="left"
                    onSubmit={handleTradePartnerOrderStatusSubmit}
                    defaultSelected={getDefaultSelected(
                      'filter.tradePartnerOrderStatus',
                      search,
                      tradePartnerOrderStatusOptions
                    )}
                    onReset={handleTradePartnerOrderStatusReset}
                    dropdownWidth={270}
                  />
                </FilterWrapper>
              ) : (
                <SkeletonButton stringLength={13} />
              )}
            </FilterCol>
            <FilterCol col="auto">
              {!isMetaDataLoading ? (
                <FilterWrapper>
                  <DateFilter
                    fieldName={t('ol.filters.order-date.fieldName')}
                    defaultFrom={search['filter.createdOn.from']}
                    defaultTo={search['filter.createdOn.to']}
                    onSubmit={selected =>
                      handleDateFilterSubmit(
                        selected,
                        'filter.createdOn.from',
                        'filter.createdOn.to'
                      )
                    }
                    onReset={selected =>
                      handleDateFilterReset(
                        selected,
                        'filter.createdOn.from',
                        'filter.createdOn.to'
                      )
                    }
                    dropdownWidth={330}
                    startCalendarFrom="1990-01-01"
                  />
                </FilterWrapper>
              ) : (
                <SkeletonButton stringLength={13} />
              )}
            </FilterCol>
            <FilterCol col="auto">
              {!isMetaDataLoading ? (
                <FilterWrapper>
                  <DateFilter
                    fieldName={t('ol.filters.repayment-date.fieldName')}
                    defaultFrom={search['filter.repayment.repaymentDate.from']}
                    defaultTo={search['filter.repayment.repaymentDate.to']}
                    onSubmit={selected =>
                      handleDateFilterSubmit(
                        selected,
                        'filter.repayment.repaymentDate.from',
                        'filter.repayment.repaymentDate.to'
                      )
                    }
                    onReset={selected =>
                      handleDateFilterReset(
                        selected,
                        'filter.repayment.repaymentDate.from',
                        'filter.repayment.repaymentDate.to'
                      )
                    }
                    dropdownWidth={330}
                    startCalendarFrom="1990-01-01"
                    preferFutureDates
                  />
                </FilterWrapper>
              ) : (
                <SkeletonButton stringLength={13} />
              )}
            </FilterCol>
            <FilterCol col="auto">
              {!isMetaDataLoading ? (
                <FilterWrapper>
                  <AmountFilter
                    fieldName={t('ol.filters.amount.fieldName')}
                    defaultFrom={search['filter.amount.from']}
                    defaultTo={search['filter.amount.to']}
                    onSubmit={handleAmountFilterSubmit}
                    onReset={handleAmountFilterReset}
                    maxValue={maxAmount}
                    minValue={minAmount}
                  />
                </FilterWrapper>
              ) : (
                <SkeletonButton stringLength={13} />
              )}
            </FilterCol>
            <FilterCol col="auto">
              {!isMetaDataLoading ? (
                <ActionRequiredWrapper>
                  <CheckboxPure
                    checked={actionRequiredChecked}
                    value
                    label={t('ol.filters.action-required-checkbox.label')}
                    name="actionRequired"
                    onChange={handleActionRequiredFilterChange}
                  />
                </ActionRequiredWrapper>
              ) : (
                <SkeletonButton stringLength={17} />
              )}
            </FilterCol>
          </Row>
        </Col>
        <Col auto>
          <Row>
            {!!filterCount && (
              <FilterCol auto>
                <ResetFilterWrapper>
                  <ResetFilterButton
                    data-target="reset-filters"
                    filterCount={filterCount}
                    onReset={resetAllFilters}
                    useSuspense={false}
                  />
                </ResetFilterWrapper>
              </FilterCol>
            )}
            <FilterCol auto>
              {!isMetaDataLoading ? (
                <SortFilter useSuspense={false} />
              ) : (
                <SkeletonButton stringLength={17} />
              )}
            </FilterCol>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  )
}

OrderFilterContainer.propTypes = {
  search: PropTypes.shape({
    hasActionRequired: PropTypes.bool,
    'filter.tradePartnerOrderStatus': PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(TradePartnerOrderStatus))
    ),
    'filter.createdOn.from': PropTypes.string,
    'filter.createdOn.to': PropTypes.string,
    'filter.repayment.repaymentDate.from': PropTypes.string,
    'filter.repayment.repaymentDate.to': PropTypes.string,
    'filter.amount.from': PropTypes.string,
    'filter.amount.to': PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  filterCount: PropTypes.number.isRequired,
  resetAllFilters: PropTypes.func.isRequired,
  maxAmount: PropTypes.string,
  minAmount: PropTypes.string,
  isMetaDataLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  tradePartnerOrderStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOf(Object.values(TradePartnerOrderStatus)),
    })
  ).isRequired,
  hasMetaDataError: PropTypes.bool.isRequired,
}

OrderFilterContainer.defaultProps = {
  maxAmount: null,
  minAmount: null,
}

export default React.memo(OrderFilterContainer, equals)
