// @todo move to components package
import React from 'react'
import {Svg} from '@modifi/ui'

// eslint-disable-next-line react/prop-types
export default React.forwardRef<SVGSVGElement, {color: string}>((props, ref) => (
  <Svg viewBox="0 0 24 22" {...props} ref={ref}>
    <path
      d="M2.01618 21.8127C1.46174 21.8127 0.987097 21.6153 0.592247 21.2205C0.197416 20.8256 0 20.351 0 19.7965V2.20343C0 1.64898 0.197416 1.17433 0.592247 0.779503C0.987097 0.384671 1.46174 0.187256 2.01618 0.187256H9.85065C10.4051 0.187256 10.8797 0.384671 11.2746 0.779503C11.6694 1.17433 11.8668 1.64898 11.8668 2.20343V5.07012H21.9838C22.5383 5.07012 23.0129 5.26753 23.4078 5.66236C23.8026 6.05719 24 6.53184 24 7.08629V19.7965C24 20.351 23.8026 20.8256 23.4078 21.2205C23.0129 21.6153 22.5383 21.8127 21.9838 21.8127H2.01618ZM1.93835 19.8744H5.04562V16.7671H1.93835V19.8744ZM1.93835 14.9915H5.04562V11.8842H1.93835V14.9915ZM1.93835 10.1086H5.04562V7.00136H1.93835V10.1086ZM1.93835 5.22578H5.04562V2.1185H1.93835V5.22578ZM6.82121 19.8744H9.92848V16.7671H6.82121V19.8744ZM6.82121 14.9915H9.92848V11.8842H6.82121V14.9915ZM6.82121 10.1086H9.92848V7.00136H6.82121V10.1086ZM6.82121 5.22578H9.92848V2.1185H6.82121V5.22578ZM11.7041 19.8744H22.0617V7.00136H11.7041V10.1086H14.0715V11.8842H11.7041V14.9915H14.0715V16.7671H11.7041V19.8744ZM17.1788 11.8842V10.1086H18.9544V11.8842H17.1788ZM17.1788 16.7671V14.9915H18.9544V16.7671H17.1788Z"
      fill={props?.color}
    />
  </Svg>
))
