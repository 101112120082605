import {compose, uniq, map, prop, filter, propEq} from 'ramda'

const mapping = [
  // SEA
  {
    incoTerm: 'EXW',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'FCA',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'FAS',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'FOB',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'CFR',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'CIF',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'CPT',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'CIP',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'DAT',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'DAP',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'DDP',
    transportModality: 'SEA',
  },
  {
    incoTerm: 'UNKNOWN',
    transportModality: 'SEA',
  },
  // AIR
  {
    incoTerm: 'EXW',
    transportModality: 'AIR',
  },
  {
    incoTerm: 'FCA',
    transportModality: 'AIR',
  },
  {
    incoTerm: 'CPT',
    transportModality: 'AIR',
  },
  {
    incoTerm: 'CIP',
    transportModality: 'AIR',
  },
  {
    incoTerm: 'DAT',
    transportModality: 'AIR',
  },
  {
    incoTerm: 'DAP',
    transportModality: 'AIR',
  },
  {
    incoTerm: 'DDP',
    transportModality: 'AIR',
  },
  {
    incoTerm: 'UNKNOWN',
    transportModality: 'AIR',
  },
  // ROAD_RAIL
  {
    incoTerm: 'EXW',
    transportModality: 'ROAD_RAIL',
  },
  {
    incoTerm: 'FCA',
    transportModality: 'ROAD_RAIL',
  },
  {
    incoTerm: 'CPT',
    transportModality: 'ROAD_RAIL',
  },
  {
    incoTerm: 'CIP',
    transportModality: 'ROAD_RAIL',
  },
  {
    incoTerm: 'DAT',
    transportModality: 'ROAD_RAIL',
  },
  {
    incoTerm: 'DAP',
    transportModality: 'ROAD_RAIL',
  },
  {
    incoTerm: 'DDP',
    transportModality: 'ROAD_RAIL',
  },
  {
    incoTerm: 'UNKNOWN',
    transportModality: 'ROAD_RAIL',
  },
]

export const byIncoTerm = incoTerm =>
  compose(uniq, map(prop('transportModality')), filter(propEq('incoTerm', incoTerm)))(mapping)

export const byTransportModality = transportModality =>
  compose(
    uniq,
    map(prop('incoTerm')),
    filter(propEq('transportModality', transportModality))
  )(mapping)
