import {clientConfig} from '@modifi/client-config'
import {AppAbility} from '../constants/permissions'

const handleAbilityBasedRedirect = (ability: Pick<AppAbility, 'can' | 'cannot'>): void => {
  if (ability.cannot('access-service', 'matrix')) {
    // check where to redirect the user to
    if (ability.can('access-service', 'treseus') && clientConfig.get('service_billing')) {
      requestAnimationFrame(() => {
        window.location.replace(clientConfig.get('service_billing') as string)
      })
    } else if (ability.can('access-service', 'charon') && clientConfig.get('service_onboarding')) {
      // charon is allowed, lets redirect the user
      requestAnimationFrame(() => {
        window.location.replace(clientConfig.get('service_onboarding') as string)
      })
    } else if (ability.can('access-service', 'argus') && clientConfig.get('service_shipments')) {
      requestAnimationFrame(() => {
        window.location.replace(clientConfig.get('service_shipments') as string)
      })
    }
  }
}

export default handleAbilityBasedRedirect
