import {path, pathOr, compose, add} from 'ramda'
import {createSelector} from 'reselect'
import {CollectionFactory, combineReducers} from '@modifi/redux'
import {getAuthenticatedAresClient} from '../../../lib'
import {ROOT_REDUCER_KEY, REDUCER_KEY, ACTION_KEY} from '../constants'

const ACTION_SET_PAGINATION_INDEX = `custom-plugins/${ACTION_KEY}/order-shipments/SET_PAGINATION_INDEX`

const initialState = {
  pagination: {
    pageIndex: 0,
    pageSize: 1,
  },
}

const paginationReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_SET_PAGINATION_INDEX: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageIndex: payload,
        },
      }
    }
    default: {
      return state
    }
  }
}

const {
  reducer: CollectionReducer,
  actions,
  selectors,
} = CollectionFactory({
  actionNamePrefix: `custom-plugins/${ACTION_KEY}/order-shipments`,
  reducerPath: [ROOT_REDUCER_KEY, REDUCER_KEY, 'orderShipments', 'data'],
  fetchRequest: (state, params) => {
    const client = getAuthenticatedAresClient()
    return client.OrderShipments.all(params).then(res => {
      const {orders, _meta} = res.data()
      return {
        entities: orders,
        meta: _meta,
      }
    })
  },
  entityPrimaryKeySelector: path(['externalOrderId']),
})

export default combineReducers({
  data: CollectionReducer,
  feature: paginationReducer,
})

export const selectCurrentPageIndex = state =>
  pathOr(
    0,
    [ROOT_REDUCER_KEY, REDUCER_KEY, 'orderShipments', 'feature', 'pagination', 'pageIndex'],
    state
  )

export const selectCurrentPageSize = state =>
  pathOr(
    0,
    [ROOT_REDUCER_KEY, REDUCER_KEY, 'orderShipments', 'feature', 'pagination', 'pageSize'],
    state
  )

const createKeyWindowFromState = state => ({
  pageIndex: selectCurrentPageIndex(state),
  pageSize: selectCurrentPageSize(state),
})

export const aFetchOrderShipments = () => (dispatch, getState) => {
  dispatch(actions.fetch(createKeyWindowFromState(getState())))
}

export const {
  selectByKeyWindow,
  selectIsKeyWindowLoading,
  selectMetaByKeyWindow,
  selectFetchCountByKeyWindow,
} = selectors

// data selectors
export const selectOrderShipmentsIsLoading = state =>
  selectIsKeyWindowLoading(state, createKeyWindowFromState(state))

export const selectOrderShipments = state =>
  selectByKeyWindow(state, createKeyWindowFromState(state))

export const selectFormattedOrderShipments = createSelector(selectOrderShipments, shipments =>
  shipments.map(({containers, ...rest}) => ({
    containerId: containers?.[0]?.id, // get only the first container number
    ...rest,
  }))
)

export const selectOrderShipmentsFetchCount = state =>
  selectFetchCountByKeyWindow(state, createKeyWindowFromState(state))

export const selectOrderShipmentsHasLoaded = createSelector(
  selectOrderShipmentsFetchCount,
  fetchCount => fetchCount > 0
)

// pagination selectors
export const selectOrderShipmentsTotalCount = state =>
  pathOr(0, ['totalCount'], selectMetaByKeyWindow(state, createKeyWindowFromState(state)))

export const selectOrderShipmentsPageSize = state =>
  pathOr(1, ['currentPageSize'], selectMetaByKeyWindow(state, createKeyWindowFromState(state)))

export const selectOrderShipmentsTotalPages = state =>
  compose(
    add(1),
    pathOr(0, ['maxPageIndex'])
  )(selectMetaByKeyWindow(state, createKeyWindowFromState(state)))

// pagination action
export const aSetPaginationIndex = index => ({
  type: ACTION_SET_PAGINATION_INDEX,
  payload: index,
})
