import {aFetchUser as aPluginFetchUser, selectUserIsLoading, selectUser} from '@modifi/plugin-user'
import {aInitializeCreateOrderLimitations} from '../create-order-limitations'

export {selectUserIsLoading, selectUser} from '@modifi/plugin-user'

export const aFetchUser = () => async (dispatch, getState) => {
  const userFetched = selectUser(getState()) || selectUserIsLoading(getState())

  if (!userFetched) {
    await dispatch(aPluginFetchUser())
    await dispatch(aInitializeCreateOrderLimitations())
  }
}
