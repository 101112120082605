import React from 'react'

const Context = React.createContext({
  externalOrderId: null,
  containerId: null,
  orderReference: null,
  onFetch: null,
})

export default Context
