import {semiDarkGray} from '@modifi/constants/colors'
import {Role, Currency} from '@modifi/enums'
import {FormattedDate, FormattedNumber, Trans} from '@modifi/plugin-i18n'
import {Col, H4, H5, InfoAlert, Paragraph, Row, Strong} from '@modifi/ui'
import * as PropTypes from 'prop-types'
import {path} from 'ramda'
import React from 'react'
import {formatHumanReadable} from '@codeplant-de/iban-utils'

import {ORDER_ROLE_BUYER, ORDER_ROLE_SELLER} from '../../constants'

import CopyButton from '../../ui/copy-button'
import DialogButtonRow from '../../ui/dialog-button-row'
import Hr from '../../ui/hr'

const bankAccountHolderMap = {
  [Role.BUYER]: 'Modifi GmbH',
  [Role.SELLER]: 'Modifi B.V.',
}

// eslint-disable-next-line react/prop-types
const RepaymentBankDetails = ({label, value}) => (
  <Col lg={6} mb={30}>
    <Paragraph color={semiDarkGray} my={0}>
      {label}
    </Paragraph>
    <Paragraph mt={5} mb={0}>
      <Strong>{value}</Strong>
    </Paragraph>
  </Col>
)

const RepaymentInformation = ({params: {payment, order}, closeDialog, t}) => {
  const {
    repayment: {
      buyerIntendedPurpose,
      settlementCurrency,
      amountInSettlementCurrency,
      openAmount,
      repaymentBalance,
      repaymentDate,
    },
    paymentReferenceCode,
  } = payment

  const {role, directDebitRepaymentAccount} = order
  const bankName = path(['repaymentBankAccount', 'bankName'], order)
  const accountHolder = directDebitRepaymentAccount
    ? directDebitRepaymentAccount.accountHolder
    : bankAccountHolderMap[role]
  const accountNumber = path(['repaymentBankAccount', 'accountNumber'], order)
  const bankCode = path(['repaymentBankAccount', 'bankCode'], order)
  const directDebitAccountNumber = path(['directDebitRepaymentAccount', 'accountNumber'], order)
  const directDebitBankCode = path(['directDebitRepaymentAccount', 'bankCode'], order)
  const directDebitMandateReference = path(
    ['directDebitRepaymentAccount', 'mandate', 'mandateReference'],
    order
  )
  const directDebitCreditorIdentifier = path(
    ['directDebitRepaymentAccount', 'mandate', 'creditorIdentifier'],
    order
  )
  const intermediaryBankName = order?.repaymentBankAccount?.intermediaryBank?.bankName || '-'
  const intermediaryBankCode = order?.repaymentBankAccount?.intermediaryBank?.bankCode || '-'

  return (
    <>
      <Row justifyContent="center" mb={60}>
        <Col auto>
          <H4>{t('dialog.repayment-information.headline', {paymentReferenceCode})}</H4>
        </Col>
      </Row>

      {!directDebitRepaymentAccount && (
        <Row>
          <RepaymentBankDetails
            label={t('dialog.repayment-information.payment-method.label')}
            value="Bank Transfer"
          />
        </Row>
      )}

      <Row>
        <RepaymentBankDetails
          label={t('dialog.repayment-information.account-holder.label')}
          value={accountHolder}
        />
        {directDebitRepaymentAccount ? (
          <RepaymentBankDetails
            label={t('dialog.repayment-information.payment-method.label')}
            value="Direct Debit"
          />
        ) : (
          <RepaymentBankDetails
            label={t('dialog.repayment-information.bank-name.label')}
            value={bankName}
          />
        )}
      </Row>
      <Row>
        <RepaymentBankDetails
          label={t('dialog.repayment-information.account-number.label')}
          value={
            directDebitRepaymentAccount
              ? formatHumanReadable(directDebitAccountNumber)
              : formatHumanReadable(accountNumber)
          }
        />
        <RepaymentBankDetails
          label={t('dialog.repayment-information.bank-code.label')}
          value={directDebitRepaymentAccount ? directDebitBankCode : bankCode}
        />
      </Row>
      {!directDebitRepaymentAccount && role === Role.SELLER && settlementCurrency !== Currency.EUR && (
        <Row>
          <RepaymentBankDetails
            label={t('dialog.repayment-information.intermediary-bank-name.label')}
            value={intermediaryBankName}
          />
          <RepaymentBankDetails
            label={t('dialog.repayment-information.intermediary-bank-code.label')}
            value={intermediaryBankCode}
          />
        </Row>
      )}
      {directDebitRepaymentAccount && (
        <Row mb={10}>
          <RepaymentBankDetails
            label={t('dialog.repayment-information.mandate-reference.label')}
            value={directDebitMandateReference}
          />
          <RepaymentBankDetails
            label={t('dialog.repayment-information.creditor-identifier.label')}
            value={directDebitCreditorIdentifier}
          />
        </Row>
      )}

      {!directDebitRepaymentAccount && (
        <Row mb={40}>
          <Col auto>
            <CopyButton
              copyString={t('dialog.repayment-information.copy-text', {
                accountHolder,
                bankName,
                accountNumber,
                bankCode,
                buyerIntendedPurpose,
                intermediaryBankName,
                intermediaryBankCode,
              })}
            >
              {t('dialog.repayment-information.cta-copy-information')}
            </CopyButton>
          </Col>
        </Row>
      )}

      <Hr />

      <Row mt={40}>
        <Col lg={6} mb={30}>
          <Paragraph color={semiDarkGray} my={0}>
            {t(
              `dialog.repayment-information.open-amount.label.${
                directDebitRepaymentAccount ? 'DIRECT_DEBIT' : 'BANK_TRANSFER'
              }`
            )}
          </Paragraph>
          <H5 mt={5} mb={0}>
            <FormattedNumber currency={settlementCurrency}>{openAmount}</FormattedNumber>
          </H5>
        </Col>
        <Col lg={6} mb={30}>
          <Paragraph color={semiDarkGray} my={0}>
            {t('dialog.repayment-information.repayment-date.label')}
          </Paragraph>
          <H5 mt={5} mb={0}>
            <FormattedDate>{repaymentDate}</FormattedDate>
          </H5>
        </Col>
      </Row>
      <Row>
        {!directDebitRepaymentAccount && (
          <Col lg={6} mb={30}>
            <Paragraph color={semiDarkGray} my={0}>
              {t('dialog.repayment-information.amount.label')}
            </Paragraph>
            <Paragraph mt={5} mb={0}>
              <Strong>
                <FormattedNumber currency={settlementCurrency}>
                  {amountInSettlementCurrency}
                </FormattedNumber>
              </Strong>
            </Paragraph>
          </Col>
        )}
        <Col lg={6} mb={30} lgOffset={directDebitRepaymentAccount ? 6 : 0}>
          <Paragraph color={semiDarkGray} my={0}>
            {t(
              `dialog.repayment-information.buyer-intended-purpose.label.${
                directDebitRepaymentAccount ? 'DIRECT_DEBIT' : 'BANK_TRANSFER'
              }`
            )}
          </Paragraph>
          <Paragraph mt={5} mb={0}>
            <Strong>{buyerIntendedPurpose}</Strong>
          </Paragraph>
        </Col>
      </Row>
      {!directDebitRepaymentAccount && (
        <Row>
          <Col lg={6} mb={40}>
            <Paragraph color={semiDarkGray} my={0}>
              {t('dialog.repayment-information.repayment-balance.label')}
            </Paragraph>
            <Paragraph mt={5} mb={0}>
              <Strong>
                <FormattedNumber currency={settlementCurrency}>{repaymentBalance}</FormattedNumber>
              </Strong>
            </Paragraph>
          </Col>
        </Row>
      )}
      {!directDebitRepaymentAccount && (
        <InfoAlert>
          <Trans i18nKey="dialog.repayment-information.alert-no-direct-debit" t={t} />
        </InfoAlert>
      )}

      <DialogButtonRow
        modal
        onSubmitClick={closeDialog}
        submitText={t('dialog.repayment-information.cta-submit')}
      />
    </>
  )
}

RepaymentInformation.propTypes = {
  params: PropTypes.shape({
    payment: PropTypes.shape({
      repayment: PropTypes.shape({
        buyerIntendedPurpose: PropTypes.string.isRequired,
        settlementCurrency: PropTypes.string.isRequired,
        amountInSettlementCurrency: PropTypes.string.isRequired,
        repaymentDate: PropTypes.string.isRequired,
        openAmount: PropTypes.string.isRequired,
        repaymentBalance: PropTypes.string.isRequired,
      }).isRequired,
      paymentReferenceCode: PropTypes.string,
    }).isRequired,
    order: PropTypes.shape({
      role: PropTypes.oneOf([ORDER_ROLE_SELLER, ORDER_ROLE_BUYER]),
      repaymentBankAccount: PropTypes.shape({
        bankName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired,
        bankCode: PropTypes.string.isRequired,
        intermediaryBank: PropTypes.shape({
          bankName: PropTypes.string.isRequired,
          bankCode: PropTypes.string.isRequired,
        }),
      }),
      directDebitRepaymentAccount: PropTypes.shape({
        accountHolder: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired,
        bankCode: PropTypes.string.isRequired,
        mandate: PropTypes.shape({
          mandateReference: PropTypes.string.isRequired,
          creditorIdentifier: PropTypes.string.isRequired,
        }),
      }),
    }),
  }).isRequired,
  closeDialog: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default RepaymentInformation
