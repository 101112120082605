import {selectSearchObj} from '@modifi/router'
import {path, pickBy, startsWith, compose, mapObjIndexed} from 'ramda'

const searchFilterKeys = [
  'filter.createdByExternalCompanyId',
  'filter.tradePartnerExternalCompanyId',
]

const keyWindowFromUrl = (state, keyWindow = null) => {
  const searchObj = selectSearchObj(state)

  const orderBy = path(['orderBy'], searchObj)
  const orderDirection = path(['orderDirection'], searchObj)
  const page = parseInt(path(['page'], searchObj), 10)
  const itemsPerPage = parseInt(path(['itemsPerPage'], searchObj), 10)

  const filters = compose(
    mapObjIndexed((value, key) => {
      // extract value before colon from search filters
      if (searchFilterKeys.includes(key)) {
        return value.map(str => str.split(':')[0])
      }
      return value
    }),
    pickBy((val, key) => startsWith('filter', key) || key === 'hasActionRequired')
  )(searchObj)

  return {
    orderBy,
    orderDirection,
    // check if itemsPerPage and page are positive numbers
    pageSize: itemsPerPage > 0 ? itemsPerPage : 20,
    pageIndex: page > 0 ? page - 1 : 0,
    ...filters,
    ...keyWindow,
  }
}

export default keyWindowFromUrl
