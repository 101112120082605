import {propEq, find} from 'ramda'

export const getDefaultSelected = (fieldName, search, options) => {
  switch (fieldName) {
    case 'filter.tradePartnerOrderStatus': {
      return [
        ...(search['filter.tradePartnerOrderStatus']
          ? search['filter.tradePartnerOrderStatus'].map(value =>
              find(propEq('value', value), options)
            )
          : []),
      ]
    }
    default:
      return ''
  }
}
