import {connect} from '@modifi/redux'

import {selectIncoTermNameById, aEnsureIncoTerms} from '../../store'
import IncoTermName from './IncoTermName'

const mapStateToProps = (state, ownProps) => ({
  incoTermName: selectIncoTermNameById(state, `${ownProps.children}`.trim()),
})

const mapDispatchToProps = {
  ensureIncoTerms: aEnsureIncoTerms,
}

export default connect(mapStateToProps, mapDispatchToProps)(IncoTermName)
