import '@modifi/client-polyfills'
import React from 'react'
import ReactDOM from 'react-dom'

import getApp from './App'

const config = {
  useSuspense: true,
  plugins: {
    i18n: {
      react: {
        useSuspense: true,
      },
    },
  },
}

getApp(config).then(App => {
  ReactDOM.render(<App />, document.getElementById('root'))
})
