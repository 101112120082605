import '@modifi/app'
import {aGenericError, CollectionFactory, combineReducers} from '@modifi/redux'
import {path} from 'ramda'
import {getAuthenticatedAresClient} from '../../../lib'

import {aUpdateOrder} from './data.store'

import {REDUCER_KEY} from '../constants'

const ACTION_KEY = 'order/extensions/FETCH'
const ACTION_APPLY_EXTENSION_CLEANUP = 'order/extension/APPLY_CLEANUP'
const ACTION_APPLY_EXTENSION_PENDING = 'order/extension/APPLY_PENDING'
export const ACTION_APPLY_EXTENSION_SUCCESS = 'order/extension/APPLY_SUCCESS'
const ACTION_APPLY_EXTENSION_ERROR = 'order/extension/APPLY_ERROR'

const initialState = {
  applyPending: false,
  applyError: null,
}

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_APPLY_EXTENSION_CLEANUP:
      return {
        ...state,
        ...initialState,
      }
    case ACTION_APPLY_EXTENSION_PENDING:
      return {
        ...state,
        applyPending: true,
      }
    case ACTION_APPLY_EXTENSION_ERROR: {
      const {message} = payload
      return {
        ...state,
        applyPending: false,
        applyError: message,
      }
    }
    case ACTION_APPLY_EXTENSION_SUCCESS:
      return {
        ...state,
        applyPending: false,
      }
    default:
      return state
  }
}

const {
  actions,
  selectors,
  reducer: availableReducer,
} = CollectionFactory({
  actionNamePrefix: ACTION_KEY,
  reducerPath: [REDUCER_KEY, 'extensions', 'available'],
  fetchRequest: async (store, {externalPaymentId}) => {
    const client = getAuthenticatedAresClient()
    return client.Payments.getExtensions({externalPaymentId}).then(
      res => res.data().availableExtensions
    )
  },
  entityPrimaryKeySelector: ({paymentReferenceCode, numberOfDays}) =>
    `${paymentReferenceCode}::${numberOfDays}`,
})

export default combineReducers({
  available: availableReducer,
  feature: reducer,
})

export const aFetchExtensions = externalPaymentId => actions.fetch({externalPaymentId})

export const aApplyExtensionCleanup = () => ({
  type: ACTION_APPLY_EXTENSION_SUCCESS,
  payload: {},
})

export const aApplyExtension = (externalPaymentId, numberOfDays) => async dispatch => {
  dispatch({
    type: ACTION_APPLY_EXTENSION_PENDING,
    payload: {},
  })

  let order = {}
  try {
    const client = getAuthenticatedAresClient()
    order = await client.Payments.applyExtension({
      externalPaymentId,
      body: {
        numberOfDays,
      },
    }).then(res => res.data())
  } catch (error) {
    dispatch(aGenericError(ACTION_APPLY_EXTENSION_ERROR, error))
    throw error
  }

  dispatch({
    type: ACTION_APPLY_EXTENSION_SUCCESS,
    payload: order,
  })

  // refetch available extensions
  dispatch(aFetchExtensions(externalPaymentId))

  // workaround for incomplete order response
  // const currentOrder = selectOrder(getState())

  dispatch(aUpdateOrder(order))
}

export const selectAvailableExtensions = (state, paymentId) =>
  selectors.selectByKeyWindow(state, {paymentId})
export const selectAvailableExtensionsCount = (state, paymentId) =>
  selectors.selectCountByKeyWindow(state, {paymentId})
export const selectHasAvailableExtensions = (state, paymentId) =>
  selectAvailableExtensionsCount(state, paymentId) > 0
export const selectIsApplyExtensionPending = state =>
  path(['order', 'extensions', 'feature', 'applyPending'], state)
export const selectApplyExtensionError = state =>
  path(['order', 'extensions', 'feature', 'applyError'], state)
