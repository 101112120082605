import {updateFactory} from '@modifi/redux'
import {getAuthenticatedAresClient} from '../../lib'

const apiClient = getAuthenticatedAresClient()

const {reducer, selectors, actions} = updateFactory({
  rootSelector: state => state?.data?.buyerConfirmation?.submit,
  actionNamePrefix: 'data/buyer-confirmation/submit',
  submitRequest:
    (a, data: any) =>
    async (dispatch): Promise<void> => {
      const {externalOrderId, ...rest} = data
      dispatch(a.pendingAction())
      try {
        await apiClient.TradePartnerOrders.updateBuyerConfirmation({
          externalOrderId,
          body: rest,
        })
        dispatch(a.successAction())
      } catch (e: any) {
        dispatch(a.errorAction(e))
        throw e
      }
    },
})

export default reducer

export const {
  selectUpdatePending: selectUpdateBuyerConfirmationPending,
  selectUpdateHasError: selectUpdateBuyerConfirmationHasError,
  selectUpdateErrorMessage: selectUpdateBuyerConfirmationErrorMessage,
  selectUpdateErrorCode: selectUpdateBuyerConfirmationErrorCode,
} = selectors

export const {aSubmit: aUpdateBuyerConfirmation, aReset: aCleanupUpdateBuyerConfirmation} = actions
