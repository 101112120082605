import {reducer as staticReducer} from '../features/static'
import {reducer as configReducer, REDUCER_KEY as CONFIG_REDUCER_KEY} from '../features/config'
import {reducer as documentMetaReducer} from '../features/document-meta'
import dataReducer from '../data/reducer'

export default {
  [CONFIG_REDUCER_KEY]: configReducer,
  static: staticReducer,
  documentMeta: documentMetaReducer,
  data: dataReducer,
}
