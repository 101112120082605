import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col, WarningAlert} from '@modifi/ui'

const ContainsOrderHint = ({containsOrder, t}) => {
  if (containsOrder === false) {
    return (
      <Row>
        <Col col={12}>
          <WarningAlert>{t('shipment-tracking.warning-message')}</WarningAlert>
        </Col>
      </Row>
    )
  }
  return null
}

ContainsOrderHint.propTypes = {
  containsOrder: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

ContainsOrderHint.defaultProps = {
  containsOrder: null,
}

export default ContainsOrderHint
