import {TradePartnerOrderStatus} from '@modifi/enums'

export const PLUGIN_NAME = 'trade-partner-order-filters'

export const getTradePartnerOrderStatusOptions = t => [
  {
    label: t('ol.filters.trade-partner-order-status.CONFIRMATION_REQUIRED.label'),
    value: TradePartnerOrderStatus.CONFIRMATION_REQUIRED,
  },
  {
    label: t('ol.filters.trade-partner-order-status.CONFIRMED.label'),
    value: TradePartnerOrderStatus.CONFIRMED,
  },
  {
    label: t('ol.filters.trade-partner-order-status.DECLINED.label'),
    value: TradePartnerOrderStatus.DECLINED,
  },
  {
    label: t('ol.filters.trade-partner-order-status.IN_PROGRESS.label'),
    value: TradePartnerOrderStatus.IN_PROGRESS,
  },
  {
    label: t('ol.filters.trade-partner-order-status.COMPLETED.label'),
    value: TradePartnerOrderStatus.COMPLETED,
  },
]

export const getSortOptions = t => [
  {
    label: t('ol.sort.repayment-date.DESC.label'),
    value: 'DESC',
  },
  {
    label: t('ol.sort.repayment-date.ASC.label'),
    value: 'ASC',
  },
]
