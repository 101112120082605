import React, {useEffect} from 'react'
import {Col, Row, Paragraph, H4, Alert} from '@modifi/ui'
import * as PropTypes from 'prop-types'

import DialogButtonRow from '../../ui/dialog-button-row'
import {Container} from './styles'

const reviewStatusMap = {
  CONFIRM: 'CONFIRMED',
  DECLINE: 'DECLINED',
}

const TradeConfirmation = ({
  t,
  params,
  closeDialog,
  updateTradeConfirmation,
  loading,
  error,
  clearError,
}) => {
  const {tradeAction, externalId, updateOrder} = params
  useEffect(() => {
    if (error) {
      clearError()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Container>
      <Row justifyContent="center" mb={24} mt={32}>
        <Col col={10}>
          <H4 textAlign="center">{t(`dialog.trade-confirmation.${tradeAction}.headline`)}</H4>
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col>
          <Paragraph>{t('dialog.trade-confirmation.description')}</Paragraph>
        </Col>
      </Row>
      {error && (
        <Row>
          <Col>
            <Alert type="danger">{error}</Alert>
          </Col>
        </Row>
      )}
      <DialogButtonRow
        canCancel
        cancelText={t('dialog.trade-confirmation.cta-cancel')}
        hasCancel
        onCancelClick={closeDialog}
        onSubmitClick={() =>
          updateTradeConfirmation(externalId, reviewStatusMap[tradeAction], updateOrder)
        }
        submitText={t(`dialog.trade-confirmation.${tradeAction}.cta-submit`)}
        alert={tradeAction === 'DECLINE'}
        canSubmit={!loading}
        loading={loading}
      />
    </Container>
  )
}

TradeConfirmation.propTypes = {
  t: PropTypes.func.isRequired,
  params: PropTypes.shape({
    tradeAction: PropTypes.string,
    externalId: PropTypes.string,
    updateOrder: PropTypes.bool,
  }).isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateTradeConfirmation: PropTypes.func.isRequired,
  externalId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  clearError: PropTypes.func.isRequired,
}

export default TradeConfirmation
