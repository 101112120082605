export default [
  {
    code: 'EXW',
    langEN: 'EXW - Ex Works',
  },
  {
    code: 'FCA',
    langEN: 'FCA - Free Carrier',
  },
  {
    code: 'FAS',
    langEN: 'FAS - Free Alongside Ship',
  },
  {
    code: 'FOB',
    langEN: 'FOB - Free On Board',
  },
  {
    code: 'CFR',
    langEN: 'CFR - Cost and Freight',
  },
  {
    code: 'CIF',
    langEN: 'CIF - Cost, Insurance and Freight',
  },
  {
    code: 'CPT',
    langEN: 'CPT - Carriage Paid To',
  },
  {
    code: 'CIP',
    langEN: 'CIP - Carriage And Insurance Paid To',
  },
  {
    code: 'DAT',
    langEN: 'DAT - Delivered At Terminal',
  },
  {
    code: 'DAP',
    langEN: 'DAP - Delivered At Place',
  },
  {
    code: 'DDP',
    langEN: 'DDP - Delivered Duty Paid',
  },
  {
    code: 'UNKNOWN',
    langEN: 'Not applicable',
  },
]
