import {CollectionFactory, Reducer} from '@modifi/redux'
import {uniq} from 'ramda'
import {createSelector} from 'reselect'
import {getAuthenticatedAresClient} from '../../lib'
import {TaskView} from '../../lib/ares/gen'

const {actions, selectors, reducer} = CollectionFactory<Record<string, any>, TaskView>({
  reducerPath: ['data', 'tasks'],
  actionNamePrefix: 'data/tasks/collection',
  fetchRequest: (state, params) => {
    const client = getAuthenticatedAresClient()
    return client.Task.fetch({...params}).then(res => res.data().tasks ?? [])
  },
  entityPrimaryKeySelector: (entity: TaskView) => entity.externalId!,
})

export default reducer as Reducer

export const selectTaskById = selectors.selectById as (
  state: Record<string, any>,
  externalTaskId: string
) => TaskView | null | undefined

export const selectTasksByParams = selectors.selectByKeyWindow as (
  state: Record<string, any>,
  params: any
) => TaskView[]

export const selectTasksAreLoadingByParams = selectors.selectIsKeyWindowLoading as (
  state: Record<string, any>,
  params: any
) => boolean

export const aFetchTasksWithParams = (params: any): any => actions?.fetch(params)

export const aUpdateLocalTasksEntity = (entity: Partial<TaskView>, merge = true): any =>
  actions?.update(entity, merge)

export const selectUniqueScopesForTasks = createSelector(selectTasksByParams, (tasks: TaskView[]) =>
  uniq(tasks.map(task => task.scope))
)
