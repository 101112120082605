import {connect} from '@modifi/redux'
import {path, compose, pickBy, startsWith, not} from 'ramda'
import {withTranslation} from '@modifi/plugin-i18n'
import {selectSearchObj, aReplace} from '@modifi/router'
import qs from 'qs'
import {withResponsiveHelper} from '@modifi/ui'

import QuickFilters from './QuickFilters'

const LOCAL_STORAGE_ORDERS_ITEMS_PER_PAGE = '@modifi:orders:itemsPerPage'
import {
  selectHasOrders,
  selectHasOrdersInProgress,
  selectHasActionRequiredOrders,
  selectHasCompletedOrders,
  selectExistingFilters,
} from '../store'

const mapStateToProps = state => ({
  location: path(['router', 'location'], state),
  search: selectSearchObj(state),
  orderBy: path(['orderBy'], selectSearchObj(state)),
  orderDirection: path(['orderDirection'], selectSearchObj(state)),
  itemsPerPage:
    parseInt(path(['itemsPerPage'], selectSearchObj(state)), 10) ||
    parseInt(localStorage.getItem(LOCAL_STORAGE_ORDERS_ITEMS_PER_PAGE), 10) ||
    undefined,
  filters: selectExistingFilters(state),
  hasActionRequiredOrders: selectHasActionRequiredOrders(state),
  hasOrderInProgress: selectHasOrdersInProgress(state),
  hasOrders: selectHasOrders(state),
  hasCompletedOrders: selectHasCompletedOrders(state),
})

const mapDispatchToProps = {
  replaceUrl: aReplace,
}

const mergeProps = (mapProps, dispatchProps, ownProps) => {
  const {search, location} = mapProps
  const {replaceUrl} = dispatchProps

  return {
    resetAllFilters: () => {
      replaceUrl({
        ...location,
        search: qs.stringify({
          ...pickBy((val, key) => not(startsWith('filter', key)), search),
          hasActionRequired: undefined,
        }),
      })
    },
    ...mapProps,
    ...dispatchProps,
    ...ownProps,
  }
}

export default compose(
  withTranslation(['matrix']),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withResponsiveHelper
)(QuickFilters)
