import {createGlobalStyle} from 'styled-components'

export default createGlobalStyle`
  html,
  body {
    height:100%
  }

  :root {
    box-sizing: border-box;
    scroll-behavior: smooth;
    --airBlue: #53A2D9;
    --backgroundColor: #F4F4F4;
    --seaNavy: #15539F;
    --darkGray: #51565C;
    --mediumGray: #B2B3B5;
    --boxShadow: 0 4px 12px 0 rgba(139, 142, 146, 0.4);
    --white: #FFFFFF;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  h1,h2,h3,h4,h5 {
    margin: 0;
  }

  :root > body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 16px;
    color: #51565c;
    background-color: #f4f4f4;
    overflow-y: auto;

    *::placeholder {
      color: #b2b3b5;
    }

    #modal {
      position: fixed;
      z-index: 999;
      width: 100%;
      margin-bottom: 1em;
      bottom: 0;
    }

    &.no-body-scroll {
      overflow-y: hidden;
    }
  }
`
