import {combineReducers} from '@modifi/redux'

import {reducer as incoTermsReducer} from './inco-terms'
import {reducer as payoutTimingsReducer} from './payout-timings'
import {reducer as transportModalityReducer} from './transport-modalities'

export const reducer = combineReducers({
  incoTerms: incoTermsReducer,
  payoutTimings: payoutTimingsReducer,
  transportModalities: transportModalityReducer,
})
