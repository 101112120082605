export const getFormattedContainerEvents = events => {
  const timelineEvents = events.reduce((accEvents, currentEvent, index, arr) => {
    if (index < arr.length - 1) {
      return accEvents.concat(
        {...currentEvent, id: `location_${index}`},
        {
          id: `transit_${index}`,
          departureLocation: currentEvent?.location,
          departureDate: currentEvent?.events?.[currentEvent?.events?.length - 1]?.date,
          arrivalLocation: arr?.[index + 1]?.location,
          arrivalDate: arr?.[index + 1]?.events?.[0]?.date,
          isEstimated: arr?.[index + 1]?.events?.[0]?.isEstimated,
          vesselNumber: currentEvent?.events?.[currentEvent?.events?.length - 1]?.vesselNumber,
        }
      )
    }
    return accEvents.concat({...currentEvent, id: `location_${index}`})
  }, [])
  return timelineEvents
}
