import {aGenericError} from '@modifi/redux'
import {path} from 'ramda'
import moment from 'moment'
import {aUpdateOrder, aFetchOrder} from './data.store'
import {getAuthenticatedAresClient} from '../../../lib'

export const ACTION_PAYMENT_ACTIVATION_CLEANUP = 'payment/ACTIVATION_CLEANUP'
export const ACTION_PAYMENT_ACTIVATION_PENDING = 'payment/ACTIVATION_PENDING'
export const ACTION_PAYMENT_ACTIVATION_SUCCESS = 'payment/ACTIVATION_SUCCESS'
export const ACTION_PAYMENT_ACTIVATION_ERROR = 'payment/ACTIVATION_ERROR'

const initialState = {
  pending: false,
  error: null,
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_PAYMENT_ACTIVATION_CLEANUP:
      return {
        ...state,
        pending: false,
        error: null,
      }
    case ACTION_PAYMENT_ACTIVATION_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case ACTION_PAYMENT_ACTIVATION_ERROR: {
      const {message} = payload
      return {
        ...state,
        pending: false,
        error: message,
      }
    }
    case ACTION_PAYMENT_ACTIVATION_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      }
    default:
      return state
  }
}

export const selectActivationPending = state =>
  path(['order', 'paymentActivation', 'pending'], state)
export const selectActivationError = state => path(['order', 'paymentActivation', 'error'], state)

export const aSubmitActivation =
  ({
    dialogType,
    externalOrderId,
    externalPaymentId,
    transferDueDate,
    newPaymentReferenceCode,
    sellerIntendedPurpose,
    documentRefs,
  }) =>
  async dispatch => {
    dispatch({
      type: ACTION_PAYMENT_ACTIVATION_PENDING,
    })

    let result = {}
    try {
      const client = getAuthenticatedAresClient()
      const params = {
        externalOrderId,
        body: {
          transferDueDate: moment(transferDueDate).format('YYYY-MM-DDT12:00:00+0000'),
          sellerIntendedPurpose,
          documentRefs,
          newPaymentReferenceCode,
          externalPaymentId,
        },
      }

      if (dialogType === 'edit') {
        result = await client.Activations.updateActivationByRef({
          ...params,
          externalPaymentId,
        }).then(res => res.data())
      } else {
        result = await client.Activations.activateByRef(params).then(res => res.data())
      }
    } catch (error) {
      dispatch(aGenericError(ACTION_PAYMENT_ACTIVATION_ERROR, error))
      throw error
    }

    dispatch({
      type: ACTION_PAYMENT_ACTIVATION_SUCCESS,
      payload: result,
    })

    if (dialogType === 'activate') {
      dispatch(aUpdateOrder(result))
    } else {
      dispatch(aFetchOrder(externalOrderId))
    }
  }

export const aCleanup = () => dispatch => {
  dispatch({type: ACTION_PAYMENT_ACTIVATION_CLEANUP})
}
