import styled from 'styled-components'
import {backgroundColor, white} from '@modifi/colors'
import {ButtonPrimary} from '@modifi/ui'

export const MobileFilterWrapper = styled.div`
  background-color: ${backgroundColor};
  height: 100%;
  padding-bottom: 40px;
`

export const FilterContainer = styled.div`
  padding: 16px;
  margin: 16px 8px 16px 8px;
  background-color: ${white};
`

export const SubmitButton = styled(ButtonPrimary)`
  position: fixed;
  bottom: 0;
`
